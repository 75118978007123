@import 'variable';
%input-element {
  color: var(--text-secondary);
  border-radius: 4px;
  height: auto;
  border: 1px solid var(--border-light);
  box-shadow: none !important;
  font-size: $font-size-base;
  &:hover,
  &:active {
    border: 1px solid var(--primary-color) !important;
  }
}
%inline-input-element {
  border: none;
  box-shadow: none;
  outline: none;
}

%check-box {
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
    }
  }
  .ant-checkbox-inner {
    background-color: var(--bg-white);
  }
}

.profile-link {
  .form-item {
    &.input-sm {
      padding: 6px 15px !important;
    }
  }
}
.form-item {
  &.toggle-switch {
    height: 27px;
    float: right;
    &.ant-switch-checked {
      &:after {
        left: 103%;
      }
      .ant-switch-loading-icon {
        margin-left: -22px;
        left: 100%;
        top: 3px;
      }
    }
    &:after {
      width: 25px;
      height: 25px;
      top: 0;
    }
    .ant-switch-loading-icon {
      left: 4px;
      top: 3px;
    }
  }
  //medium input styles
  &.input-md {
    @extend %input-element;
    font-size: $font-size-base;
    padding: 8.5px 15px;
  }
  //small input size
  &.input-sm {
    @extend %input-element;
    font-size: $font-size-base;
    padding: 4.5px 15px;
  }
  //large input size
  &.input-lg {
    @extend %input-element;
    font-size: $font-size-base;
    padding: 13px 15px;
    line-height: normal;
  }

  // Input password
  &.input-password-md {
    input {
      @extend %input-element;
      font-size: $font-size-base;
      padding: 8.5px 15px;
    }
  }
  &.time-period {
    .ant-input {
      @extend %input-element;
      padding: 4.5px 15px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      margin-right: 1px;
      width: 50px;
    }
  }
  //check box styles
  &.checkbox-group {
    .ant-checkbox {
      @extend %check-box;
    }
  }
  .ant-checkbox {
    @extend %check-box;
  }

  //medium select styles
  &.select-sm {
    .ant-select-selection {
      @extend %input-element;
      padding: 0px 15px;
    }
    .ant-select-selection__rendered {
      .ant-select-selection-selected-value {
        color: var(--text-secondary);
      }
    }
  }

  &.select-md {
    .ant-select-selection {
      @extend %input-element;
      padding: 4px 15px;
    }
    //.ant-select-selection__rendered {
    //  line-height: normal;
    //  margin-left: 0;
    //}
  }

  &.select-lg {
    .ant-select-selection {
      @extend %input-element;
      padding: 8px 15px;
    }
    .ant-select-selection__rendered {
      //line-height: normal;
      //margin-left: 0;
      .ant-select-selection-selected-value {
        color: var(--text-secondary);
      }
    }
  }

  &.capitalize {
    .ant-select-selection {
      .ant-select-selection__rendered {
        .ant-select-selection-selected-value {
          text-transform: capitalize;
        }
      }
    }
  }

  &.multi-select {
    .ant-select-selection {
      @extend %input-element;
      padding: 1px 15px;
    }
    .ant-select-selection__rendered {
      margin: 0;
      .ant-select-selection__choice {
        display: flex;
        border-radius: 50px;
        align-items: center;
        height: 36px;
        line-height: 36px;
        img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
    }
  }
  &.create-tags {
    .ant-select-selection {
      padding: 2px 5px 5px 5px;
    }
    .ant-select-selection__placeholder,
    .ant-select-search__field__placeholder {
      margin-top: -3px;
    }
    .ant-select-selection__rendered {
      .ant-select-selection__choice {
        border-radius: 0px;
      }
    }
  }

  //medium input number styles
  &.input-number-sm {
    @extend %input-element;
    padding: 0;
  }
  &.input-number-md {
    @extend %input-element;
    padding: 4px 5px;
  }
  &.input-number-lg {
    @extend %input-element;
    width: 100%;
    padding: 0;
    .ant-input-number-input {
      height: auto;
      line-height: normal;
      padding: 13px 15px;
      font-size: $font-size-base;
    }
  }
  &.input-date-picker-sm {
    input {
      @extend %input-element;
      padding: 6px 15px;
    }
  }
  //medium date picker styles
  &.input-date-picker-md {
    input {
      @extend %input-element;
      padding: 8.5px 15px;
    }
  }
  &.input-date-picker-lg {
    input {
      @extend %input-element;
      padding: 11px 25px 11px 15px;
    }
  }
  //medium range picker styles
  &.input-range-picker-md {
    & > span {
      height: auto;
      padding: 10px 15px;
    }
  }
  //large range picker styles
  &.input-range-picker-lg {
    & > span {
      height: auto;
      padding: 12px 15px;
    }
  }
  //text area styles
  &.input-textarea {
    border: 1px solid var(--border-light);
    resize: none;
    box-shadow: none;
    padding: 10px 15px;
    &:hover,
    &:active {
      border: 1px solid var(--primary-color) !important;
      box-shadow: none;
    }
  }
  //select with profile picture styles
  &.select-with-profile-md {
    .ant-select-selection {
      @extend %input-element;
      padding: 0px 15px;
      height: auto;
    }
    .ant-select-selection__rendered {
      line-height: 40px;
      margin-left: 0;
      .ant-select-selection-selected-value {
        img {
          height: 24px;
          width: 24px;
          border-radius: 50%;
          margin-right: 10px;
        }
        .flat-users {
          .fa-user-group {
            font-size: 16px !important;
            margin-right: 10px;
          }
        }
      }
    }
  }
  //select with profile small size
  &.select-with-profile-sm {
    .ant-select-selection {
      @extend %input-element;
      padding: 0 15px;
      height: auto;
    }
    .ant-select-selection__rendered {
      .ant-select-selection-selected-value {
        line-height: 28px;
        i,
        img {
          height: 25px;
          width: 25px;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
    }
  }

  //inline input (input with no border)
  &.inline-input {
    @extend %inline-input-element;
    &:hover,
    &:active {
      @extend %inline-input-element;
    }
  }
  //tree structured select style
  &.tree-select-md {
    .ant-select-selection--single {
      @extend %input-element;
      padding: 4px 15px;
      .fa-caret-down {
        font-size: 12px !important;
        color: var(--text-light);
      }
      .ant-select-selection__rendered {
        margin: 0;
      }
    }
    .ant-select-selection--multiple {
      @extend %input-element;
      padding: 4px 15px;
      .ant-select-selection__rendered {
        padding-bottom: 5px;
        margin: 0;
      }
    }
  }

  &.input-addon-md {
    .ant-input {
      @extend %input-element;
      font-size: $font-size-base;
      padding: 8.5px 15px;
    }
    .ant-input-group-addon {
      background: transparent;
      border: none;
      color: var(--text-secondary);
    }
  }
  &.input-addon-before-md {
    .ant-input-group-addon {
      height: 40px;
      background: var(--input-light-bg);
      border: 1px solid var(--border-light);
      border-right: none;
      box-shadow: none !important;
      &:hover,
      &:active {
        border: 1px solid var(--border-light);
        border-right: none;
      }
    }
    .ant-input {
      height: 40px;
      border: 1px solid var(--border-light);
      box-shadow: none !important;
      &:hover,
      &:active {
        border: 1px solid var(--primary-color) !important;
      }
    }
  }
  &.border-less-select {
    .ant-select-selection {
      background: transparent;
      border: none;
      &:hover,
      &:active,
      &:focus {
        border: none;
        background: transparent;
      }
    }
  }
  &.ant-input-password {
    .ant-input {
      @extend %input-element;
      font-size: $font-size-base;
      padding: 8.5px 15px;
    }
  }
}

.ant-row {
  .ant-form-item-label {
    .ant-form-item-required:before {
      content: '' !important;
    }
  }
}

.ant-select-tree-dropdown {
  max-height: 450px !important;
  max-width: 400px !important;
  border-radius: 5px;
  padding-bottom: 15px !important;
  &.ant-select-dropdown--multiple {
    padding-top: 15px !important;
  }
  .ant-select-tree-treenode-switcher-open {
    .ant-select-tree-switcher {
      svg {
        display: none;
      }
    }
    margin-top: 10px;
    .ant-select-tree-node-content-wrapper {
      padding: 7px;
    }
    .ant-select-tree-child-tree:not(:first-child) {
      margin-top: 10px;
    }
  }
  .ant-select-dropdown-search {
    background: var(--bg-white) !important;
  }
  .ant-select-search__field__wrap {
    padding: 10px;
    .ant-select-search__field {
      padding: 7px 15px 7px 15px !important;
      border-radius: 50px !important;
      border: 1px solid var(--input-box-border);
    }
  }
  &.ant-select-dropdown--single {
    .ant-select-search__field__placeholder {
      margin-left: 14px;
    }
  }
}

.assign-dropdown {
  .ant-select-dropdown-menu-item {
    padding: 7px 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.hidden {
      display: none;
    }
  }
  img {
    object-fit: cover;
    object-position: center;
  }
}

.select-dropdown {
  border-radius: 5px;
  box-shadow: 0 3px 10px 0 rgba(35, 58, 132, 0.08);
  background: var(--bg-white);
  .ant-select-dropdown-menu-item {
    padding: 8px;
  }
}

.ant-radio-group {
  .ant-radio-wrapper {
    color: var(--text-secondary);
  }
}

.input-group-with-addon {
  .ant-input-group {
    .ant-input-group-addon {
      background-color: var(--select-box-active) !important;
      border: 1px solid var(--border-light) !important;
      border-right: 0 !important;
    }
    .ant-input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  &.workflow {
    .ant-input-group-addon,
    input {
      padding: 13px 15px !important;
    }
  }
  &.rules {
    .ant-input {
      margin-top: 0px !important;
    }
  }
}

.select-title-description {
  &.ant-select-dropdown {
    .ant-select-dropdown-menu-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .option-title {
    font-weight: 700;
  }

  .option-desc {
    white-space: pre-line;
  }
}

.ant-radio-wrapper-checked {
  .ant-radio-inner {
    box-shadow: none !important;
    outline: none !important;
  }
}

.ant-select-dropdown {
  max-width: 620px;
}

.ant-input-suffix {
  .ant-input-clear-icon {
    @include color-with-opacity($hex-color: var(--black-rgb), $opacity: 0.25);
  }
}

.ant-input-number {
  .ant-input-number-handler-wrap {
    background: var(--white);
  }
  &:hover,
  &:focus {
    .ant-input-number-handler-wrap {
      //background: var(--white);
      border-color: var(--border-dark);
      .ant-input-number-handler {
        .anticon {
          color: var(--border-dark);
        }
        &:hover {
          .anticon {
            color: var(--primary-color);
          }
        }
        &.ant-input-number-handler-down {
          border-top: 1px solid var(--border-dark);
        }
      }
    }
  }
}

.range-picker-dropdown {
  .ant-calendar-input {
    text-align: center;
  }
  .ant-calendar-range-right {
    .ant-calendar-date-input-wrap {
      margin-left: 0;
    }
  }
  .ant-calendar-range-middle {
    padding: 0;
  }
}
