.rounded-none {
  border-radius: 0 !important;
}

.rounded-xxs {
  border-radius: 2px !important;
}

.rounded-xs {
  border-radius: 4px !important;
}

.rounded-sm {
  border-radius: 5px !important;
}

.rounded {
  border-radius: 6px !important;
}

.rounded-lg {
  border-radius: 7px !important;
}

.rounded-xl {
  border-radius: 8px !important;
}

.rounded-2xl {
  border-radius: 10px !important;
}

.rounded-3xl {
  border-radius: 12px !important;
}

.rounded-full {
  border-radius: 9999px !important;
}

.rounded-8xl {
  border-radius: 54px !important;
}

.rounded-t-xs {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.rounded-t {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}

.rounded-t-xl {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.rounded-r-none {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-r-xs {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.rounded-r-lg {
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
}

.rounded-r-xl {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.rounded-r-3xl {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.rounded-b-none {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-b-xs {
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.rounded-b-sm {
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.rounded-b {
  border-bottom-right-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.rounded-b-lg {
  border-bottom-right-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
}

.rounded-b-xl {
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.rounded-l-none {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-l-xs {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.rounded-l {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.rounded-l-lg {
  border-top-left-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
}

.rounded-l-xl {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.rounded-l-3xl {
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
}

.rounded-tl-2xl {
  border-top-left-radius: 10px !important;
}

.rounded-tl-3xl {
  border-top-left-radius: 12px !important;
}

.rounded-tr-lg {
  border-top-right-radius: 7px !important;
}

.rounded-tr-3xl {
  border-top-right-radius: 12px !important;
}

.rounded-br-lg {
  border-bottom-right-radius: 7px !important;
}

.rounded-br-3xl {
  border-bottom-right-radius: 12px !important;
}

.rounded-bl-3xl {
  border-bottom-left-radius: 12px !important;
}

.border-inherit {
  border-color: inherit;
}

.border-current {
  border-color: currentColor;
}

.border-transparent {
  border-color: transparent;
}

.border-0 {
  border-width: 0 !important;
}

.border {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-t-0 {
  border-top-width: 0 !important;
}

.border-t {
  border-top-width: 1px !important;
}

.border-t-2 {
  border-top-width: 2px !important;
}

.border-t-4 {
  border-top-width: 4px !important;
}

.border-r-0 {
  border-right-width: 0 !important;
}

.border-r {
  border-right-width: 1px !important;
}

.border-r-2 {
  border-right-width: 2px !important;
}

.border-r-4 {
  border-right-width: 4px !important;
}

.border-b-0 {
  border-bottom-width: 0 !important;
}

.border-b {
  border-bottom-width: 1px !important;
}

.border-b-2 {
  border-bottom-width: 2px !important;
}

.border-b-4 {
  border-bottom-width: 4px !important;
}

.border-l-0 {
  border-left-width: 0 !important;
}

.border-l {
  border-left-width: 1px !important;
}

.border-l-2 {
  border-left-width: 2px !important;
}

.border-l-4 {
  border-left-width: 4px !important;
}

.border-solid {
  border-style: solid !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-none {
  border-style: none !important;
}
