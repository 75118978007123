@import '../../style/variable';

.workspace-list-layout {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  background: var(--bg-white);
  overflow: auto;
  .back-link {
    position: absolute;
    top: 20px;
    left: 30px;
    cursor: pointer;
    color: var(--text-secondary);
    i {
      margin-right: 6px;
      font-size: $font-size-base;
    }
  }
  .workspace-list {
    width: 600px;
    padding: 30px 0;
    #back-workspace {
      position: absolute;
      top: 30px;
      left: 50px;
      cursor: pointer;
      .flaticon-back {
        margin-right: 10px;
      }
    }
    h3 {
      text-align: left;
      margin-bottom: 20px;
    }
    .branding {
      display: inline-flex;
      margin-bottom: 15px;
      .logo {
        width: 45px;
        height: 42px;
        margin-right: 6px;
      }
      h1 {
        font-weight: bold;
        margin-bottom: 0;
        font-size: 30px;
      }
      & > span {
        cursor: pointer;
        color: var(--text-secondary);
        flex-basis: 33%;
        justify-content: left;
        margin-right: 20px;
        i {
          margin-right: 5px;
          font-size: $font-size-base;
        }
      }
    }
    .info {
      color: var(--text-primary);
      font-size: $font-size-base;
      text-align: left;
      margin-bottom: 20px;
    }
    .btn-sm {
      font-size: 16px;
      font-weight: 600;
      width: 100%;
      padding: 12px;
      margin-bottom: 13px;
    }
    .ant-list {
      border-radius: 5px;
      box-shadow: 0 3px 10px 0 rgba(35, 58, 132, 0.08);
      border: 1px solid var(--border-light);
      background: var(--bg-white);
      margin-bottom: 25px;
      cursor: pointer;
      a,
      .workspace-list-item {
        .ant-list-item {
          padding: 10px 15px;
          color: var(--text-primary);
          font-weight: 600;
          border-bottom: 1px solid var(--border-gray);
          &:hover {
            @include background-with-opacity(
              $hex-color: var(--black-rgb),
              $opacity: 0.01
            );
          }
          .ant-list-item-meta {
            align-items: center;
            .project-image {
              display: flex;
              flex-shrink: 0;
              align-items: center;
              width: 40px;
              height: 40px;
              border-radius: 5px;
              justify-content: center;
              font-size: 20px;
              font-weight: 600;
            }
            img.project-image {
              height: auto;
            }
            .ant-avatar-image {
              width: 40px;
              height: 40px;
            }
            .ant-list-item-meta-content {
              text-align: left;
              h4 {
                display: grid;
                span:first-child,
                .domain {
                  margin-bottom: 0;
                  color: var(--text-primary);
                  font-weight: 600;
                  display: inline-block;
                  vertical-align: middle;
                }
                .domain {
                  font-weight: 400;
                  color: var(--text-light);
                }
                span:first-child {
                  margin-right: 5px;
                }
              }
            }
          }
          .key {
            font-weight: normal;
            color: var(--text-light);
            margin-right: 10px;
          }
          i {
            color: var(--text-light);
            font-size: $font-size-base;
          }
        }
        &:last-child {
          .ant-list-item {
            border-bottom: none;
          }
        }
      }
    }
    &.no-workspace {
      .ant-list {
        display: none;
      }
    }
  }
}

.shopify-plan-modal {
  .box {
    display: flex;
    align-items: center;
    border-radius: 3px;
    //TODO: Need to change color variable name
    border: solid 1px $icon-color-light;
    padding: 15px 35px 20px 44px;
    & + .box {
      margin-top: 20px;
    }
    .avatar {
      height: 35px;
      width: 35px;
      margin-right: 35px;
      img {
        height: inherit;
        width: inherit;
      }
    }
    .content {
      margin-right: 20px;
      h3 {
        font-size: 14px;
        margin-bottom: 2px;
        font-weight: 600;
        span {
          margin-left: 8px;
          font-size: 13px;
          //TODO: Need to change color variable name
          color: $bot-button-text-color;
          font-weight: normal;
        }
      }
    }
    button {
      span {
        //TODO: Need to change color variable name
        color: $white-light;
      }
    }
  }
}
