@import '../../style/popover';
@import '../../style/mixin';
@import '../../style/variable';

// TODO: NEED TO REVAMP CSS AFTER, WILL BE DONE DURING WEEKENDS

%profile {
  @include prefixer(display, inline-flex);
  align-items: center;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  justify-content: center;
  border-radius: 30px;
  font-size: 13px;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: auto;
  line-height: 1;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 38px !important;
  list-style-position: inside;
  list-style-type: disc;
}
.settings-layout {
  .ant-form {
    max-width: 1024px;
  }

  .fa-trash {
    color: var(--text-light);
    cursor: pointer;
    font-size: 16px;
    &:hover {
      color: $red-700;
    }
  }
  .add-text{
    display: inline-block;
    color: var(--primary-color);
    font-size: $font-size-base;
    cursor: pointer;
    &.disabled {
      display: none;
    }
    .not-allowed {
      cursor: not-allowed;
    }
  }

  .qualification-bot {
    .input-textarea {
      width: 450px;
    }

    .add-text {
      margin-bottom: 20px;
    }

    .email-disabled-field {
      .ant-input {
        width: 200px;
        border: 1px solid var(--border-gray) !important;
      }
    }

    .border-class {
      max-width: 650px;
    }
  }
  .data-and-privacy{
    .gdpr-class{
      max-width: 600px;
      max-height: 200px;
    }
    .editor-class{
      overflow: auto;
      height: 150px;
      min-height: 100px;
    }
    .heading{
      font-size: 15px;
      color: var(--text-secondary);
      font-weight: 500;
      margin-bottom: 10px;
    }
    .toggle-switch{
      top: -15px;
    }
    .ant-row,
    .process-field {
      margin-bottom: 24px;
    }
    .border-class {
      border: 1px solid var(--border-light);
      border-radius: 0 0 5px 5px;
      padding: 20px;
      margin-bottom: 5px;
    }
    .data-privacy-preview {
      background: #fff; // Theme Independent
      border: 1px solid var(--border-light);
      padding: 20px 30px 40px;
      border-radius: 8px;
      min-height: 600px;
      .heading {
        font-size: 18px;
      }

      .heading,
      p {
        color: rgba(0, 0, 0, 0.65); // Theme Independent
      }

      .button-preview {
        text-align: center;
        position: absolute;
        bottom: 15px;
        right: 20px;
        button {
          background: transparent;
          -webkit-border-radius: 24px;
          -moz-border-radius: 24px;
          border-radius: 24px;
          &:first-child {
            color: $green-500; // Theme Independent
            border-color: $green-500; // Theme Independent
            margin-bottom: 10px;
          }
          &:last-child {
            margin-left: 10px;
            border-color: var(--gray-600);
            color: var(--gray-600);
          }
        }
      }
    }
  }

  .knowledge-base-settings,
  .qualification-bot,
  .language,
  .availability,
  .tracking-code{
    .heading{
      label{
        font-weight: 500;
        margin-bottom: 5px;
        color: var(--text-secondary);
        display: block;
      }

      .element {
        margin-bottom: 8px;
      }
    }
    .many-selects{
      margin-bottom: 4px;

      .select-small {
        width: 200px;
        margin-right: 12px;
      }
    }
  }
  .language{
    .default-language{
      min-width: 100px;
      width: auto;
      max-width: 250px;
    }
    .add-text {
      margin-bottom: 15px;
    }
  }
  .visibility{
    .visibility-form{
      .condtion-text{
        display: flex;
        align-items: center;
      }
      .heading{
        font-size: 15px;
        color: var(--text-secondary);
        font-weight: 500;
        margin-bottom: 6px;
      }
      .test {
        max-width: 800px;
        border: solid 1px var(--border-light);
        padding: 20px;
        border-radius: 3px;
        margin-bottom: 25px;
        .ant-row-flex {
            margin-bottom: 12px;
        }
        .ant-row {
          margin-right: 10px;
        }
        .messenger {
          .ant-form-text{
            font-weight: 600;
            color: var(--text-secondary);
            padding: 0 10px 0 0;
            min-width: 50px;
            text-align: right;
          }
          .current{
            margin-left: 25px;
          }
          i{
            margin-left: 10px;
          }
          .enabled-paths{
            margin-bottom: 0;
          }
          .ant-form-item-children{
            display: flex;
            align-items: center;
          }
          span {
            input {
              max-width: 337px;
            }
          }
        }

        .add-text {
          font-size: $font-size-base;
        }
      }

      .separate-line{
        margin: 25px 0 25px;
        height: 1px;
        width: 690px;
        background: var(--border-light);
        position: relative;
        span{
          position: absolute;
          top: 50%;
          left: 45%;
          transform: translate(50%, -50%);
          background: var(--bg-white);
          padding: 0 6px;
          font-size: 13px;
          font-weight: 600;
          color: var(--text-primary);
        }
      }
    }
    .ant-form-item-control{
      line-height: inherit;
    }
    .size-checked{
      margin-top: 5px;
      .ant-checkbox-group-item {
        display: block;
        &:last-of-type {
          margin-top: 10px;
        }
      }
    }
    .only-one{
      .ant-switch{
        bottom: 0;
      }
    }
  }

  .plan-choosing{
    display: flex;
    margin-bottom: 10px;
    .ant-radio-wrapper {
      width: 200px;
      .ant-radio {
        margin-right: 5px;
      }
    }
  }
.show-messenger {
    .description {
      width: 100%;
      font-weight: 400;
      overflow: hidden;
      white-space: pre-wrap;
      color: var(--text-light);
    }
  }  .show-messenger,
  .plan-choosing{
    .ant-radio-wrapper{
      .ant-radio{
        margin-right: 15px;
      }
      .heading{
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 3px;
        color: var(--text-primary);
      }
      display: flex;
      align-items: center;
      border-radius: 5px;
      border: 1px solid var(--border-light);
      padding: 15px;
      margin-bottom: 8px;
    }

    .ant-radio-wrapper-checked {
      border: 1px solid var(--primary-color);
      background: var(--light-blue-color);
    }
    .only-one{
      .ant-switch{
        bottom: 0;
      }
    }
  }
  .plan-choosing {
    .ant-radio-wrapper {
      padding: 10px 15px;
      width: 170px;
      span.ant-radio + * {
        padding: 0;
      }
    }
  }
  .forms,
  .visibility{
    .description{
      width: 100%;
      color: var(--text-secondary);
      display: block;
      line-height: 1.54;
      overflow: hidden;
    }
  }
  .forms,
  .email-settings {
    .heading{
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 8px;
      color: var(--text-secondary);
    }
  }

  .forms {
    .heading {
      sup {
        margin-left: 3px;
        color: white;
        background: var(--primary-color);
        font-weight: 600;
        border-radius: 5px;
        padding: 3px;
      }
    }
  }

  .visibility,
  .appearance,
  .advanced,
  .forms,
  .bot-identity,
  .email-settings,
  .spam-filters {
    .advanced-data{
      .save-survey{
        margin-top: 10px;
      }
      .customize-survey-email{
        display: block;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .special-notice {
        .special-notice-label{
          margin: 10px 0 !important;
        }
        .ant-input-disabled, .ant-input[disabled] {
          background-color: transparent !important;
        }
      }

      .special-notice-content{

        .special-notice-content-container{
          position: relative;
          padding-top: 20px;

          .see-language{
            position: absolute;
            top: 0;
            right:0;
          }
        }
      }

      .display-duration {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        .ant-calendar-picker{
          margin-bottom: 10px;
        }
        .date-divider{
          margin: auto 1rem;
        }
      }
    }
    .ant-switch{
      bottom: 10px;
      margin-left: 30px;
    }
    .border-class {
      border: 1px solid var(--border-light);
      border-radius: 0 0 5px 5px;
      padding: 20px;
      max-width: 700px;

      .satisfaction-survey,
      .respond-closed-conversation {
        display: flex;
      }
      .description{
        display: inline-block;
        width: calc(100% - 74px);

        & + button {
          vertical-align: top;
          bottom: 10px;
        }
      }
      .subscription-default-contact {
        width: 100%;
      }
      .default-contact-dropdown {
        width: 200px;
        margin-top: 15px;
      }
      .disallow-time-period {
        margin: 0 10px;
        width: 70px;
        text-align: left;
        border: 1px solid var(--border-gray);
        font-size: 14px;
      }
    }
    .continue{
      margin-bottom: 0;
      border-radius: 0;
      border-bottom: 0;
    }
    .first-border-class{
      border-radius: 5px 5px 0 0;
      margin-bottom: 0;
      border-bottom: 0px;
    }
    .only-one{
      margin-bottom: 24px;
      border-radius: 5px;
      .ant-row{
        margin-bottom: 10px;
      }
    }
  }

  .general {
    .settings-heading {
      margin-bottom: 8px;
    }
    .general-form {
      .gist-brand {
        display: flex;
        .ant-switch {
          margin: 1px 0 5px;
        }
      }
    }
  }

  .availability {
    .element {
      .ant-col-5 {
        .ant-form-item-children{
          display: flex;
          align-items: baseline;
        }
        .ant-form-text{
          padding: 0 10px;
        }
      }
    }
    .many-selects{
      i{
        margin-left: 10px;
      }
    }

    .description {
      color: var(--text-secondary);
      margin-bottom: 10px;
    }
    .settings-heading{
      margin-bottom: 8px;
    }
    .heading{
      font-weight: 600;
      margin-bottom: 10px;
    }
    .select-md{
      width: 450px;
    }
    .ant-col-2,
    .ant-col-3{
      .select-md{
        width: 100%;
      }
      margin-right: 10px;
      .ant-form-item{
        margin-bottom: 0;
      }
    }
  }
  .with-switch{
    .ant-form-item-children{
      display: flex;
      justify-content: space-between;
    }
  }
  .appearance,
  .meetings{
    height: 100%;
    .border-class{
      @media screen and (max-width: $screen-xl) {
        width: 600px;
      }
    }
    .ant-row {
      .ant-col-10{
        height: 90%;
      }
    }
    .color-picker-flex{
      display: flex;
      .flex-color{
        display: flex;
        align-items: center;
        margin-top: 4px;
        flex-wrap: wrap;
        gap: 10px 0;
      }
      .color-or{
        margin-right: 8px;
        font-weight: 600;
      }
    }
    .appearance-icons{
      .ant-col-2{
        margin-right: 10px;
      }
      .messenger-icon{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: var(--primary-color);
        opacity: 0.3;
        cursor: pointer;
        position:relative;
        &.selected{
          opacity: 1;
        }
        img{
          position:absolute;
          width:100%;
          opacity: 1;
        }
      }
    }

    .chat-appearance{
      width: 380px;
      @media screen and (min-height: 900px) {
          position: fixed;
      }
.preview-as {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
      }      .chat{
        position: relative;
        height: 720px;
        display: flex;
        flex-direction: column;
        box-shadow: 0 2px 52px 0 rgba(0, 0, 0, 0.16);
        border-radius: 8px;
        overflow: hidden;
        background: rgb(245, 249, 251);
        .header,.footer{
          padding: 24px 30px;
        }

        .header {
          position: relative;
          background-color: var(--primary-color);
          color: white;
          padding: 32px 30px 44px;

          &:before {
            content: '';
            position: absolute;
            inset: 0;
            pointer-events: none;
            opacity: 0.35;
            background-size: 325px 323.5px, cover;
          }

          &::after {
            content: '';
            position: absolute;
            top: var(--header-height);
            left: 0;
            width: 100%;
            opacity: 1;
            z-index: 0;
            background: linear-gradient(
              rgba(255, 255, 255, 0) 0%,
              rgb(255, 255, 255) 100%
            );
            height: 80px;
          }

          @for $i from 1 through 15 {
            &.pattern-#{$i} {
              &:before {
                background-image: url('https://gist-testing.b-cdn.net/images/patterns/pattern-#{$i}.png');
              }
            }
          }

          @for $i from 1 through 19 {
            &.opacity-#{$i*5} {
              &:before {
                opacity: $i * 5/100;
              }
            }
          }
          img{
            height: 32px;
            margin-bottom: 50px;
          }

          .avatar {
            border: 2px solid var(--white);
            margin-top: 30px;
            align-self: center;
          }
          .greeting-text{
            font-size: 27px;
            font-weight: 700;
            line-height: 32px;
            opacity: 0.7;
            margin-bottom: 4px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .welcome-text{
            font-size: 27px;
            font-weight: 700;
            line-height: 32px;
            margin-bottom: 0px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
          }
        }
        .body {
          position: relative;
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          margin-top: -85px;
          gap: 10px;
          z-index: 1;
            .widget {
            padding: 16px;
            margin: 0 16px;
            background: #fff;
            border-radius: 6px;
            box-shadow: 0 0 0 1px #00000014, 0 2px 8px #0000000f;
            h3{
              font-size: 14px;
              margin-bottom: 8px;
              font-weight: 500;
            }
            h4{
              font-size: 13px;
              margin-bottom: 15px;
              color: rgb(145, 155, 176);
            }

            &.article-widget {
              .input-md {
                border-radius: 50px;
                height: 42px;
                padding: 0 15px 0 42px;
                pointer-events: none;

                .ant-input-prefix {
                  left: 13px;
                  i {
                    font-size: 16px;
                  }
                }

                input {
                  border: none;
                  padding: 0;
                }
              }
            }
          }
          .btn-like{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 15px;
            border: none;
            svg{
              height: 15px;
              margin-left: 10px;
            }
          }

          .messenger-tabs {
            animation: fadeInUp;
            animation-duration: 0.4s;
            animation-fill-mode: forwards;
            background-color: #fff;
            box-shadow: 0 0 1px 1px #0000000d, 0 0 25px #0000000d;
            display: flex;
            flex-direction: row;
            height: 72px;
            justify-content: space-between;
            position: absolute;
            right: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            z-index: 999;

            .messenger-tab {
              align-items: center;
              display: flex;
              flex: 1 1;
              flex-direction: column;
              justify-content: center;
              overflow: hidden;
              text-align: center;
              text-overflow: clip;
              transition: all 0.15s ease 0s;

              svg {
                height: 23px;
                margin-bottom: 7px;
                margin-top: 4px;
                width: auto;
              }

              span {
                font-size: 13px;
                font-weight: 400;
                line-height: 16px;
                max-width: calc(100% - 2px);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              &.selected {
                svg {
                  color: var(--primary-color);
                }
                span {
                  color: var(--primary-color);
                }
              }
            }
          }
        }
      }
    }
    .appearance-form,
    .meeting-profile-form {
      max-width: 690px;
      width: 100%;
      .text-editor {
        .froalaEditor {
          .fr-toolbar .fr-btn-grp {
            margin: 0 17px 0 0px;
          }
          .fr-box.fr-basic .fr-element {
            padding: 10px 15px;
          }
          [id|='personalize'] {
            width: 95px !important;
            padding-left: 10px!important;
            margin-left: 5px!important;
          }
        }
      }
      .add-languages{
        display: flex;
        align-items: center;
        .ant-row{
          width: 400px;
        }
      }
      .appearance-color-picker{
        position: absolute;
        right: 0;
        z-index: 2;
        top: 50px;
      }

      .color-picker{
        width: 35px;
        height: 35px;
        margin-right: 10px;
        border-radius: 50%;
        cursor: pointer;
        position: relative;
        border: 1px solid var(--border-light);
        &.custom-color-picker{
          background: var(--bg-white);
          border: 1px solid var(--border-light);
          position: relative;
          svg{
            width: 100%;
            height: 55%;
            top: 9px;
            position: relative;
            transform: rotate(45deg);
          }
        }
        &.checked:after{
          position: absolute;
          content: "\2713";
          top: calc(50% - 14px);
          left: calc(50% - 9px);
          color: var(--text-white);
          font-size: 20px;
        }
        &.black:after{
          color: var(--text-secondary) !important;
        }
      }
      .ant-row {
        .ant-col-5 {
          display: flex;
          align-items: center;
          .pixel {
            margin-left: 8px;
            font-size: 13px;
          }
        }
      }
      .end-text{
        font-size: 13px;
        color: var(--text-primary);
        opacity: 0.5;
      }
      .launcher{
        .select-md{
          max-width: 150px;
        }
      }
    }
  }
  .settings-heading {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  flex-direction: row;

  .general,
  .branding,
  .tracking-code,
  .appearance,
  .data-and-privacy,
  .double-opt-in,
  .meetings {
    .form-item {
      &.input-number-md {
        min-width: 450px;
      }
      &.input-md,
      &.select-md,
      &.input-number-md,
      &.input-textarea {
        max-width: 450px;
      }
    }
    .input-group {
      .ant-input-group {
        max-width: 450px;
        .ant-input-group-addon {
          background-color: var(--select-box-active) !important;
          border: 1px solid var(--border-light) !important;
          border-right: 0 !important;
        }
        .ant-input {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }
  .general,
  .forms,
  .kb-settings,
  .feedback-settings,
  .newscenter-settings {
    .ant-tabs {
      .ant-tabs-bar {
        padding-top: 5px;
        padding-left: 30px;
      }

      .ant-tabs-content {
        overflow: auto;
      }

      .ant-tabs-tabpane {
        padding: 20px 30px;
      }
      .branding {
        background: var(--bg-white);
        height: 100%;
        width: 100%;
      }
    }
  }
  .contact-segments,
  .teams {
    .default {
      color: var(--text-light);
      font-size: 12px;
    }
  }
  .branding {
    .company-logo {
      .preview-holder {
        height: 140px;
        max-width: 450px;
        border-radius: 3px;
        background: var(--bg-white);
        position: relative;
        border: 1px solid var(--border-light) !important;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .helper-text {
          margin-top: 5px;
          color: var(--primary-color);
        }
        input {
          position: absolute;
          opacity: 0;
          z-index: 10;
          height: 140px;
          cursor: pointer;
        }
        img {
          position: absolute;
          border-radius: 3px;
          z-index: 5;
          max-height: 100%;
          max-width: 100%;
        }
        .delete-image {
          z-index: 11;
          position: absolute;
          right: 5px;
          top: 5px;
          background: var(--text-light);
          border-radius: 50%;
          height: 23px;
          width: 23px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          .fa-xmark {
            font-size: 9px;
            color: var(--text-white) !important;
          }
        }
      }
    }
  }
  .teams {
    .team-action {
      &.edit {
        background: var(--search-box-bg);
        padding: 8px 15px;
        font-weight: 500;
        color: var(--text-secondary);
        border-radius: 4px;
        height: auto;
        border: none;
        box-shadow: none;
      }
    }
  }
  .general,
  .branding,
  .tracking-code {
    .ant-form-item {
      //margin-bottom: 15px !important;
    }
    .ant-upload.ant-upload-drag {
      background: var(--bg-white) !important;
    }
  }
  .ant-form-item-label {
    //line-height: 29px !important;
    font-weight: 500;
    margin-bottom: 4px;

    label {
      &::after {
        display: none;
      }
    }
  }
  .blocked-project-owner {
    align-items: center;
  }
  .settings-content {
    background: var(--bg-white) !important;
    padding: 20px;
    width: 100%;

    &.snippets {
      padding: 0;

      .snippets-component {
        padding: 20px;

        .search .ant-input-affix-wrapper {
          input {
            border: 1px solid var(--border-gray);
          }
        }
      }
    }

    &.general,
    &.forms {
      padding: 0!important;
    }
    &.teams-roles {
      padding-top: 0 !important;
    }
    iframe {
      border-width: 0;
    }
    .general-footer {
      position: fixed;
      bottom: 0;
      padding: 20px 30px;
      width: 100%;
      border-top: 1px solid var(--input-box-border);
      background: var(--bg-white);
      margin-left: -30px;
    }
    .d-none {
      .ant-select-selection__rendered {
        margin-right: 0;

        i{
          margin-top: 10px;
        }

        span {
          display: none;
        }
      }
    }
    .contact-properties,
    .conversation-properties {
      table {
        border-radius: 5px;

        .ant-table-tbody {
          border-top: 1px solid var(--border-gray) !important;
        }
      }

      .ant-collapse-content-box {
        padding: 0;
      }

      .ant-collapse-item {
        border-bottom: none;

        .ant-collapse-header {
          padding-left: 25px;

          .ant-collapse-arrow {
            left: 0;
          }
        }
      }
      .ant-collapse-header {
        color: var(--text-secondary);
        font-weight: 500;
        font-size: 16px;
        .properties {
          .right {
            display: none;
          }
        }
        &:hover {
          .right {
            display: inline-block;
            font-size: $font-size-base;

            .edit {
              margin-right: 15px;
              color: var(--text-light);

              .fa-pencil {
                margin-right: 5px;
                font-size: $font-size-base;
              }
            }

            .delete {
              color: $error-color-dark;

              &.disabled {
                opacity: 0.5;
              }

              .fa-trash {
                margin-right: 5px;
                font-size: $font-size-base;
                color: $error-color-dark;
              }
            }
          }
        }
      }
    }
  }
  .rails-settings-iframe{
    padding: 0 !important;
  }
  .filter-section {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      flex: 1;
    }
    .right {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .search-webhook {
        input {
          border-radius: 50px;
          width: 250px;
          text-overflow: unset;
        }
      }

      button {
        margin-left: 10px;
      }
    }
  }
  .tracking-code {
    .add-text {
      margin-bottom: 0;
      &.disabled {
        pointer-events: inherit;
        display: inline-block;
      }
    }
    .secondary-domains{
      .ant-form-item-children{
        display: flex;
        align-items: center;
      }
      i,
      .anticon-loading{
        margin-left: 10px;
      }
    }
    .tracking-form {
      margin-bottom: 40px;
      .ant-row {
        max-width: 450px;
        margin-bottom: 5px;
      }
      .domain-save {
        vertical-align: bottom;
      }
    }
    .tracking-title {
      font-weight: 500;
      margin-bottom: 5px;
      font-size: $font-size-base;
      line-height: 2;
      color: var(--text-primary);
    }
    .tracking-description {
      margin-bottom: 15px;
      .download-icon {
        margin-right: 5px;
      }
      // p {
      //   margin-bottom: 3px !important;
      //   width: 667px;
      //   font-size: 13px;
      //   line-height: 1.69;
      // }
      .workspace-id {
        width: 200px;
        height: 35px;
        padding: 5px 0px 5px 10px;
        border-radius: 3px;
        border: 1px solid var(--border-gray);
        display: flex;
        align-items: center;
        justify-content: space-between;
        .workspace-id-copy {
          font-size: 13px;
          font-weight: 500;
          color: var(--primary-color);
          cursor: pointer;
          background: var(--input-light-bg);
          padding: 7px;
          border-left: 1px solid var(--border-dark);
        }
      }
    }

    button {
      margin-right: 10px;
    }
  }
  .fa-caret-down {
    font-size: 11px;
  }
  .snippet-form {
    .snippet-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .left {
        width: 100%;

        .snippet-name {
          margin-bottom: 10px;

          .form-item {
            font-size: 18px;
            font-weight: 500;
            padding: 0 0 5px;
            outline: none;
            border: none;

            &:hover,
            &:focus {
              outline: none;
              border: none !important;
              border-bottom: 1px dashed var(--border-dark) !important;
            }
          }
        }

        .meta-info {
          color: var(--text-light);

          .profile-avatar {
            font-size: 14px;
            margin: 0 5px;
          }
        }
      }

      .right {
        display: flex;
        margin-left: 20px;

        .btn-primary {
          margin-left: 10px;
        }
      }
    }
  }  .users, .snippets, .spam ,.out-bound{
    .user-flex ,.outbound-flex{
      display: flex;
      align-items: center;
      .no-avatar {
        background: var(--search-box-bg);
        border-radius: 50%;
        height: 32px;
        width: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        flex-shrink: 0;
      }
      .user-desc {
        margin-left: 10px;
        .user-name {
          font-weight: 600;
          //color: var(--text-primary);
        }
        .user-mail {
          font-size: 13px;
        }
      }
    }
  }
  .spam {
    .ant-tabs-bar {
      padding-top: 0;
      padding-left: 30px;
      margin-bottom: 20px;
    }
    .ant-tabs-content {
      .ant-tabs-tabpane {
        padding: 5px 20px;
        &.spam-filters-tab-pane {
          padding-right: 0px;
          &.ant-tabs-tabpane-active {
            height: calc(100vh - 240px);
            overflow-y: auto;
          }
        }
      }
    }
  }
  .users {
    .ant-select-arrow {
      right: 6px;
    }
    .ant-select {
      .active {
        height: 8px;
        width: 8px;
        display: inline-block;
        background-color: $success-color;
        border-radius: 50%;
      }
      .inactive {
        height: 8px;
        width: 8px;
        display: inline-block;
        background-color: var(--text-light);
        border-radius: 50%;
        margin-right: 5px;
      }
    }
    .user-action{
      .resend {
        margin-right: 5px;
      }
      .delete {
        background: var(--search-box-bg);
        padding: 8px 15px;
        font-weight: 500;
        color: var(--text-secondary);
        border-radius: 4px;
        height: auto;
        border: none;
        box-shadow: none;
      }
    }
    .properties-table {
      .ant-table-tbody {
        overflow-y: auto !important;
        td:nth-child(2) {
          word-break: break-word;
        }
      }
    }
    .user-flex {
      .no-avatar {
        background-color: var(--profile-picture-bg);
      }
    }
  }
  .qualification-data {
    .description,
    .qualification-list-item {
      display: inline-block;
      line-height: 1.54;
      color: var(--text-primary);
    }
    .qualification-list {
      .qualification-list-block {
        display: flex;
        align-items: baseline;
        .anticon-delete {
          margin-left: 5px;
          font-size: 17px;
          color: var(--text-light);

          &:hover {
            color: $settings-delete-button;
          }
        }
      }
      .qualification-list-item {
        width: 240px;
        height: 35px;
        border-radius: 3px;
        border: 1px solid var(--border-gray);
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        margin-right: 10px;
        padding: 8px;
        background: var(--bg-white);
        text-transform: capitalize;

        i {
          color: var(--icon-color-dark) !important;
          font-size: 13px;
          margin-right: 8px;
        }
      }
    }
    .add-qualification {
      color: var(--primary-color);
      cursor: pointer;
      &.disabled {
        pointer-events: unset;
      }
    }
  }

  .profile-master-switch,
  .profile-meeting-link-switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .ant-row {
      margin: 0 !important;
    }
  }
  .profile-master-switch {
    border: solid 1px var(--border-gray);
    border-radius: 5px;
    padding: 15px 20px;
  }

  .meeting-profile-form {
    .profile-link {
      display: inline-flex;
      align-items: center;
      width: 590px;
      .ant-input {
        width: 170px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
      .copy-link {
        color: var(--primary-color);
        font-weight: 500;
        cursor: pointer;
        padding: 6px 8px 7px;
        background: var(--input-light-bg);
        border: 1px solid var(--input-box-border);
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-left: 0;
      }
    }
    .contact {
      .ant-form-item-children {
        display: flex;
        align-items: center;
      }
    }
  }

  .general-profile-settings {
    width: 590px;
    .ant-upload {
      cursor: pointer;
      .profile-image {
        position: relative;
        &:hover {
          .edit-profile {
            color: var(--text-light);
            display: block;
            position: absolute;
            font-size: 22px;
            color: var(--text-white);
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            text-align: center;
          }
          .profile-avatar:after {
            height: 100%;
            width: 100%;
            position: absolute;
            background: var(--bg-darker);
            opacity: 0.6;
            content: '';
            border-radius: 50%;
            left: 0;
          }
        }
      }
      .edit-profile {
        display: none;
      }
    }
  }

  .ant-form-text {
    line-height: 1.54;
    font-size: 13px;
  }

  .profile-details {
    .ant-radio {
      display: none;
    }
    .theme {
      display: flex;
      align-items: center;
    }
    .ant-upload {
      margin-left: 20px;
    }
    span.ant-upload {
      margin-left: 0;
    }
    .ant-row{
      margin-top: 10px;
    }
    .ant-form-extra {
      margin-left: 20px;
      margin-bottom: 10px;
    }
  }

  .pickup-theme {
    width: 200px;
    height: 120px;
    border-radius: 4px;
    border: solid 1px var(--bg-gray);
    background-color: var(--bg-gray);
    margin-bottom: 8px;
    cursor: pointer;
    display: block;
    background-position:center;
    background-repeat:no-repeat;
    background-size:cover;
    img {
      width: 50%;
      border: none !important;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      height: 118px;
    }
    &.active {
      border: solid 1px var(--primary-color);
    }
  }

  .calendar-integration {
    .ant-list-item-meta {
      align-items: center !important;
    }
    .ant-select-selection {
      padding: 3px 5px 5px 5px;
    }
    .ant-select-selection--multiple {
      .ant-select-selection__rendered {
        .ant-select-selection__choice {
          background-color: var(--bg-gray);
          border-radius: 3px;
        }
      }
    }
    .connect-google-calendar {
      width: 367px;
      .ant-form-text {
        margin-top: 20px;
      }
    }
    .connected-calendar-settings {
      width: 590px;
      .ant-list-item {
        border-radius: 3px;
        border: 1px solid var(--border-gray);
        padding: 12px 20px;
        margin-bottom: 15px;
        .ant-list-item-meta {
          .ant-list-item-meta-title {
            color: var(--text-secondary);
          }
          .ant-list-item-meta-description {
            color: var(--text-light);
          }
        }
      }
      .connected-account-details {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .ant-btn {
          border: none;
          padding: 0 0 11px 0;
        }
      }
      .select-calendars {
        margin: 0 8px 6px 0;
      }
    }
  }

  .unavailability {
    .unavailable-date-holder {
      display: flex;
      align-items: center;
      margin-top: 15px;
      margin-bottom: 10px;
      height: 35px;
      color: var(--text-primary);

      .ant-calendar-picker {
        width: 160px;
        height: 35px;
        .ant-input {
          height: 35px;
        }
        .ant-calendar-picker-icon {
          margin-right: 0;
        }
      }
      .unavailable-date {
        border: 1px solid var(--border-gray);
        padding: 6px 12px;
        border-radius: 3px;
        width: 160px;
      }

      i {
        color: var(--icon-color-dark);

        &.anticon-calendar {
          margin-right: 10px;
        }

        &.fa-trash {
          margin-left: 15px;

          &:hover {
            color: $error-color-dark;
          }
        }
      }
    }
    .add-date {
      color: var(--primary-color);
      font-weight: 500;
      margin-top: 10px;
      span {
        cursor: pointer;
      }
    }
  }

  .meetings {
    padding: 0;
    .ant-tabs {
      .ant-tabs-bar {
        margin: 0;
        padding: 5px 20px 0;
      }
      .ant-tabs-content {
        .ant-tabs-tabpane {
          height: calc(95vh - 100px);
          overflow-y: auto;
          padding: 20px 30px;
        }
      }
    }
  }
  .notification-container,
  .pipelines-and-stages-container {
    padding: 20px 30px;
    background: var(--bg-white) !important;
    width: 100%;
    overflow: auto;
    .element {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
    }
    h1 {
      font-size: 18px;
      font-weight: 600;
      color: var(--text-primary);
    }
    .info {
      font-size: 13px;
      font-weight: normal;
      color: var(--text-primary);
      margin-bottom: 20px;
    }
    .label {
      margin-right: 10px;
      font-size: $font-size-base;
      font-weight: 600;
      color: var(--text-primary);
      vertical-align: middle;
    }
    .ant-form-item {
      margin-bottom: 0;
    }
    .notify-switch {
      margin-right: 22px;
    }
    .notification-switches,
    .pipeline-content {
      border: solid 1px var(--border-gray);
      padding: 15px 40px 15px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: none;

      .ant-row {
        background: var(--bg-white);
        border: none !important;
      }

      .text {
        width: 70%;
        h1 {
          font-size: $font-size-base;
          font-weight: 600;
          color: var(--text-primary);
        }
        p {
          font-size: 13px;
          font-weight: normal;
          color: var(--text-primary);
          margin-bottom: 0;
        }
      }
    }
    .last-child {
      border-bottom: solid 1px var(--border-gray);
    }
    .ant-row-flex {
      .ant-col:last-of-type {
        text-align: center;
      }
      h1 {
        font-size: 18px;
        font-weight: 600;
        color: var(--text-primary);
      }
      p {
        font-size: $font-size-base;
        font-weight: 600;
        color: var(--text-primary);
      }
    }

    .column-sorter-container {
      .ant-row {
        border-bottom: 1px solid var(--border-gray) !important;
        border-left: 1px solid var(--border-gray) !important;
        border-right: 1px solid var(--border-gray) !important;
        border-top: none !important;
        padding: 0 !important;
        background: var(--bg-white);
        .ant-col {
          padding: 20px 15px;
          &:last-of-type {
            padding: 16px;
          }
        }
      }
    }
  }

  .pipelines-and-stages-container {
    .ant-row {
      border-top: 1px solid var(--border-gray) !important;
      border-bottom: 1px solid var(--border-gray) !important;
      padding: 0 !important;
      background: var(--table-header-bg);
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      .ant-col {
        padding: 12px 15px;

        span {
          font-size: $font-size-base;
          font-weight: 500;
        }
      }
    }
  }
  .bot-identity,
  .custom-email-template {
    .color-flex {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 7px;
      }
      input {
        width: 100px;
        &:focus {
          outline: none;
        }
      }
      > span {
        flex-basis: 20%;
      }
      .color-actions {
        display: flex;
      }
    }
    .color-picker {
      margin-right: 0;
      margin-left: 10px;
    }
  }
  .appearance {
    .ant-collapse {
      background: var(--bg-white);

      & > .ant-collapse-item {
        background: var(--bg-white);

        &.ant-collapse-item-active {
          .ant-collapse-content-active {
            border-top: none;
            overflow: visible;
            .ant-collapse-content-box {
              padding-top: 0;
              padding-bottom: 10px;
              .continue{
                margin-bottom: 20px;
                border-radius: 0 0 5px 5px;
                border-bottom: 1px solid var(--border-light);
              }
            }
          }
        }
        .ant-collapse-content-box {
          padding: 0 20px 20px 20px;
        }

        & > .ant-collapse-header {
          padding: 20px 20px;
          font-size: 16px;
          font-weight: 500;
          .ant-collapse-extra {
            color: var(--gray-700);
            font-size: $font-size-base;
            line-height: 20px;
            float: none;
            margin-top: 5px;
            font-weight: 400;
          }
          i {
            left: auto !important;
            right: 20px;
          }
        }
      }
    }
    div {
      & > h3 {
        font-size: 18px;
        font-weight: 600;
        color: rgb(24, 44, 64); // Theme Independent
      }
      & > h4 {
        color: rgb(145, 155, 176);
      }
    }

    .background-image-opacity {
      width: 400px;
    }
    .bg-image-picker {
      width: 48px;
      height: 48px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      margin-right: 10px;
      border-radius: 50%;
      cursor: pointer;

      &.checked {
        border: 1px solid var(--primary-color) !important;
        transform: scale(1.1);
      }
    }
  }

  .bot-identity,
  .appearance,
  .custom-email-template,
  .meetings {
    .color-picker {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      border-radius: 50%;
      cursor: pointer;
      position: relative;
      border: 1px solid var(--border-light);

      &.custom-color-picker {
        background: var(--bg-white);
        border: 1px solid var(--border-light);
        position: relative;
        svg{
          width: 100%;
          height: 55%;
          top: 7px;
          position: relative;
          transform: rotate(45deg);
        }
      }
      &.checked:after{
        position: absolute;
        content: '\2713';
        top: calc(50% - 13px);
        left: calc(50% - 8px);
        color: white;
        font-size: 16px;
      }
      &.light.checked:after {
        color: black;
      }

      &.black:after {
        color: var(--text-secondary) !important;
      }
      &.color-picker-lg {
        width: 48px;
        height: 48px;
        border: 1px solid rgba(0, 0, 0, 0.1);

        &.checked {
          border: 1px solid var(--primary-color);
          transform: scale(1.1);
          &:after {
            color: var(--primary-color);
          }
        }
      }
    }
    .messenger-bubble{
      float: right;
      .circle-close{
        box-shadow: 0 2px 52px 0 rgba(0, 0, 0, 0.12);
        border-radius: 50%;
        width: 48px;
        height: 48px;
        background: var(--primary-color);
        color: white;
        margin-top: 20px;
        img{
          width: 100%;
        }
      }
    }
  }
  .bot-identity{
    .header{
      margin-bottom: 10px;
      h1{
        font-size: 18px;
      }
      p{
        font-size: 13px;
      }
    }
    .ant-form{
      width: unset;
      input,
      .bot-name{
        max-width: 400px;
      }
      .bot-name {
        position: relative;
        .character-count {
          position: absolute;
          right: 3px;
          top: 5px;
        }
      }
      .border-class {
        .ant-form-item + .ant-form-item {
          margin: 0;
        }
      }
    }
    .custom-heading{
      &:not(:last-child){
        margin-bottom: 20px;
      }
    }
    .bot-icon{
      width: 60px;
      height: 60px;
      margin-top: 15px;
      cursor: pointer;
      opacity: 0.3;
      display: inline-block;
      &:not(:last-child){
        margin-right: 15px;
      }
      &.checked{
        opacity: 1;
      }
      &.custom-checked:hover {
        opacity: 0.7;
      }
    }
    .bot-appearance{
      width: 390px;
      .bot-box{
        position: relative;
        height: 100px;
        padding: 20px;
        box-shadow: 0 36px 64px 0 rgba(0,0,0,0.12);
        border-radius: 15px 15px 0 15px;
        display: flex;
        background: #fff; // Theme independent

        .bot-img {
          width: 65px;
          margin-right: 15px;
        }
        .bot-details{
          flex-grow: 1;
          h5{
            overflow: hidden;
            max-width: 240px;
            text-overflow: ellipsis;
          }
        }
        .fa-xmark{
          font-size: 11px;
          font-weight: 600;
        }
      }
      .chat-corner{
        position: absolute;
        right: 0;
        color: var(--text-white);
        bottom: -28px;
      }
    }
    .time-period {
      input {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
      .ant-input{
        width: 75px;
      }
      margin-top: 3px;
      .ant-input-group {
        display: flex;
        align-items: center;
        & > span {
          margin-right: 10px;
        }
        .ant-input-group-addon {
          border: none;
          background: transparent;
          .ant-select {
            height: 40px;
            margin: -5px -12px;
            .ant-select-selection {
              border-top-left-radius: 0 !important;
              border-bottom-left-radius: 0 !important;
              border-left: 0;
            }
          }
          .column-select {
            .ant-select-selection:hover {
              outline: 0;
              -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
              box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
              border-right-width: 1px !important;
            }
            .ant-select-selection {
              margin: 0px;
            }
          }
        }
      }
    }
  }
  .double-opt-in {
    .full-width {
      max-width: 100% !important;
    }
    .froalaEditor {
      margin-bottom: 20px;
    }
    .message-preview {
      .plain-email {
        padding: 0 20px;
      }
    }
  }
  .ant-tabs-bar {
    margin-bottom: 0;
  }
  .ant-tabs-content {
    padding-top: 20px;
  }

  .see-language {
    margin-left: 10px;
    color: var(--primary-color);
    cursor: pointer;
    &.disabled {
      pointer-events: inherit;
    }
  }

  .table-container {
    .outbound-status-select {
      .ant-select-selection {
        .ant-select-selection__rendered {
          .ant-select-selection-selected-value {
            padding-left: 10px;
          }
        }
      }
    }
  }

  .custom-templates {
    .ant-tabs-content {
      height: calc(100% - 40px);
      overflow: auto;
    }
  }
}

.emojis{
  .ant-popover-inner{
    .ant-popover-inner-content{
      padding: 0 !important;
      .emoji-mart{
        border:0;
      }
    }
  }
}

.ant-drawer-wrapper-body{
  position: relative;
  .modal-close{
    .fa-xmark{
      font-size: 8px;
      position: relative;
      top: -2px;
    }
    position: absolute;
    top: 16px;
    right: 16px;
    padding: 0 6px;
    border-radius: 50%;
    background: var(--text-light);
    color: white;
    cursor: pointer;
    transform: scale(0.7);
    &:hover{
      transform: scale(1);
    }
  }
}

.drawer-content {
  .btn {
    &.btn-primary {
      margin-right: 10px;
    }
  }
  .action-buttons {
    //margin-top: 20px;
    .disabled-btn {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }
  .fa-caret-down {
    font-size: 11px;
  }
  .settings-check-box {
    display: flex;
    .form-item {
      &.switch-visible {
        margin-left: 16px;
      }
    }
  }
  .ant-row {
    .select-label {
      // margin-left: 12px;
    }
  }
  .select-label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
  }
  .input-emoji {
    caret-color: white;

    .anticon {
      color: var(--text-light);
    }
    .ant-input {
      padding-left: 0;
      border: 0;
      height: 20px;
      cursor: pointer;
      &:focus {
        box-shadow: none;
      }
    }
  }
}

.custom-email-template{
  .settings-heading{
    margin-bottom: 20px;
  }
  .mb-15{
    margin-bottom: 15px !important;
  }
  .ant-select-selection {
    min-width: 100px;
  }
  form {
    .form-text{
      font-size: 13px;
      color: var(--text-primary);
      margin-bottom: 15px;
    }
    .template-dropdown {
        width: auto !important;
        min-width: 120px;
        max-width: 250px;
      .ant-select-selection {
        .ant-select-selection-selected-value {
          text-transform: capitalize;
        }
      }
    }
    .ant-form-item-label {
      margin-bottom: 5px;
      label {
        color: var(--text-primary);
        font-weight: 600;
      }
    }
    .ant-form-item-control{
      line-height: 15px;
    }
  }
  .label{
    font-weight: 600;
    margin-bottom: 5px;
    color: var(--text-primary);
  }

  p {
    color: var(--text-primary);
    font-size: 13px;
    margin-bottom: 15px;
  }
  .left{
    display: inline-block;
    vertical-align: middle;
  }
  button{
    margin-bottom: 38px;
    float: right;
    display: flex;
    align-items: center;
  }
  .template-list{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .template{
      width: 242px;
      height: 150px;
      border-radius: 4px;
      border: 1px solid var(--bg-gray);
      margin: 0 20px 55px 0;
      cursor: pointer;
      &.active {
        border: solid 1px var(--primary-color);

        p {
          max-width: 128px;
        }
      }
      a{
        display: block;
        overflow: hidden;
        width: 242px;
        height: 150px;
        margin-bottom: 5px;
        iframe{
          border: none;
          pointer-events: none;
          width: 480px;
          height: 296px;
          transform: scale(0.5);
          transform-origin: 0 0;
          padding: 1px;
        }
      }
      p{
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 200px;
        color: var(--text-primary);
        font-size: $font-size-base;
        display: inline-block;
        margin-right: 9px;
        vertical-align: middle;
      }
      .ant-tag{
        border-radius: 4px;
        background-color: var(--primary-color) !important;
      }
    }
  }
}

.template-header {
  border-bottom: 1px solid var(--border-light);
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  a {
    width: 100%;
  }
  .btn-align {
    display: flex;
    button:first-of-type {
      margin-right: 10px;
    }
  }
  .settings-heading{
    display: inline;
  }
  .right{
    float: right;
    button{
      margin-right: 5px;
    }
  }
  .back {
    color: var(--text-primary);
    cursor: pointer;
    left: 30px;
  }
}
.email-template-editor-layout {
  padding: 20px 0;
  margin: 0 auto;
  width: 100%;
  background: inherit;
  .heading{
    font-weight: 600;
    margin-bottom: 10px;
  }
   .ant-alert {
    margin-bottom: 15px;
    border: none;
    padding: 8px 15px 10px 54px;

    .ant-alert-icon {
      position: absolute;
      top: 14px;
      left: 20px;
      font-size: 18px;
    }
  }
  .input-layout{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn-align{
      .btn-sm{
        margin-right: 10px;
        &:last-child{
          margin-right: 0;
        }

        &.danger {
          color: $error-color;
        }
      }
    }
  }
  input{
    width: 500px;
    margin-bottom: 20px;
  }
  h2{
    font-size: 16px;
    margin-bottom: 10px;
    color: var(--text-primary);
  }
  .message-editor {
    border: none;
    display: flex;
    min-height: 800px;
    /*min-width: 1220px;*/
    .template-editor {
      height: 100%;
      flex-basis: 65%;
      /*min-width: 750px;*/
      width: 100%;
      padding: 20px;
      background: var(--bg-white);
      border: 1px solid var(--border-light);
      border-radius: 5px;
    }
    .template-preview {
      /*min-width: 640px;*/
      width: 100%;
      height: 100%;
      border: 1px solid var(--border-light);
      border-radius: 5px;
      .message-preview {
        background: var(--bg-white);
        border-radius: 5px;
        height: 100%;
        //max-height: 500px;
        //overflow: auto;
        .preview-container {
          height: 100%;
          .content {
            color: var(--text-primary);
            margin-bottom: 0;
            //max-height: 1000px;
            //overflow-y: auto;
            height: 100%;
            padding: 0;
            overflow-x: hidden;
            //white-space: pre-line;
            word-break: break-word;

            iframe {
              height: calc(100% - 113px);
              width: 100%;
              border: none;
              background: var(--bg-white);
            }
          }
        }
        &.is-mobile {
          width: 380px;
          margin: 0 auto;
          .ellipse {
            //display: none;
          }
        }
        .ellipse {
          padding: 12px 20px;
          border-bottom: 1px solid var(--border-light);

          .label {
            display: inline-block;
            margin-right: 10px;
            padding: 0;
            width: 10px;
            height: 10px;
            border-radius: 8px;
            &.danger {
              background-color: $preview-browser-close-color;
            }
            &.yellow {
              background-color: $preview-browser-expand-color;
            }
            &.success {
              background-color: $preview-browser-minimize-color;
            }
          }
        }
        .subject {
          padding: 10px 30px;
          border-bottom: 1px solid var(--border-light);
          font-weight: 600;
          color: var(--text-secondary);
        }
      }
      .device-switcher {
        text-align: center;
        padding: 25px 0 10px;
        .ant-radio-button-wrapper {
          background: transparent;
          &.ant-radio-button-wrapper-checked {
            background: var(--bg-white);
          }
        }
      }
    }
  }
  #bee-plugin-container {
    border: 1px solid var(--border-light);
  }
}

.api-key-container {
  .container {
    max-width: 600px;
    color: var(--text-primary);

    .heading {
      margin-bottom: 20px;
      h1 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 8px;
      }
      p{
        font-size: 13px;
        margin-bottom: 15px;
      }
    }
    .form-element {
      label {
        display: block;
        font-weight: 500;
        margin-bottom: 6px;
      }
      .item {
        display: flex;
        align-items: center;
        .ant-input-password {
          .ant-input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            padding-right: 30px;
          }
        }
        span + span {
          font-weight: 500;
          color: var(--primary-color);
          cursor: pointer;
          padding: 8.5px 16px;
          background: var(--input-light-bg);
          border: 1px solid var(--input-box-border);
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          border-left: 0;
        }
        #access-token {
          display: none;
        }
      }
    }
  }
}

.subscription-container {
  .container {
    width: 600px;
    color: var(--text-primary);

    .heading {
      margin-bottom: 20px;
      border-bottom: 1px solid var(--border-light);

      .title {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        .plan-name {
          flex: 1;
          h1 {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 0;
            text-transform: capitalize;
            display: inline-block;
          }
          > span {
            font-size: $font-size-base;
            margin-left: 5px;
            font-weight: normal;
          }
        }
        a {
          float: right;
          font-size: $font-size-base;
        }
        button {
          margin-left: 20px;
        }
      }
      p{
        margin-bottom: 5px;
      }
    }
    .usage-detail {
      margin-bottom: 45px;
      a {
        color: var(--primary-color);
        font-weight: 600;
      }
      .title {
        margin-bottom: 12px;
        span {
          &:first-child {
            font-weight: 600;
            font-size: 18px;
            margin-right: 5px;
          }
        }
      }
      .form-element {
        margin-bottom: 16px;
        label {
          span {
            text-transform: capitalize;
          }
          display: block;
          font-weight: 600;
        }
      }
      p {
        margin-bottom: 5px;
      }
      .ant-progress-bg {
        background: var(--primary-color) !important;
      }

      .add-on {
        margin-top: 15px;
        .add-on-details {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          button {
            margin-left: 10px;
          }
        }
      }
    }
    .payment-detail {
      .title {
        display: flex;
        align-items: center;
        font-weight: 600;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid var(--border-light);

        h3 {
          font-size: 16px;
          flex: 1;
          margin-bottom: 0;
        }
        span {
          cursor: pointer;
          color: var(--primary-color);
        }
      }
      .payment-list {
        .item {
          display: flex;
          align-items: center;
          & + .item {
            margin-top: 15px;
          }
          > i {
            font-size: 20px;
            margin-right: 10px;
          }
          .content {
            flex: 1;
            display: flex;
            span {
              &:first-child {
                width: 270px;
              }
            }
            p {
              margin-bottom: 0;
              font-size: 13px;
            }
          }
          .right {
            i {
              font-size: 16px;
              color: var(--text-light);
            }
          }
        }
      }
    }
  }
}

.add-card-drawer,
.add-shared-email-drawer {
  z-index: 1002;
  h2 {
    margin-bottom: 25px;
    font-size: 18px;
    font-weight: 600;
  }
  .ant-row {
    margin-bottom: 15px !important;
    &.toggle-switch {
      .ant-form-item-label {
        margin-bottom: 0;
        margin-right: 10px;
      }
      .ant-form-item-control-wrapper {
        display: inline-block;
      }
    }
    .ant-calendar-picker {
      width: 100%;
    }
    &.footer {
      margin-top: 30px;
      button {
        & + button {
          margin-left: 10px;
        }
      }
    }
    .ant-form-item-label {
      line-height: normal;
      margin-bottom: 8px;
      color: var(--text-primary);
      font-weight: 500;
      label:after {
        display: none;
      }
      .ant-form-item-required {
        padding-right: 10px;
        color: var(--text-primary);

        &:before {
          content: ''; // hide the star mark
          right: 0;
          margin-right: 0;
          position: absolute;
        }
        &:after {
          display: none;
        }
      }
    }
  }
  .anticon-loading {
    color: var(--text-white);
  }
  .card-form {
    label {
      color: var(--text-primary);
      font-weight: 500;
    }
    input,
    .StripeElement {
      margin-top: 5px;
      color: var(--text-primary);
      border-radius: 4px;
      height: auto;
      border: 1px solid var(--border-light) !important;
      box-shadow: none !important;
      font-size: $font-size-base;
      padding: 8.5px 15px;
      margin-bottom: 15px;
      &:hover,
      &:active {
        border: 1px solid var(--primary-color) !important;
      }
    }
    input::placeholder {
      color: var(--text-light);
      font-weight: normal;
    }
    button {
      & + button {
        margin-left: 10px;
      }
    }
    .default-check-box {
      margin-bottom: 15px;
    }
  }
  .switch-toggle {
    span {
      margin-left: 10px;
    }
  }
}

.billing-detail-section {
  width: 400px;
  margin-bottom: 20px;
  .ant-form {
    width: 100%;
    .ant-row {
      margin-bottom: 15px;
      .ant-form-item-required::before {
        display: none;
      }
      .description {
        margin-bottom: 10px;
        line-height: normal;
      }
    }
  }
}

.ant-form-item-children p {
  margin-bottom: 10px;
}

.margin-b-15 {
  margin-bottom: 15px;
}

.out-bound {
  .domain-verify{
    margin-bottom: 40px;
    h1{
      font-size: 15px;
      font-weight: 600;
      color: var(--text-primary);
    }
    .card{
      display: flex;
      padding: 17px 20px;
      max-width: 900px;
      border: 1px solid var(--border-light);
      background: var(--bg-white);
      border-radius: 4px;
      align-items: center;
      font-size: 13px;
      margin-bottom: 10px;
      .domain-name {
        flex: 1;
      }
      p {
        margin-bottom: 2px;
        font-weight: bold;
      }
      .status {
        height: 8px;
        width: 8px;
        border-radius: 50%;
        margin-right: 5px;
        display: inline-block;
        .not-connect {
          color: var(--border-light);
        }
        &.active {
          background: $success-color;
        }
        &.inactive {
          background: var(--text-light);
        }
      }
      .status-active {
        color: $success-color;
      }
      .status-inactive {
        color: var(--text-light);
      }
    }
  }
  .outbound-header {
    margin-bottom: 10px;
    .filter-section {
      margin-bottom: 8px;
      .left {
        .settings-heading {
          margin-bottom: 8px;
        }
        p {
          font-size: 13px;
          color: var(--text-primary);
          text-align: left;
        }
      }
    }
    .right {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
    }
  }
  .info{
    font-size: 13px;
    color: var(--text-primary);

    strong {
      font-size: 13px;
      color: var(--text-primary);
      font-weight: bold;
    }
  }
  .ant-select,
  .status{
    .inactive {
      height: 8px;
      width: 8px;
      display: inline-block;
      background-color: var(--text-light);
      border-radius: 50%;
      margin-right: 5px;
    }
    .active {
      height: 8px;
      width: 8px;
      display: inline-block;
      background-color: $success-color;
      border-radius: 50%;
    }
  }
  .outbound-action {
    &.edit {
      background: var(--search-box-bg);
      padding: 8px 15px;
      font-weight: 500;
      color: var(--text-secondary);
      border-radius: 4px;
      height: auto;
      border: none;
      box-shadow: none;
    }
  }
}
.outbound-drawer,
.outbound-verfication-drawer,
.bot-identity {
  .drawer-heading {
    font-size: 18px;
    font-weight: bold;
    color: var(--text-primary);
    margin-bottom: 10px;
  }

  .outbound-form {
    .ant-form-item-label {
      margin-bottom: 5px;
      label {
        color: var(--text-primary);
        font-weight: 500;
        &:after {
          content: '';
        }
      }
    }
    p {
      font-size: 13px;
      color: var(--text-light);
      margin-bottom: 0px;
    }
    .ant-form-item-control {
      line-height: 15px;
      .ant-avatar {
        height: 60px !important;
        width: 60px !important;
        cursor: pointer;
      }
    }
  }
  .ant-upload {
    cursor: pointer;
    .no-image {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--text-white);
      background: var(--search-box-bg);
      font-size: 24px;
    }
    .custom-image {
      border: 2px solid var(--black);
      background: none;
    }
    .profile-image {
      position: relative;
      .fa-plus {
        color: var(--text-white);
        font-size: 24px;
      }
      &:hover {
        .edit-profile {
          color: var(--text-light);
          display: block;
          position: absolute;
          font-size: 22px;
          color: var(--text-white);
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          right: 0;
          text-align: center;
        }
        .profile-avatar:after {
          height: 100%;
          width: 100%;
          position: absolute;
          background: var(--bg-darker);
          opacity: 0.6;
          content: '';
          border-radius: 50%;
          left: 0;
        }
        .custom-icon-hover {
          width: 60px;
          height: 60px;
          position: absolute;
          border-radius: 60px;
          background: rgba(82, 110, 132, 0.6);
        }
        .custom-display {
          display: none;
        }
      }
    }
    .edit-profile {
      display: none;
    }
  }
}

.shared-team-container {
  .container {
    .heading {
      margin-bottom: 20px;
      h1 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 8px;
      }
      p{
        font-size: 13px;
        margin-bottom: 0;
      }
    }
    .form-element {
      label {
        display: block;
        font-weight: 500;
        margin-bottom: 6px;
      }
      .item {
        display: flex;
        align-items: center;
        span + span {
          font-weight: 500;
          color: var(--primary-color);
          cursor: pointer;
          padding: 8.5px 16px;
          background: var(--input-light-bg);
          border: 1px solid var(--border-dark);
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          border-left: 0;
        }
        & > .ant-input {
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
          background: var(--bg-white);
        }
        #team-email {
          display: none;
        }
      }
      &:last-child {
        margin-bottom: 20px;
      }
    }
    .info-section {
      margin-bottom: 25px;
      p {
        margin-bottom: 0;
      }
    }
  }
  .shared-team-email-table {
    .header {
      display: flex;
      margin-bottom: 15px;
      align-items: center;
      .heading {
        flex: 1;
      }
    }
    .link-color {
      color: var(--primary-color);
      font-weight: 600;
    }
  }
}
.iframe-settings-layout {
  padding: 0;
}
.team-drawer {
  .drawer-description {
    font-size: 13px;
    color: var(--text-light);
    margin: 5px 0 10px 0 !important;
  }

  .no-records {
    padding: 15px 0px;
    font-size: 13px;
  }
  .custom-heading {
    color: var(--text-primary);
    margin-bottom: 10px;
    display: flex;
    .info{
      margin-right: 10px;
      & + .ant-row {
        margin-bottom: 0 !important;
      }
    }
    h4{
      font-weight: 400;
      margin-bottom: 5px;
    }
    &.center{
      align-items: center;
    }
    p{
      font-size: 13px;
      color: var(--text-light);
    }
  }

  .border-class {
    border: 1px solid var(--border-light);
    border-radius: 5px;
    padding: 15px 20px;
    max-width: 100% !important;
    .ant-form-item-label {
      margin-bottom: 0 !important;
    }
    .description {
      display: inline-block;
      width: calc(100% - 74px);

      & + button {
        vertical-align: top;
        bottom: 10px;
      }
    }

    label {
      color: var(--text-black);
      font-size: 15px;
      font-weight: 400;
    }
  }

  .ant-alert {
    margin-top: 20px;
    color: var(--text-color);
  }

  .ant-tabs-tabpane {
    padding-top: 5px;
  }
  #project_role_id {
    .ant-select-selection-selected-value {
      span {
        display: none;
      }
    }
  }
}
.property-drawer {
  .ant-form-item:nth-child(2) {
    .ant-form-item-children {
      position: relative;
      .character-count {
        font-size: 13px;
        color: var(--text-light);
        position: absolute;
        top: 28px;
        right: 10px;
      }
    }
  }
}
.transfer-ownership-form {
  label {
    margin: 10px 0px;
  }
  .ant-radio-wrapper:hover {
    .ant-radio-inner {
      border-color: var(--primary-color);
    }
  }
  .action-buttons {
    margin-top: 20px;
  }
}
.default-language-dropdown {
  max-width: 250px;
}

.user-status{
  .pending{
    margin-left:11px;
  }

  .inactive,
  [title='Hide']::before {
    background-color: var(--text-light);
  }
  .active,[title='Show']::before{
    background-color: $success-color;
  }
  .inactive,
  .active,
  [title='Show']::before,
  [title='Hide']::before{
    height: 8px;
    width: 8px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 5px;
  }
  [title='Show']::before,
  [title='Hide']::before{
    content:'';
  }
}

//Payment
.pay-due-modal {
  .ant-modal-content {
    text-align: center;
    .ant-modal-body {
      padding: 30px;
      h1 {
        color: var(--text-secondary);
      }
      .card-detail {
        color: var(--text-secondary);

        div {
          border-radius: 5px;
          padding: 15px;
          font-weight: 500;
          border: 1px solid var(--border-light);
          margin-top: 5px;
          box-shadow: 0 2px 4px 0 var(--black-opacity-bg) !important;
        }
      }
    }
    .ant-modal-footer {
      text-align: center;
      padding: 15px;
    }
  }
}

.redeem-coupon-container {
  h2 {
    margin-bottom: 20px;
  }
  .redeem-coupons-list {
    .redeem-coupon {
      display: flex;
      gap: 10px;
      .coupon-item {
        width: 40%;
        margin-bottom: 15px;
      }
      i {
        font-size: 11px;
        margin-top: 11px;
        cursor: pointer;
      }
      .invalid-coupon {
        border-color: #dd3636;
      }
      .invalid-coupon-text {
        color: #dd3636;
        margin-top: 5px;
        margin-left: 5px;
      }
    }
  }
  .footer {
    margin-top: 20px;
  }
}

.shopify-settings-modal,
.apps-container {
  .save-btn{
    text-align: end;
  }
  .sync-btn {
    padding: 4px 6px;
  }
}
.apps-container {
  .sync-btn {
    margin-left: 7px;
  }
}
.shopify-settings-modal {
  .description {
    font-size: 14px;
  }

  .ant-modal-body {
    height: 450px;
    overflow: auto;
  }

  .tracking-code-view-copy {
    // button copy to be inside code
    position: absolute;
    top: 60px;
    left: 372px;
  }
}

.fb-page-modal {
  .ant-radio-wrapper {
    display: block;
    margin-bottom: 15px;
    color: var(--text-primary);

    &.ant-radio-wrapper-checked {
      font-weight: 500;
      .ant-radio-inner {
        //border-color: var(--primary-color);

        &:after {
          background-color: var(--primary-color);
        }
      }
    }
    &:hover {
      .ant-radio-inner {
        border-color: var(--primary-color);
      }
    }
  }
}
.template-dropdown-items {
  text-transform: capitalize;
}

.plan-container {
  .body {
    height: 100%;
    .list-skeleton {
      width: 50%;
      min-width: 275px;
      max-width: 355px;
      & + .list-skeleton {
        margin-left: 15px;
      }
    }
    .plans{
      display: flex;
      .plan-features{
        flex-basis: 70%;
        > * {
          //margin-bottom: 20px;
        }
        .plan-tab {
          .ant-tabs-large-bar .ant-tabs-nav-container {
            font-size: 18px;
          }
          &.ant-tabs-card {
            .ant-tabs-card-bar {
              .ant-tabs-tab {
                padding: 6px 35px;
                height: 52px;
              margin-right: 6px;}
              .ant-tabs-tab-active {
                border-top: 1px solid var(--primary-color);
                height: 52px;
              }
              .ant-tabs-nav-container {
                height: 52px;
              }
            }
          }
        }
        .header{
          display: flex;
          width: 85%;
          h1{
            flex-grow: 1;
          }
        }

        h1 {
          color: var(--text-primary);
          font-size: 22px;
          font-weight: 600;
        }
        .features-info{
          h4{
            margin-bottom: 15px;
          }
          > div{
            width: 50%;
            float: left;
          }
        }
        .features {
          text-align: left;
          margin-bottom: 30px;
          li {
            color: var(--text-primary);
            list-style: none;
            margin-bottom: 10px;

            &.unavailable {
              color: $icon-color-light;
            }
            i {
              font-size: 12px;
              margin-right: 10px;
              font-weight: 600;

              &.fa-check {
                color: var(--primary-color);
              }
              &.fa-xmark {
                color: $error-color;
              }
            }
          }
        }
      }
      .plan-billed{
        flex-basis: 30%;
        border-left: 1px solid var(--border-light);
        min-width: 400px;
        .ant-skeleton{
          padding: 20px;
        }
        .billing-info{
          padding: 30px;
          border-radius: 3px;
          background-image: linear-gradient(
            304deg,
            var(--primary-color),
            #65bbff
          ); // TODO
          color: var(--text-white);
          display: flex;
          height: 270px;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          h2{
            color: var(--text-white);
            font-weight: 500;
          }
          .btn-default-transparent{
            background: transparent;
            border: 1px solid var(--white);
            color: var(--text-white);

            &:hover,
            &:active,
            &:focus{
              border: 1px solid var(--white);
              color: var(--text-white);
            }
          }
          p{
            font-weight: 600;
            font-size: 16px;
            &:last-child {
              margin-bottom: 0;
            }
          }
          button {
            display: block;
            margin-bottom: 10px;
            width: 220px;
          }
          .period{
            font-size: 15px;
            font-weight: 500;
            text-transform: capitalize;
          }
          .cost{
            .price{
              font-size: 44px;
            }
            .per{
              font-size: 25px;
            }
            margin-bottom: 10px;
          }
        }
        .recurring-fee{
          padding: 20px;
          p{
            margin-bottom: 0;
          }
          h3{
            padding-bottom: 20px;
            text-align: center;
            font-size: 18px;
          }
          h4{
            font-size: 15px;
          }
          .plan-types{
            display: flex;
            align-items: center;
            margin-top: 15px;
            .price-plan{
              flex-grow: 1;

              p {
                color: var(--text-light);
                font-size: 13px;
                margin-top: 5px;
              }
              h5{
                font-size: 15px;
                color: var(--text-secondary);
                font-weight: normal;
                margin-bottom: 0;
                max-width: 290px;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
            .price-estimate{
              font-size: 18px;
              font-weight: 500;
            }
          }
        }
        .amount-slider{
          padding: 20px;
          .slider{
            padding: 0px 20px;
          }

          .ant-slider {
            .ant-slider-handle,
            .ant-slider-dot-active {
              border: 2px solid var(--primary-color);
            }

            .ant-slider-track {
              background: var(--primary-color);
            }
          }
          .contacts{
            display: flex;
            font-size: 13px;
            input{
              width: 100px;
            }
            p{
              flex-grow: 1;
            }
          }
        }
      }
    }
  }
}
.editor-container{
  position: relative;
  .editor-copy-code { // style for copy script code
    padding: 16px;
    overflow: auto;
    font-size: 85%;
    line-height: 1.6;
    background-color: #f6f8fa;
    border-radius: 3px;
    color: var(--text-primary);
  }
  &:hover{
    .copy-btn{
      display: block;
      position: absolute;
      top:10px;
      right:10px;
    }
  }
  .copy-btn{
    display: none;
  }
}

.drawer-settings {
  .ant-form-item-label,
  .ant-form-item-control {
    line-height: 30px !important;
    margin-bottom: 5px;
  }

  .footer {
    margin-top: 0px !important;
  }
  .ant-col-24:last-child {
    padding-bottom: 0px !important;
  }
  .drawer-heading,
  .heading {
    h2 {
      font-size: 18px;
      color: var(--text-secondary);
      margin-bottom: 10px !important;
    }
  }
}

.property-action {
  .action-disable {
    .ant-dropdown-button {
      cursor: no-drop;
      display: none;
      button[disabled] {
        pointer-events: none;
      }
    }
  }
}

.double-opt-in {
  .ant-select-selection {
    padding: 0px 15px;

    .ant-select-selection-selected-value {
      img {
        height: 25px;
        width: 25px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
  }
  .email-composer-layout-preview {
    min-width: auto !important;
    .ant-tabs {
      width: 100%;
    }
  }
  .ant-form {
    width: auto !important;
  }
}

.separate-line{
  margin: 20px 0;
  height: 1px;
  width: 100%;
  background: var(--border-light);
  position: relative;
  span{
    position: absolute;
    top: 50%;
    left: 45%;
    transform: translate(50%, -50%);
    background: var(--bg-white);
    padding: 0 6px;
    font-size: 13px;
    font-weight: 600;
    color: var(--text-primary);
  }
}

.settings-content.email-settings {
  padding: 20px !important;
}

.more-action-dropdown {
  min-width: 130px;
  .ant-dropdown-menu-item {
    &:hover {
      background-color: var(--select-hover-color) !important;
    }
  }
}

.ant-select-dropdown-menu-item {
  padding: 8px 12px;
}

.company-property {
  .border-class {
    width: 700px;
    border: 1px solid var(--border-light);
    border-radius: 0 0 5px 5px;
    padding: 20px;
    margin-bottom: 5px;
  }
}

.ant-popover .ant-popover-content {
  //max-width: 350px;
}

.antd-img-crop-modal {
  .ant-modal-body {
    padding: 0 !important;

    .antd-img-crop-container {
      margin-bottom: 0;
    }

    .antd-img-crop-control {
      margin: 10px auto;
    }
  }
}

.user-preferences {
  &.theme {
    max-width: 700px;
  }
}

.roles-drawer {
  .ant-row {
    margin-bottom: 0 !important;
  }

  .roles-tabs {
    margin-top: 5px;
    margin-bottom: 80px;

    .border-class {
      padding: 15px 20px;

      label {
        font-size: 15px;
        font-weight: 400;
      }

      .ant-form-item-label {
        margin-bottom: 0;
      }
    }

    .custom-access-container {
      display: flex;
      margin-top: 15px;

      .ant-radio-wrapper {
        display: flex;
        font-weight: 400;
        line-height: 1;
        margin-bottom: 15px !important;
        margin-left: 70px;

        &:last-of-type {
          margin-bottom: 0 !important;
        }
      }

      .ant-form-item-label {
        line-height: 1 !important;
        font-weight: 500;
      }
    }
    .additional-info {
      margin-left: 175px;
      margin-top: -5px;
    }
    .hidden {
      visibility: hidden;
      height: 0;
      padding: 0;
      margin: 0;
      border: none;
    }
    .m-b-10 {
      margin-bottom: 10px !important;
    }
    .custom-apps-list {
      margin: 20px 0;
      .custom-app {
        display: flex;
        margin: 10px 0;
        .app-name {
          width: 40%;
        }
        .ant-switch {
          bottom: 0;
          margin: 3px 0;
        }
      }
    }
  }

  .drawer-footer {
    position: fixed;
    bottom: 0;
    width: 740px;
    padding: 20px 0;
    font-weight: 500;
    background: var(--bg-white);
  }
}

.stripe-climate {
  position: absolute;
  bottom: 20px;
  display: flex;
  align-items: center;
  color: #6b6f76;

  svg {
    margin-right: 10px;
    overflow: visible;
  }
}

.stripe-climate {
  position: absolute;
  bottom: 20px;
  display: flex;
  align-items: center;
  color: #6b6f76;

  svg {
    margin-right: 10px;
    overflow: visible;
  }
}

.user-preferences {
  &.theme {
    max-width: 700px;

    .switch-box {
      padding: 20px;
      border: 1px solid var(--input-box-border);
      border-radius: 5px;
      width: 100%;
    }
  }
}
.role-dropdown {
  .ant-select-dropdown-menu-item {
    display: block;

    span {
      float: right;
      padding: 0 5px;
      background: var(--primary-color-hover);
      color: var(--text-white);
      border-radius: 4px;
      font-size: 12px;
    }
  }
}

.stripe-climate {
  position: absolute;
  bottom: 20px;
  display: flex;
  align-items: center;
  color: #6b6f76;

  svg {
    margin-right: 10px;
    overflow: visible;
  }
}


.webhooks-tabs-layout {
  background: var(--bg-lighter);

  .ant-tabs-bar {
    text-align: center;
    background-color: #ffffff;
    margin-bottom: 0;
    position: relative;
  }

  .ant-tabs-content {
    .ant-tabs-tabpane {
      background: var(--bg-white);
      height: calc(100vh - 130px);
      overflow-y: auto;

      .details {
        min-height: 600px;
        background: var(--bg-white);
        min-width: 600px;
        max-width: 900px;
        overflow: visible;
        margin: 10px auto;
        border: 1px solid var(--border-gray);
        border-radius: 5px;
        padding: 30px;
        color: var(--text-secondary);

        .skeleton-title {
          margin-bottom: 20px;
        }

        .title {
          margin-bottom: 25px;
          color: var(--text-secondary);

          h1 {
            font-size: 18px;
            font-weight: 600;
            margin: 0;
          }

          p {
            margin: 0;
            font-size: 13px;
          }
        }

        .label,
        .ant-form-item-label label {
          color: var(--text-secondary);
          font-weight: 500;
          font-size: 14px;
          margin-bottom: 6px;
          display: block;
        }
      }

      .ant-btn.ant-dropdown-trigger {
        margin-top: 2px;
        margin-left: -49px;
        box-shadow: none;
      }

      .hidden {
        visibility: hidden;
      }

      .webhook-data-container {
        flex-flow: row;
        margin-bottom: 10px;

        .webhook-input + .webhook-input {
          margin-left: 10px;
        }

        .cancel-icon {
          display: flex;
          align-items: center;
          margin-left: 10px;

          i {
            font-size: 11px;
            color: var(--icon-color-dark);
            cursor: pointer;
          }
        }

        textarea {
          resize: none;
        }
      }

      .add-another {
        padding: 0;
        margin: 0;
      }
    }
  }

  .add-another {
    padding: 0;
    margin: 0;
  }
}

#pre-editor-code {
  padding: 16px;
  overflow: auto;
  font-size: 85%;
  line-height: 1.6;
  background-color: var(--ace-text-layer);
  border-radius: 3px;
  color: var(--text-primary);
}

.subscription-drawer {
  .action-buttons {
    margin-top: 20px;
  }
}

.charge-confirmation-modal{
  .action-button {
    display: flex;
    justify-content: flex-end;
  }
}
.sorter-dropdown {
  width: unset !important;
  min-width: 90px !important;
  max-width: 150px !important;
}

.calendar-connect-modal {
  .ant-modal-body {
    .ant-radio-group {
      display: flex;
      justify-content: center;
      gap: 20px;

      .ant-radio-button-wrapper {
        height: max-content;
        padding: 15px;
        border-radius: 6px;

        &:hover {
          color: var(--text-primary);
          border: 1px solid var(--primary-color);
        }

        .title {
          display: block;
          font-size: 17px;
          font-weight: 500;
          margin-bottom: 8px;
          margin-top: 5px;
        }

        p {
          margin-bottom: 0;
          line-height: 24px;
        }
      }

      .ant-radio-button-wrapper-checked {
        color: var(--text-primary);
        border: 1px solid var(--primary-color);
        background: var(--light-blue-color);
      }
    }
  }
}
.generate-user-hash {
  display: flex;
  .generate-button {
    margin-top: 29.5px;
  }
}
.security {
  &.settings-content {
    .choose-method-content {
      margin-top: 24px;
      .method-card-container {
        display: flex;
        .method-card {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 300px;
          padding: 20px;
          border: 1px solid var(--border-gray);
          border-radius: 4px;
          span {
            &.link {
              &:hover {
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
          i {
            font-size: 48px;
            color: #c7c7c7;
          }
        }
      }
    }
    .installation-main-container {
      padding: 10px 0px;
      .back-to-install {
        padding: 10px 0px;
        width: fit-content;
        .back-icon {
          font-size: 14px;
          margin-right: 8px;
        }
        .back-text {
          font-size: 14px;
          &:hover {
            text-decoration: underline;
          }
        }
        &:hover {
          cursor: pointer;
          color: blue;
          .back-icon {
            color: blue;
          }
        }
      }
      .lang-select {
        display: flex;
        align-items: center;
      }
      .HMAC-code-container {
        position: relative;
        .hmac-button {
          display: none;
        }
        &:hover .hmac-button {
          position: absolute;
          display: block;
          top: 10px;
          right: 10px;
        }
      }
      .track-code-container {
        position: relative;
        .trackCode-copy-button {
          display: none;
        }
        &:hover .trackCode-copy-button {
          position: absolute;
          display: block;
          top: 10px;
          right: 10px;
        }
      }

      .CodeMirror {
        height: 100%;
        min-height: 100px;
        .CodeMirror-scroll {
          background: #f6f6f6;
          padding: 12px;
          min-height: 184px;
        }
      }
    }
    .form-element {
      margin-bottom: 10px;

      label {
        display: block;
        font-weight: 500;
        margin-bottom: 6px;
      }

      .item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        width: 700px;

        .ant-input-password {
          .ant-input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            padding-right: 30px;
          }
        }

        span + span {
          font-weight: 500;
          color: var(--primary-color);
          cursor: pointer;
          padding: 8.5px 16px;
          background: var(--input-light-bg);
          border: 1px solid var(--input-box-border);
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          border-left: 0;
        }

        #identify-token {
          display: none;
        }
      }
    }
  }
}
.end-date-dropdown .ant-calendar-today-btn {
  display: none;
}
