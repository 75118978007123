@import 'variable';

%btn-common-style {
  border-radius: 4px;
  height: auto;
  border: none;
  box-shadow: none;
}
.btn {
  @extend %btn-common-style;
  &.btn-md {
    padding: 7px 15px;
  }
  &.btn-sm {
    padding: 4px 15px;
  }
  &.btn-lg {
    padding: 10px 15px;
  }
  &.btn-table {
    background-color: red;
  }
  &.btn-xs {
    padding: 4px;
    font-size: 12px;
  }
  &.btn-primary {
    background-color: var(--primary-color);
    color: var(--text-white); // Theme independent
    font-weight: 600;
    border: 1px solid var(--primary-color);
    &:active,
    &:hover {
      background: var(--primary-color-hover);
      border-color: var(--primary-color-hover);
      color: white; // Theme independent
    }
    &[disabled] {
      cursor: not-allowed;
    }
    &[aria-disabled='true'] {
      cursor: not-allowed;
    }
    i {
      color: inherit;
    }
  }
  &.btn-default {
    background-color: var(--search-box-bg);
    color: var(--text-light);
    font-weight: 600;
    border-color: var(--search-box-bg);
    &:active,
    &:hover {
      background-color: var(--search-box-bg);
      color: var(--text-light);
      border: 1px solid var(--input-box-border);
    }
  }
  &.btn-danger {
    background: $settings-delete-button;
    color: var(--text-white);
    font-weight: 600;
    border: 1px solid $settings-delete-button;
    &:active,
    &:hover {
      background: $settings-delete-button;
      color: var(--text-white);
      border: 1px solid $settings-delete-button;
    }
  }
  &.btn-danger-secondary {
    background: var(--bg-white);
    color: $settings-delete-button;
    font-weight: 500;
    border: 1px solid $settings-delete-button;
    i {
      margin-right: 5px;
      color: $settings-delete-button;
      font-size: $font-size-base;
    }
    &:active,
    &:hover {
      background: rgba(255, 88, 88, 0.05);
      color: $settings-delete-button;
      border: 1px solid $settings-delete-button;
    }
  }
  &.btn-success {
    background: $success-color;
    color: var(--text-white);
    font-weight: 600;
    border: 1px solid $success-color;
    &:active,
    &:hover {
      background: $success-color;
      color: var(--text-white);
      border: 1px solid $success-color;
    }
  }
  &.btn-light {
    background: var(--bg-white);
    color: var(--text-secondary);
    font-weight: normal;
    border: 1px solid var(--input-box-border);
    &:active,
    &:hover {
      background: var(--bg-white);
      color: var(--primary-color);
      border-color: var(--primary-color);
    }
  }
  &.btn-dark {
    background: var(--text-primary);
    color: var(--text-white);
    font-weight: 600;
    border: 1px solid var(--text-primary);
    &:active,
    &:hover {
      opacity: 0.75;
      background: var(--text-primary);
      border-color: var(--text-primary);
      color: var(--text-white);
    }
  }
  &.btn-secondary {
    background: var(--text-light);
    color: var(--text-white);
    font-weight: 600;
    border: 1px solid var(--text-light);
    &:active,
    &:hover {
      background: var(--text-light);
      border-color: var(--text-light);
    }
  }
  &.btn-secondary-outline {
    border: 1px solid var(--border-dark);
    background: var(--bg-white);
    color: var(--text-light);
    font-weight: 600;
    &[disabled] {
      border: solid 1px var(--border-dark);
      background: var(--bg-white);
      color: var(--text-light);
    }
    &:not(:disabled) {
      &:active,
      &:hover {
        border: 1px solid var(--primary-color);
        background: var(--bg-white);
        color: var(--primary-color);

        i {
          color: var(--primary-color);
        }
      }
    }
    &[disabled] {
      opacity: 0.4;
      cursor: not-allowed;
    }
    &[aria-disabled='true'] {
      opacity: 0.4;
      cursor: not-allowed;
    }
    &.disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
  &.btn-white {
    border: solid 1px var(--border-dark);
    background-color: var(--bg-white);
    color: var(--text-light);
    &:active,
    &:hover {
      color: var(--primary-color);
      border: solid 1px var(--primary-color);
    }
  }
  &.btn-default-secondary {
    border: solid 1px var(--border-light);
    background-color: var(--border-light);
    color: var(--text-light);
    &:active,
    &:hover {
      color: var(--text-light);
      border: solid 1px var(--border-light);
    }
  }
  &.btn-default-transparent {
    border: solid 1px var(--primary-color);
    background-color: transparent;
    color: var(--primary-color);
    &:active,
    &:hover {
      color: var(--text-light);
      border: solid 1px var(--primary-color);
    }
  }
}

.ant-dropdown-button {
  .ant-btn-default {
    @extend %btn-common-style;
    background-color: var(--table-header-bg);
    border: 1px solid var(--border-dark);
    padding: 3px 15px;
    height: 32px;
    font-weight: 500;
    border-radius: 0;
    color: var(--text-secondary);
    &:hover {
      background-color: var(--table-header-hover-bg);
    }
  }
  .ant-dropdown-trigger {
    padding-bottom: 3px;
    padding-top: 3px;
    margin-left: 1px;
    vertical-align: top;
    line-height: 0;
    .anticon-ellipsis {
      line-height: 0;
      svg {
        width: 20px;
        height: 30px;
        color: $icon-color-light;
      }
    }
  }
}

.tooltip-with-button {
  .ant-btn {
    @extend %btn-common-style;
    padding: 7px 15px;
    background-color: var(--primary-color);
    color: var(--text-white);
    font-weight: 600;
    border: 1px solid var(--primary-color);
    height: 36px;
    opacity: 0.4;
  }
  &.tooltip-secondary {
    .ant-btn {
      border: solid 1px var(--border-dark);
      background: var(--bg-white);
      color: var(--text-light);
      font-weight: 600;
      &:active,
      &:hover,
      &:focus {
        border: solid 1px var(--primary-color);
        background: var(--bg-white);
        color: var(--primary-color);
      }
    }
  }
}
