@import 'variable';
@import 'theme';
@import 'mixin';
@import 'button';
@import 'table';
@import 'input-element';
@import 'drawer';
@import 'antd-overrides';
@import 'omnisearch';
@import 'side-bar';
@import 'messages-editor';
@import 'froala-overrides';
@import 'helpers/flex';
@import 'helpers/margin';
@import 'helpers/padding';
@import 'helpers/border';
@import 'helpers/typography';
@import 'helpers/dimensions';
@import 'helpers/cursor';
@import 'helpers/helper';
@import 'dark-mode-overrides';

h1 {
  font-size: 24px;
  line-height: 32px;
}

h2 {
  font-size: 21px;
  line-height: 28px;
}

h3 {
  font-size: 18px;
  line-height: 24px;
}

h4 {
  font-size: 15px;
  line-height: 22px;
}

%flex-align-center {
  display: flex;
  align-items: center;
}

%three-dot-icon {
  i {
    opacity: 0.7;
    font-size: 18px;
    cursor: pointer;
    svg {
      @include prefixer(transform, rotate(90deg));
    }
    &:hover {
      opacity: 1;
    }
  }
}

.ant-menu-item {
  .count {
    opacity: 0.6;
    margin-left: 5px;
    color: var(--text-light);
  }
  &.ant-menu-item-selected .count {
    opacity: 1;
  }
}
.sidebar-tooltip {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .sub-title {
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1 1;
    line-height: 1.2;
  }

  .right {
    display: none;
    margin-left: 3px;
    span {
      i {
        cursor: pointer;
        font-size: $font-size-base;
        color: var(--text-light);

        &:not(.fa-trash) {
          &:hover {
            color: var(--primary-color);
          }
        }
        &:hover {
          &.fa-trash {
            color: $error-color;
          }
        }
      }

      &:first-child {
        margin-left: 2px;
      }
      &:last-child {
        margin-left: 8px;
      }
    }
  }
}

.sidebar-tooltip:hover {
  .right {
    display: inline;
  }
  &:not(.no-crud) .count {
    display: none;
  }
}

.rounded-image-md {
  height: 35px;
  width: 35px;
  border-radius: 50%;
}

.tag {
  text-transform: capitalize;
  .success {
    border-color: $label-success-bg;
    background-color: $label-success-bg;
    color: $label-success-text;
  }
  .warning {
    border-color: $label-warning-bg;
    background-color: $label-warning-bg;
    color: $label-warning-text;
  }
  .paused {
    border-color: var(--label-default-bg);
    background-color: var(--label-default-bg);
    color: var(--label-default-text);
  }
  .danger {
    border-color: $label-danger-bg;
    background-color: $label-danger-bg;
    color: $label-danger-text;
  }
  .info {
    border-color: $label-info-bg;
    background-color: $label-info-bg;
    color: $label-info-text;
  }
}

.author-dropdown {
  .ant-select-dropdown-menu-item {
    img {
      height: 24px;
      width: 24px;
      border-radius: 50%;
      margin: 0 !important;
    }
    i {
      width: 20px;
      margin-right: 0;
    }
    .author-name {
      margin-left: 15px;
    }
    .all-author {
      margin-left: 15px;
    }
    .active-status {
      border: 2px solid var(--white);
      height: 9.5px;
      width: 9.5px;
      border-radius: 6px;
      position: absolute;
      z-index: 1;
      left: 29px;
      top: 21px;
      &.active {
        background: $bg-success;
      }
      &.in-active {
        background-color: var(--bg-dark);
      }
    }
  }
  &.article-authors {
    max-width: 227px;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 190px !important;
    .ant-select-dropdown-menu {
      max-width: 235px !important;
    }
  }
}

.cus-drop-down {
  border-radius: 5px;
  box-shadow: 0 3px 10px 0 rgba(35, 58, 132, 0.08);
  border: solid 1px 1px solid var(--border-light);
  background: var(--bg-white);
  .ant-dropdown-menu-item {
    padding: 8px 20px;
    color: var(--text-primary);
    &.background {
      background: var(--bg-white);
    }
  }
}

.field-required {
  color: $error-color;
}

.personalize-dropdown {
  max-width: 200px;
}

.campaign-email-composer {
  .ant-row {
    .ant-form-item-label {
      .ant-form-item-required:before {
        right: 0;
        margin-right: 0;
        position: absolute;
        top: 0;
      }
    }
  }
}
.dropdown-list-style {
  .ant-dropdown-menu-item {
    padding: 10px 20px;
  }
}

.cus-select-drop-down {
  border-radius: 5px;
  box-shadow: 0 3px 10px 0 rgba(35, 58, 132, 0.08);
  border: solid 1px 1px solid var(--border-light);
  background: var(--bg-white);
  .ant-select-dropdown-menu-item {
    padding: 8px 20px;
    color: var(--text-primary);
    display: flex;
    align-items: center;
    &.background {
      background: var(--bg-white);
    }
  }
}

%profileImageText {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-secondary);
  background: var(--profile-picture-bg);
  flex-shrink: 0;
}

.profile-avatar {
  &.ssm {
    @include profileContainer(16px, 16px, 8px);
  }
  &.sm {
    @include profileContainer(24px, 24px, 9px);
  }
  &.md {
    @include profileContainer(30px, 30px, 12px);
  }
  &.lg {
    @include profileContainer(40px, 40px, 16px);
  }
  &.xl {
    @include profileContainer(60px, 60px, 24px);
  }
  &.xxl {
    @include profileContainer(64px, 64px, 24px);
  }
  &.sxl {
    @include profileContainer(48px, 48px, 22px);
  }
  &.text {
    @extend %profileImageText;
  }
}

.stripe-callback-container {
  width: 400px;
  padding-top: 150px;
  margin: 0 auto;
  &.loader {
    padding-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  h1 {
    margin-bottom: 8px;
  }
  p {
    margin-bottom: 30px;
  }
  button {
    width: 100%;
  }
}

.event-meta-select {
  .logical-operator {
    display: none;
  }
}
.logical-dropdown {
  .ant-select-dropdown-menu-item {
    display: block !important;
  }
  .logical-operator {
    display: block;
    float: right;
  }
}

.restricted-features {
  span {
    color: var(--primary-color);
    font-weight: 500;
    cursor: pointer;
    margin-right: 5px;
  }
}

.disabled {
  pointer-events: none;
  cursor: not-allowed !important;
}

.active-status {
  &.online {
    background: $bg-success;
  }
  &.inactive {
    background-color: var(--bg-dark);
  }
  &.offline,
  &.away {
    background: $conversation-teammate-away-color;
  }
}

.customize-drawer {
  .drawer-heading {
    margin-bottom: 0 !important;
    h2 {
      font-size: 18px;
      color: var(--text-secondary);
      margin-bottom: 5px;
    }
  }
  .ant-drawer-body {
    padding: 30px;
  }
  .heading {
    margin-bottom: 5px !important;
  }

  .ant-row {
    .ant-form-item-control {
      p {
        line-height: 25px !important;
      }
      .drawer-description {
        line-height: 20px !important;
        margin-top: 5px;
        //margin-bottom: 0px !important;
      }
    }
    .ant-form-item-required::before {
      margin-right: 0px !important;
    }
    .ant-form-item-control {
      margin-bottom: 0px !important;
    }
  }
  .ant-radio-wrapper {
    margin-bottom: 0px !important;
  }
  .btn-primary,
  .btn-secondary-outline {
    margin: 0px 10px 0px 0px !important;
  }
  .content {
    .template-upload-image {
      border-radius: 5px;
      border: 1px dashed var(--border-dark);
      cursor: pointer;
      width: 538px;
      max-height: 150px;
      text-align: center;
      div.browse {
        color: var(--primary-color);
        padding: 45px 0;
      }
      img {
        padding: 10px;
        max-width: 100%;
        max-height: 130px;
      }
    }
  }
  .certificateName {
    color: var(--primary-color);
  }
}

.workflow-action-drawer {
  .contact-reports,
  .content {
    padding: 20px;
  }
  .ant-drawer-body {
    padding: 0 !important;
  }
  .ant-tabs-tabpane {
    padding: 15px 0 !important;
  }
  .heading {
    margin-bottom: 0 !important;
  }
}
.contact-report-container {
  display: flex;
  justify-content: space-between;
  height: 100%;
  .content {
    width: 100%;
  }
  .contact-reports {
    width: 100%;
    padding: 20px 0 20px 20px;
    .header {
      display: flex;
      justify-content: space-between;
      gap: 70px;
      align-items: center;
      margin-bottom: 20px;
      .left {
        display: flex;
        gap: 10px;
        align-items: center;
      }

      .search {
        max-width: 300px;
      }
    }

    .contact-list {
      display: flex;
      flex-direction: column;
      gap: 15px;
      height: calc(100vh - 140px);
      overflow: auto;
      margin: 0 -15px;
      padding: 0 15px;

      .contact-item {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-around;
        padding-bottom: 15px;
        border-bottom: 1px solid var(--border-light);
        &:last-child {
          border-bottom: none;
        }
        .left {
          display: flex;
          gap: 10px;
          width: 30%;
        }
        & > span {
          width: 20%;
        }
        .item-label {
          width: 20%;
          font-weight: 600;
          &:first-child {
            width: 30%;
          }
        }
      }
    }
  }
  .sidebar {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-left: 1px solid var(--border-gray);
    text-align: center;
    z-index: 1;
    padding: 55px 8px 0;
    max-width: 20%;
    height: calc(100vh - 50px);
    .sidebar-item {
      border-radius: 6px;
      cursor: pointer;
      font-size: 17px;
      font-weight: 500;
      padding: 4px;
      position: relative;
      text-align: center;
      width: 42px;
      &:hover,
      &.selected {
        background: var(--conversation-selected-bg-color);
        color: var(--primary-color);
      }
    }
  }
}
.customize-drawer-space {
  .ant-row:nth-last-of-type(-n + 2) {
    margin-bottom: 20px !important;
  }
}

// snippet drawer
.customize-drawer-space {
  #description.froalaEditor {
    .fr-wrapper {
      .fr-element {
        img {
          @extend %editor-image;
          margin: 0 !important;
        }
      }
    }
  }
}
.fr-btn-grp {
  [id|='personalize'],
  [id|='conversation'],
  [id|='contact'],
  [id|='teammate'] {
    padding-left: 10px !important;
    padding-right: 20px !important;
    text-transform: capitalize;
  }

  [id|='personalize'] {
    width: 92px !important;
  }

  [id|='conversation'] {
    width: 95px !important;
  }

  [id|='contact'] {
    width: 65px !important;
  }

  [id|='teammate'] {
    width: 80px !important;
  }
}

.article-layout {
  .fr-box.fr-basic.fr-top {
    .fr-toolbar {
      position: sticky;
      top: 0;
      z-index: 10;
      border-radius: 0 !important;
      border-width: 0 1px 1px !important;
    }

    .fr-wrapper {
      border-top: none !important;
      table {
        td,
        th {
          border: 1px solid var(--border-dark);
        }
      }
    }
  }
}

.text-editor {
  .fr-box.fr-basic.fr-top .fr-wrapper {
    box-shadow: none !important;
    border: 1px solid var(--gray-300) !important;
    .fr-element.fr-view {
      color: var(--text-black);
    }
  }

  .fr-toolbar.fr-bottom,
  .fr-toolbar.fr-top {
    box-shadow: none !important;
    border: 1px solid var(--border-gray) !important;
    border-bottom: none !important;
  }

  .fr-toolbar .fr-command.fr-btn,
  .fr-popup .fr-command.fr-btn,
  .fr-modal .fr-command.fr-btn {
    color: var(--text-primary) !important;
  }

  p {
    //color: var(--text-primary) !important;
  }
}

// END: Froala Editor styles

.initial-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    font-size: 50px;
    color: var(--primary-color);
  }
}

.rails-iframe {
  height: 100vh;
  width: 100%;
  border: 0;
  &.product-update {
    background: var(--bg-white);
  }
}

//Alert messages
.alert {
  margin-bottom: 15px;
  border-radius: 3px;
  padding: 10px 15px;
  &.error {
    background-color: $alert-danger-bg;
    color: $alert-danger-text;
  }
  &.info {
    background-color: $alert-info-bg;
    color: $alert-info-text;
  }
  &.warning {
    background: $alert-warning-bg;
    color: $alert-warning-text;
  }
  span {
    font-weight: 600;
  }
}

.perform-math {
  text-align: center;
  width: 100%;
  &.divide {
    & > .ant-row {
      display: flex;
    }
    & > .ant-row,
    .ant-row-flex {
      flex-direction: row-reverse;
    }
  }
  .ant-input-number {
    color: inherit;
    margin-left: 0 !important;
  }
}

.content-container {
  height: 100%;
  .profile-layout {
    .profile-view {
      .profile-detail {
        .contact-details {
          .list {
            .description {
              .editable-cell-value-wrap {
                .contact-owner-details {
                  img {
                    margin-right: 10px;
                  }
                  .no-image-profile {
                    width: 20px;
                    height: 20px;
                  }
                }
              }
              &.contact-owner-desc {
                .editable-select {
                  .ant-select-selection-selected-value {
                    & > div {
                      img {
                        & + span {
                          margin: 0;
                        }
                      }
                    }
                  }
                }
              }
              .editable-cell-value-wrap,
              &.contact-owner-desc {
                .editable-select .ant-select-selection-selected-value,
                .contact-owner-details {
                  img {
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.customize-popover {
  max-width: 400px;
}
.hint-text {
  font-size: 13px;
  color: var(--text-light);
}

.preview-dropdown {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: var(--text-white);
  font-weight: 600;
  height: 32px;
  padding: 0 7px;

  &:active,
  &:hover,
  &:focus {
    background-color: var(--primary-color-hover);
    border: 1px solid var(--primary-color-hover);
    color: var(--text-white);
  }

  i {
    color: var(--text-white);
  }
}

.dropdown-button-container {
  padding: 10px;
  background: var(--bg-white) !important;
  border-radius: 5px !important;
  box-shadow: 0 3px 10px 0 rgba(35, 58, 132, 0.08);
  border: 1px solid var(--border-light);

  .search {
    margin-bottom: 15px;

    .ant-input-search {
      .ant-input {
        height: 35px;
        padding-left: 15px;
      }
    }
  }

  p {
    padding-left: 8px;
    margin-bottom: 5px;
  }

  .users-list {
    overflow-y: auto;
    max-width: 290px;

    .user-list {
      cursor: pointer;
      padding: 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &:hover {
        color: var(--primary-color);
      }
    }

    .no-results {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 185px;
      font-weight: 500;
      text-transform: capitalize;
    }

    .ant-spin {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.avatar-proflie {
  display: flex;
  align-items: center;
  min-width: 100px;
  white-space: pre-line;
  word-break: break-word;
  color: var(--primary-color);
  .title-wrapper {
    display: flex;
    align-items: center;
    .contacts-profile {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      width: 28px;
      height: 28px;
      margin-right: 10px;
      justify-content: center;
      background-color: var(--profile-picture-bg);
      border-radius: 28px;
      font-size: 12px;
      color: var(--text-secondary);
    }
  }
  .ant-avatar {
    margin-right: 10px;
    flex-shrink: 0;
  }
}

#mail_subject {
  & + .ant-input-suffix {
    z-index: 0;
    i {
      cursor: pointer;
      &:hover {
        color: var(--primary-color);
      }

      & + i {
        margin-left: 10px;
      }
    }
  }
}

.custom-fields-dropdown {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.ellipse {
  display: flex;

  .preview-section {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  .actions {
    button:first-child {
      margin-right: 8px;
    }

    .is-mobile {
      background: none;
      border: none;
      font-size: 18px;
      color: $icon-color-light;
      cursor: pointer;

      &:nth-of-type(2) {
        margin-left: 18px;
      }
    }
  }
}

.avatar-proflie {
  display: flex;
  align-items: center;
  min-width: 100px;
  white-space: pre-line;
  word-break: break-word;
  color: var(--primary-color);
  .title-wrapper {
    display: flex;
    align-items: center;
    .contacts-profile {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      width: 28px;
      height: 28px;
      margin-right: 10px;
      justify-content: center;
      background-color: var(--profile-picture-bg);
      border-radius: 28px;
      font-size: 12px;
      color: var(--text-secondary);
    }
  }
  .ant-avatar {
    margin-right: 10px;
    flex-shrink: 0;
  }
}

.top-navbar .fal fa-circle-question,
.top-navbar .fas fa-bell {
  font-size: 18px;
  position: relative;
  top: 2px;
}
.top-navbar .fal fa-circle-question:hover,
.top-navbar .fas fa-bell:hover {
  color: var(--text-white);
  cursor: pointer;
}

.ant-modal-footer {
  padding: 12px 20px;
  text-align: right;
  background-color: transparent;
  border-top: 1px solid var(--border-gray);
  border-radius: 0 0 4px 4px;
}

// Loading layout
.loading-layout {
  background: var(--bg-white);
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-tag {
  .ant-select-dropdown-menu {
    max-height: 600px;
  }
  .ant-select-dropdown-menu-item-disabled {
    display: none;
  }
}

//TODO: need to add space for chat
//.ant-table-body .ant-table-fixed {
//  padding-bottom: 70px;
//}

.user-list-table .ant-table-body .ant-table-fixed {
  padding-bottom: 0;
}

.section-table .ant-table-body .ant-table-fixed {
  padding-bottom: 0;
}

// table Loader added in table.scss
//.ant-spin-nested-loading .ant-spin-blur {
//  display: none!important;
//}
//.ant-table-wrapper .ant-spin-nested-loading>div>.ant-spin {
//  height: 65vh;
//  max-height: initial;
//}

.btn-primary .anticon-loading {
  color: var(--text-white);
}

.fal fa-circle-question:hover {
  cursor: pointer;
}

.beamer_icon.active,
#beamerIcon.active {
  top: 10px !important;
  left: 12px !important;
}

.visitors-cell {
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 75px;
  max-width: 350px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  .ant-avatar {
    width: 28px;
    height: 28px;
  }

  .chat {
    border: solid 1px var(--border-gray);
    padding: 2px 6px;
    border-radius: 5px;
    cursor: pointer;

    i {
      font-size: 14px !important;
      color: var(--text-light);
    }
  }
}

//.live-view-chat-modal,
//.proactive-chat-modal {
//  .ant-modal-header {
//    border-bottom: none;
//    .ant-modal-title {
//      font-size: 18px;
//    }
//  }
//  .ant-modal-body {
//    padding: 0 24px;
//    position: relative;
//    & > span {
//      position: absolute;
//      right: 204px;
//      top: 14px;
//      cursor: pointer;
//      z-index: 10000;
//    }
//    #proactiveMessage {
//      margin-bottom: 0;
//    }
//    .ant-form-item {
//      margin-bottom: 0;
//    }
//    .fr-toolbar .fr-btn-grp {
//      margin-right: 200px; // change this when adding more toolbar btn on <messageModel
//    }
//  }
//  .ant-modal-footer {
//    padding: 15px 20px;
//    border: none;
//    .proactive-model-btn {
//      + .proactive-model-btn {
//        margin-bottom: 0;
//        margin-left: 8px;
//      }
//      button {
//        border: none;
//        background: inherit;
//        font-weight: bold;
//        .proactive-model-btn-primary-text {
//          color: var(--text-white);
//        }
//      }
//    }
//  }
//}

.live-view-chat-modal .ant-modal-body > span {
  right: 164px;
}
.beamer_icon.active,
#beamerIcon.active {
  top: 10px !important;
  left: 12px !important;
}

.beamer_icon.active,
#beamerIcon.active {
  top: 10px !important;
  left: 12px !important;
}
.warning-tooltip {
  width: 250px;
  .ant-tooltip-arrow::before {
    background: var(--bg-white);
  }
  .ant-tooltip-inner {
    background: var(--bg-white);
    .heading {
      i {
        color: $error-color;
        font-size: 20px;
        margin-right: 10px;
      }
      color: black;
      margin: 10px;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .body {
    margin: 10px;
    ol {
      padding-inline-start: 15px;
      li {
        color: black;
        font-size: $font-size-base;
        margin-bottom: 10px;
        span {
          color: $primary-color-secondary;
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
}

.tribute-container {
  max-width: 1024px !important;
  z-index: 1001;
}
.fa-caret-down {
  font-size: 10px !important;
}
.ant-tabs-extra-content {
  .fas.fa-pen {
    cursor: pointer;
    line-height: 45px;
  }
}

.disabled-btn.btn-md button {
  padding: 7px 15px;
  height: auto;
}
#meeting_link_id {
  .option-desc {
    display: none;
  }
}

.meeting-dropdown,
.show-bot-until-dropdown {
  .ant-select-dropdown-menu-item {
    display: block !important;
  }
  .option-title {
    font-weight: 500;
    font-size: 15px;
  }
  .option-desc {
    display: block;
    opacity: 0.5;
    white-space: nowrap;
  }
}

.custom-bot-icon-tooltip {
  max-width: 280px;
}

.profile-dropdown {
  .ant-select-dropdown-menu-item {
    display: flex;
    .profile-avatar {
      margin-right: 10px;
      height: 24px !important;
      width: 24px !important;
    }
    img {
      margin-right: 10px;
      height: 24px !important;
      width: 24px !important;
    }
  }
}
  #include_sample_data{
    .block{
      display: block !important;
      margin-bottom: 4px;
    }
}
.deal-owner-select {
  .ant-select-selection-selected-value {
    display: flex !important;
    align-items: center;

    .profile-avatar {
      height: 26px !important;
      width: 26px !important;
      margin-right: 5px;
      img {
        vertical-align: inherit;
      }
    }
  }
}

.select-dropdown-list {
  .ant-spin.ant-spin-sm.ant-spin-spinning {
    height: 23px;
    margin: 0 auto;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 12px 20px 23px;
  }
}

.custom-email-select.ant-btn {
  padding: 10px 20px;
  width: 100%;
  white-space: normal;
  &:hover {
    color: var(--text-primary);
  }
}
// Avoid the overflow for continuous string
.CodeMirror-wrap pre {
  word-break: break-word;
}

.snippet-form {
  .visibility-form-item {
    .assign-select {
      .team-emoji {
        margin-right: 5px;
      }
    }
  }
  .action-buttons {
    padding-top: 5px;
    margin-bottom: 0;

    button {
      float: right;

      &:last-of-type {
        margin-right: 10px;
      }
    }
  }
}

// OMNI search
.apply-contacts-tag-modal {
  .additional-section {
    margin-top: 20px;

    p {
      font-weight: 500;
    }

    .tag {
      display: inline-block;
      padding: 2px 12px;
      border-radius: 50px;
      border: solid 1px var(--border-gray);
      color: var(--text-light);
      margin-bottom: 10px;
      margin-right: 10px;
      font-size: 13px;
      max-width: 100%;

      &:hover {
        cursor: pointer;
        background: #f1f1f1;
        color: #354052;
      }

      .tag-name {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
        white-space: nowrap;
      }
    }
  }
}

// FORM Builder
.editor-layout {
  .gist-one-bg {
    background: var(--form-editor-sidebar-bg) !important;

    .settings-panel.active-panel {
      background: var(--bg-light) !important;
    }
  }

  #gist-editor {
    .gist-cv-canvas {
      border-left: 1px solid var(--border-gray);
    }
    #selected-component {
      background: var(--form-editor-sidebar-bg) !important;
    }

    .gist-field {
      background: transparent !important;

      input,
      select,
      textarea {
        background: transparent !important;
        border: 1px solid var(--border-gray) !important;
      }
    }

    .gist-sm-sector .gist-sm-field input {
      background: transparent !important;
      border: 1px solid var(--border-gray) !important;
    }
  }
}

//.ant-btn {
//  &:not(.ant-btn-link) {
//    border: solid 1px var(--border-dark);
//  }
//}

.new-btn {
  background: var(--white);
}

.zap-row,
.zap-empty {
  background: var(--white) !important;
  border: 1px solid var(--border-gray) !important;

  .zap-description {
    color: var(--text-primary) !important;
  }
}

.highcharts-background {
  fill: var(--white) !important;
}

.highcharts-yaxis-grid {
  .highcharts-grid-line {
    stroke: var(--border-gray);
  }
}

.mb-0 {
  margin-bottom: 0;
}
.mr-2 {
  margin-right: 8px;
}
.mr-6 {
  margin-right: 24px;
}

.font-primary {
  color: $primary-color;
}

.is-electron {
  .top-navbar,
  .notification-layout {
    -webkit-user-select: none;
    -webkit-app-region: drag;
    padding-left: 85px;
  }

  .content-container {
    margin-left: 60px;
  }

  .workspace-switcher-sidebar {
    width: 60px;
    height: calc(100% - 58px);
    position: absolute;
    left: 0;
    background: var(--top-nav-bg);
    display: flex;
    flex-direction: column;

    .workspace-item {
      display: flex;
      justify-content: center;
      margin: 5px 7px;
      padding: 0;
      border-radius: 50%;
      border: 3px solid transparent;

      &.selected {
        border: 3px solid #ffffff;
        background: #fff;
      }

      &:hover {
        border: 3px solid #a0a0a0;
        background: #a0a0a0;
      }

      img {
        width: 40px;
        height: 40px;
      }
    }
  }
}

.ant-modal-mask,
.ant-modal-wrap {
  z-index: 1001;
}
.not-authorized-modal {
  .ant-modal-close {
    display: block !important;
  }
  .content {
    padding: 20px;
  }
}
.shortcut-keys {
  padding-top: 10px;
  font-size: 14px;
  text-align: center;
}

.product-info-header {
  color: var(--text-secondary);
  background: var(--sidebar-bg-light);
  border: 1px solid var(--border-gray);

  &.bg-white {
    background: var(--bg-white);
  }

  .title {
    font-weight: 600;
  }

  .description {
    margin-bottom: 0;
  }

  i {
    margin-right: 6px;
    color: var(--text-primary);
  }

  a {
    color: var(--text-primary);

    &:hover {
      span {
        text-decoration: underline;
      }
    }
  }

  .info-banner-close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 16px;
    color: var(--label-default-text);
    cursor: pointer;
  }
}

.person-mail-subscription {
  .subscription-items {
    margin-bottom: 8px;
  }
  .subscribe-switch {
    min-width: 33px;
  }
  .subscribe-status {
    background: $error-color;
  }
}

.grecaptcha-badge {
  bottom: 85px !important;
  z-index: 1001;
}

.custom-fields {
  display: flex;

  .ant-btn.ant-dropdown-trigger {
    margin-top: 2px;
    margin-left: -49px;
    box-shadow: none;
  }

  .hidden {
    visibility: hidden;
  }

  textarea {
    resize: none;
  }
}

.custom-field-dropdown {
  .ant-dropdown-menu {
    height: 550px;
    overflow: scroll;
  }
}

.snippets-component {
  .snippet-search-input.ant-input-affix-wrapper {
    margin-bottom: 16px;

    input {
      padding: 6px 0 6px 35px;
      height: 36px;
      &:focus,
      &:hover {
        border: 1px solid var(--primary-color) !important;
        box-shadow: none;
      }
    }
  }

  .snippets-container {
    border: 1px solid var(--border-gray);
    border-radius: 6px;

    .snippets-list-container-scroll {
      position: relative;
      border-right: 1px solid var(--border-gray);
      overflow-y: auto;
      max-height: 1000px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;

      .sort-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 6px 6px 16px;
        border-bottom: 1px solid var(--border-gray);

        position: sticky;
        top: 0;
        background: var(--popover-bg);

        .ant-select-selection {
          border: none;

          .ant-select-selection__rendered {
            margin-right: 30px;
          }
        }
      }

      .snippet-list-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 16px;
        border-bottom: 1px solid var(--border-gray);
        gap: 4px;
        position: sticky;
        top: 0;
        background: var(--popover-bg);
        i {
          margin-right: 5px;
        }

        .title {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .action {
          cursor: pointer;

          &.back-button {
            color: var(--primary-color);
            flex-shrink: 0;
          }

          &.edit-folder {
            color: var(--primary-color);
          }

          &.delete-folder {
            margin-left: 10px;
            color: $workflow-danger-text-color;
          }
        }
      }

      .snippets-list {
        list-style-type: none;
        margin-block-start: 0;
        padding-inline-start: 0;
        width: 100%;
        margin-bottom: 0;

        .snippets-list-item {
          padding: 12px 16px;
          border-bottom: 1px solid var(--border-gray);
          border-left: 2px solid transparent;
          cursor: pointer;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;

          &:hover {
            background: var(--conversation-hover-bg-color);
          }

          &.selected {
            background: var(--conversation-selected-bg-color);
            border-left: 2px solid var(--primary-color);
            color: var(--primary-color);
          }

          &.category-item {
            display: flex;
            align-items: center;
            gap: 5px;

            i {
              color: var(--text-light);
            }
            .title {
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .snippet-count {
              color: var(--text-light);
              font-size: 90%;
              margin-left: 8px;
            }
            .right-icon {
              flex-grow: 1;
              text-align: right;
            }
          }
        }

        .no-snippets {
          text-align: center;
          padding: 10px;
          margin-bottom: 0;
        }
      }
    }

    .snippets-content-container {
      padding: 16px 20px;

      .snippet-content-preview {
        word-break: break-word;

        .title {
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 10px;
        }

        .notes {
          padding: 6px 8px;
          border-radius: 4px;
          margin-bottom: 10px;
          background: var(--conversation-note-message-bg);
          color: var(--conversation-note-message-text);
        }
      }
    }
  }

  &.snippet-inbox {
    .snippets-container {
      .ant-row-flex {
        .snippets-list-container-scroll,
        .snippets-content-container {
          height: 50vh;
          max-height: 600px;
        }

        .snippet-content-preview {
          display: flex;
          flex-direction: column;
          height: 100%;
          .title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .ant-btn {
              padding: 2px 10px;
            }
          }

          .header {
            .title-container {
              display: flex;
              align-items: baseline;
              justify-content: space-between;

              .title {
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 10px;
                padding-right: 5px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                display: block;
              }

              .ant-btn {
                padding: 1.5px 8px;
              }
            }

            .meta-info {
              font-size: 11px;

              .profile-avatar {
                height: 18px;
                width: 18px;
              }
            }
          }

          .content {
            overflow: auto;
            flex-grow: 1;
            img {
              width: 100%;
            }
          }
          .divider {
            margin: 10px -20px;
            width: calc(100% + 40px);
          }
        }
      }
    }
  }
}
.snippet-team-dropdown {
  .team-emoji {
    margin-right: 5px;
  }
}
.insert-snippet-model {
  .ant-modal-title {
    display: flex;
    justify-content: space-between;
    .right {
      margin-right: 20px;
      .operations-picker {
        button {
          i {
            margin-left: 6px;
          }
        }
      }
    }
  }
}

.oauth-authorize-container {
  background: var(--select-box-active);
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding: 30px 20px;
  overflow: auto;
  .oauth-authorize-container-inner {
    .oauth-authorize-header {
      margin-bottom: 10px;
      div {
        font-size: 25px;
        font-weight: 500;
        margin-bottom: 10px;
      }
      span {
        font-size: 20px;
      }
    }
    .oauth-authorize-card {
      background: var(--bg-white);
      li {
        margin-bottom: 3px;
        div {
          font-size: medium;
          margin-bottom: 3px;
        }
      }
      .footer-btn {
        text-align: end;
        .ant-btn {
          margin-left: 10px;
        }
      }
    }
    margin: auto 0;
    display: flex;
    flex-direction: column;
    .app-logo {
      width: 70px;
      margin: 0 auto;
    }
  }
  .spinner-align {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.oauth-success-container {
  svg {
    width: 50px;
    height: 50px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  button {
    width: auto;
  }
}

.rc-menu__item--hover {
  background: var(--select-hover-color) !important;
}

.asset-profile-drawer {
  .ant-drawer-content {
    overflow: visible !important;

    .ant-drawer-wrapper-body {
      overflow: visible !important;
    }

    .ant-drawer-body {
      padding: 0;

      .ant-drawer-close {
        left: -30px;
        top: 15px;
        background: var(--bg-lightest-gray);
        border-radius: 6px;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--border-gray);
        z-index: -1;

        i {
          color: var(--text-light);
        }

        &:hover {
          i {
            color: var(--text-secondary);
          }
        }
      }

      .profile-layout {
        border-radius: 6px 0 0 6px;

        .profile-view {
          height: 100vh;
          border-radius: 6px 0 0 6px;

          .profile-detail {
            .contact-details {
              .list {
                .description {
                  &.contact-owner-desc {
                    .editable-select {
                      overflow: hidden;

                      .ant-select-selection-selected-value {
                        & > div {
                          img {
                            & + span {
                              margin: 0;
                            }
                          }
                        }
                      }
                    }
                  }

                  .editable-cell-value-wrap {
                    .contact-owner-details {
                      img {
                        margin-right: 10px;
                      }

                      .no-image-profile {
                        width: 20px;
                        height: 20px;
                      }
                    }
                  }

                  .editable-cell-value-wrap,
                  &.contact-owner-desc {
                    .editable-select .ant-select-selection-selected-value,
                    .contact-owner-details {
                      img {
                        width: 18px;
                        height: 18px;
                        border-radius: 50%;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.custom-btn {
  @extend %light-btn;
  padding: 4px 8px;
  font-size: 14px;
  border-radius: 8px;
  &:focus {
    color: var(--light-btn-text-hover) !important;
    background-color: var(--light-btn-bg-hover) !important;
  }
  img {
    height: 14px;
    position: relative;
    top: -1px;
  }

  i {
    color: var(--text-primary);
    font-size: 14px;
    padding: 0;
    &.prioritize.fas {
      color: $yellow-800;
    }
  }

  .text {
    margin-left: 6px;
  }

  &:hover {
    i {
      color: $alert-info-text;
    }
  }
  &.btn-link {
    background: none;
    padding: 4px;
  }
}

.survey-ongoing-table-header {
  .left {
    display: flex !important;
    align-items: center;
    .ant-select-selection {
      max-height: 40px;
    }
  }
}

.minimal-popover {
  .ant-popover-inner {
    min-width: 0 !important;

    .ant-popover-inner-content {
      padding: 5px 15px !important;
    }
  }
  .ant-list {
    color: inherit;
    .ant-list-item {
      border-bottom: none;
    }
  }
}

.flex-align-items-center {
  display: flex;
  align-items: center;
}

.contact-owner-multiple-select {
  .ant-select-selection--multiple {
    .ant-select-selection__choice__content {
      display: flex;

      .no-image-profile {
        background-color: var(--white);
      }
    }
  }
}

.ant-select-dropdown {
  .ant-select-dropdown-menu {
    .ant-select-dropdown-menu-item {
      .anticon {
        &.anticon-check {
          right: 0;

          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}

// Used in Workflow and settings modules
.liquid-dropdown {
  max-height: calc(100vh - 208px);
  overflow: auto;
}

.link {
  color: var(--primary-color);
  cursor: pointer;

  i {
    color: var(--primary-color);
  }

  &:hover {
    opacity: 0.9;
  }
}

.underlined-link {
  color: var(--text-light);

  &:hover {
    color: var(--text-light);
    cursor: pointer;
    text-decoration: underline;
  }
}

.text-muted {
  color: var(--text-light);
}

.no-image-profile {
  @extend %profile;
  background-color: var(--profile-picture-bg);
}

.emojis {
  .ant-popover-inner {
    .ant-popover-inner-content {
      padding: 0 !important;

      .emoji-mart {
        border: 0;
      }
    }
  }
}

// Date time group with timezone
.date-and-time {
  display: flex;
  gap: 5px;

  .ant-row {
    margin-right: 5px;
  }

  .time-block {
    display: flex;

    .ant-row:first-of-type {
      margin-right: 0;

      .ant-select-selection {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .ant-row:last-of-type {
      .ant-select-selection {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

.ant-radio-group {
  &.ant-radio-group-vertical {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.radio-card-button {
  .ant-radio-inner {
    display: none;
  }

  .ant-radio-wrapper {
    margin-right: 0;

    .ant-radio {
      display: none;

      & + * {
        padding: 0;
      }
    }

    .ant-card {
      min-height: 165px;
      padding-top: 25px;
      border-radius: 6px;
      text-align: center;
      height: 100%;

      &:hover {
        border-color: var(--primary-color);
      }

      .ant-card-cover {
        img {
          width: 48px;
          margin: 30px auto 0;
        }

        &::after {
          content: '';
          font-family: 'Font Awesome 6 Pro';
          color: var(--primary-color);
          position: absolute;
          left: 10px;
          font-size: 16px;
          top: 10px;
          width: 16px;
          height: 16px;
          border: 2px solid gray;
          border-radius: 50%;
        }

        i {
          font-size: 28px;
        }
      }

      .ant-card-body {
        padding: 20px 15px 30px;

        .ant-card-meta-detail {
          text-align: center;

          .ant-card-meta-title {
            font-weight: 600;
            font-size: 17px;
            color: var(--text-primary);
          }

          .ant-card-meta-description {
            white-space: normal;
            color: var(--text-primary);
          }
        }
      }
    }

    &.ant-radio-wrapper-disabled {
      img {
        filter: grayscale(1);
      }
    }

    &.ant-radio-wrapper-checked {
      .ant-card {
        border-color: var(--primary-color);

        .ant-card-cover::after {
          content: '\f058';
          border: none;
          top: 6px;
        }
      }
    }
  }
}

.code-snippet {
  position: relative;
  border: solid 1px var(--editor-field-border-color);

  button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 50px;
    font-size: 11px;
    padding: 5px !important;
    z-index: 1;
  }

  button {
    display: none;
  }

  &:hover {
    button {
      display: block;
    }
  }

  .ace_editor {
    width: 100%;
    height: 100px;
    border-radius: 6px !important;
    background-color: var(--white) !important;
    font-size: 14px !important;

    .ace_gutter {
      top: 6px;
      width: 40px !important;
      background-color: var(--white) !important;

      .ace_gutter-cell {
        padding-right: 15px !important;
      }

      .ace_gutter-active-line {
        background-color: var(--white) !important;
      }
    }

    .ace_scroller {
      .ace_cursor {
        display: none !important;
      }

      .ace_active-line {
        background-color: transparent !important;
      }

      .ace_text-layer {
        .ace_line {
          max-width: 400px;

          .ace_comment {
            background-color: transparent;
          }
        }
      }
    }

    .ace_content {
      background-color: var(--ace-text-layer);
    }
  }

  .ace_content {
    padding: 5px 5px 0;
  }

  .ace_line {
    font-size: $font-size-base;
    max-width: 100% !important;
    word-break: break-all;

    .ace_text {
      max-width: 100% !important;
      word-break: break-all;
    }
  }
}

// Search box
.search {
  input {
    border-radius: 50px;
    border: 1px solid var(--border-gray);
    background: var(--search-box-bg);
    padding: 4px 15px;
    height: 32px;

    &::placeholder {
      color: var(--text-light);
    }

    &:focus {
      box-shadow: none;
    }
  }

  .ant-input-suffix {
    i {
      font-size: 16px;

      &.anticon-close {
        font-size: 14px;
      }
    }
  }
}

.top-navbar i.fal {
  margin-right: 5px;
}

.top-navbar-dropdown {
  .ant-menu-item {
    display: flex;
    align-items: center;

    &:hover {
      i {
        color: var(--primary-color);
      }
    }

    &.ant-menu-item-selected {
      .active {
        i {
          color: var(--primary-color);
        }
      }
    }
  }
}

.switch-component {
  &.horizontal {
    display: flex;
    align-items: center;

    .ant-form-item-label {
      margin-right: 15px;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}

.ant-radio-group.radio-button-group.vertical {
  display: flex;
  flex-direction: column;

  .ant-radio-button-wrapper {
    height: 40px;
    line-height: 40px;
    border: 1px solid var(--border-light);
    margin-bottom: -1px;
    box-shadow: none;

    &:nth-child(2):hover + .ant-radio-button-wrapper,
    &:nth-child(1):hover + .ant-radio-button-wrapper {
      border-top-color: $primary-color-secondary;
    }

    &:not(:first-child)::before {
      width: 0px;
    }

    &:not(.ant-radio-button-wrapper-disabled) {
      &.ant-radio-button-wrapper-checked {
        position: relative;
        color: inherit;
        background-color: rgba(55, 159, 244, 0.08);
        border: 1px solid $primary-color-secondary;
        box-shadow: none;

        &::before {
          width: 0;
        }
      }

      &:hover {
        position: relative;
        color: inherit;
        border: 1px solid $primary-color-secondary;
        box-shadow: none;

        i {
          color: $primary-color;
        }
      }
    }

    &:last-child {
      border-radius: 0 0 3px 3px;
    }
    &:first-child {
      border-radius: 3px 3px 0 0;
    }
  }

  img,
  i {
    max-height: 20px;
    margin-bottom: 5px;
    margin-right: 10px;
    max-width: 20px;

    &.small {
      max-height: 15px;
      margin-left: 3px;
      max-width: 15px;
    }
  }

  .help-icon {
    position: absolute;
    top: 11px;
    font-size: 16px;
    right: 10px;
  }
}

.text-primary {
  color: var(--primary-color);
}

.text-default {
  color: var(--text-primary);
}

.text-secondary {
  color: var(--text-secondary);
}

.ant-layout-sider {
  .search {
    input {
      background: var(--bg-white);
    }
  }
}

// TODO: Adding animation for search operation
.search {
  height: 32px;

  .search-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background: transparent;
    cursor: pointer;

    i {
      color: $icon-color-light;
      font-size: 16px;
    }

    &:hover {
      background-color: var(--light-btn-bg-hover);

      i {
        color: var(--light-btn-text-hover);
      }
    }
  }

  &.active {
    min-width: 260px;

    .search-icon {
      display: none;
    }

    .ant-input-affix-wrapper {
      display: inline-block;
    }
  }

  &:not(.active) {
    .ant-input-affix-wrapper {
      display: none;
    }
  }

  .ant-input-affix-wrapper {
    .ant-input-suffix {
      cursor: pointer;
    }
  }
}

.switch-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  i {
    margin-left: 5px;
  }
}

.preview-url-modal {
  .preview-url-modal-content {
    .fade-show {
      padding: 16px;
      margin-bottom: 15px;
      border-radius: 6px;
      background-color: #e3eafa;
      border-color: #c3d7ff;
      color: #001438;
    }
    .ant-input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .recent-used-pages {
      margin-top: 20px;
      .recent-urls-container {
        max-height: 300px;
        overflow: auto;
        .recent-urls {
          cursor: pointer;
          font-size: 13px;
          padding: 12px 0;
          border-bottom: 1px solid var(--border-gray);

          &.active {
            color: var(--primary-color);
          }

          &:hover {
            color: var(--primary-color);
          }
        }
      }
    }
  }
}

.text-success {
  color: $success-color;
}

.text-danger {
  color: $error-color;
}

.collapse-bordered {
  &.ant-collapse {
    background: var(--bg-white);

    & > .ant-collapse-item {
      background: var(--bg-white);

      &.ant-collapse-item-active {
        .ant-collapse-content-active {
          border-top: none;

          .ant-collapse-content-box {
            padding-top: 0;
            padding-bottom: 0;

            .continue {
              margin-bottom: 20px;
              border-radius: 0 0 5px 5px;
              border-bottom: 1px solid var(--border-light);
            }
          }
        }
      }

      .ant-collapse-content-box {
        padding: 0 20px 20px 20px;
      }

      & > .ant-collapse-header {
        padding: 20px 20px;
        font-size: 17px;
        font-weight: 500;

        .ant-collapse-extra {
          color: var(--gray-700);
          font-size: $font-size-base;
          line-height: 20px;
          float: none;
          margin-top: 5px;
          font-weight: 400;
        }

        i {
          left: auto !important;
          right: 20px;
        }
      }
    }
  }
}

.switch-box {
  padding: 20px;
  border: 1px solid var(--input-box-border);
  border-radius: 5px;
  width: 100%;

  p {
    margin-bottom: 0;
  }
}

.ant-spin-show-text {
  flex-direction: column-reverse;
  gap: 30px;
}

.ant-spin-text {
  color: var(--text-light);
}

.ant-message {
  z-index: 2147483631;
}
.upload-csv {
  margin: 30px 0;

  .ant-upload-drag {
    background: var(--bg-white);
    border-radius: 3px;
    border: dashed 1px var(--icon-color-dark);
    padding: 14px;

    .ant-upload-drag-icon {
      margin-bottom: 10px;
    }

    .ant-upload-text {
      font-size: $font-size-base;
      font-weight: 600;
      color: var(--text-primary);
    }

    p {
      font-size: $font-size-base;
      color: var(--text-primary);
    }
  }

  .uploaded-file {
    border-radius: 5px;
    border: var(--file-uploader-border);
    background-color: var(--file-uploader-bg);
    position: relative;
    padding: 38px 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    img {
      margin-right: 10px;
    }

    p {
      margin-bottom: 0;
    }

    .upload-details {
      p:first-child {
        font-weight: bold;
      }
    }

    .fas fa-circle-xmark {
      font-size: 18px !important;
      color: var(--text-light) !important;
      position: absolute;
      right: 5px;
      top: 5px;
      cursor: pointer;
    }
  }
}

.ant-message {
  z-index: 2147483631;
}
// Tags section
// Tags list section
.tag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  .tag {
    @extend %tag-list;

    .tag-name {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
      white-space: nowrap;
    }

    &:not(.clickable) {
      &:hover {
        .tag-name {
          flex: 0 1 calc(100% - 10px);
        }
      }
    }

    &.add-btn {
      cursor: pointer;

      .tag-name {
        margin-left: 5px;
      }
    }

    &.clickable {
      cursor: pointer;

      &:hover {
        border-color: var(--light-btn-text-hover);
        color: var(--light-btn-text-hover);

        i {
          color: var(--light-btn-text-hover);
        }
      }
    }

    i {
      font-size: 12px;
      cursor: pointer;

      &.cancel {
        margin-left: 5px;
      }
    }

    .fas.fa-xmark.cancel {
      display: none;
    }

    &:hover {
      .fas.fa-xmark.cancel {
        position: absolute;
        right: 2px;
        height: 15px;
        width: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
      }
    }
  }
}

.radio-button-container {
  margin-top: 20px;

  .ant-btn {
    padding: 10px 25px;
  }

  .ant-radio-group {
    display: flex;
    gap: 15px;
    width: max-content;
    margin: 0 auto;

    & + div {
      margin-top: 20px;
      margin-bottom: 0;
    }

    .ant-radio-inner {
      display: none;
    }

    .ant-radio-wrapper {
      .ant-radio {
        display: none;

        & + * {
          padding: 0;
        }
      }

      .radio-button-item {
        display: flex;
        flex-direction: column;
        border-radius: 6px;
        width: 300px;
        min-height: 200px;
        padding: 30px 20px 20px;
        text-align: center;

        &:hover {
          border-color: var(--primary-color);
        }

        .ant-card-cover {
          margin-bottom: 20px;
        }

        .ant-card-body {
          padding: 0;

          .ant-card-meta-title {
            font-weight: 600;
            font-size: 17px;
            color: var(--text-primary);
          }
        }
      }

      .ant-card-cover {
        img {
          width: 60px;
          margin: 30px auto 0;
        }

        &::after {
          content: '';
          font-family: Flaticon;
          color: var(--primary-color);
          position: absolute;
          left: 10px;
          font-size: 16px;
          top: 10px;
          width: 16px;
          height: 16px;
          border: 2px solid gray;
          border-radius: 50%;
        }

        i {
          font-size: 30px;
        }
      }

      .ant-card-meta-detail {
        text-align: center;

        .ant-card-meta-description {
          white-space: normal;
          color: var(--text-primary);
        }
      }

      &.ant-radio-wrapper-disabled {
        img {
          filter: grayscale(1);
        }
      }
    }

    .ant-radio-wrapper-checked {
      .radio-button-item {
        border-color: var(--primary-color);
        background: rgba(55, 161, 246, 0.08);
        color: var(--primary-color);

        .ant-card-meta-title {
          color: var(--primary-color) !important;
        }
      }

      .ant-card-cover::after {
        content: '\f106';
        border: none;
        top: 6px;
      }
    }
  }
}

button.link {
  cursor: pointer;
  background: none;
  border: none;
  color: var(--primary-color);
}

.bg-light {
  background: var(--bg-light);
}

.bg-lighter {
  background: var(--bg-lighter);
}

.bg-gray {
  background: var(--bg-gray) !important;
}

.new-tag {
  @extend %tag-list;
  cursor: pointer;
  height: 25px;
  display: none;

  &:hover {
    cursor: pointer;
    border: 1px solid var(--primary-color) !important;
    color: var(--primary-color) !important;
  }
}

.page-restriction {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tabs-full-width {
  .ant-tabs-nav {
    width: 100% !important;
  }

  .ant-tabs-tab {
    flex: 1;
    text-align: center;
  }

  .ant-tabs-nav > div:nth-of-type(1) {
    display: flex !important;
    width: 100% !important;
  }
}

.bg-white {
  background: var(--bg-white);
}

.bg-gray {
  background: var(--bg-gray);
}

.bg-gray-300 {
  background: var(--bg-light);
}

.bg-gray-200 {
  background: var(--bg-lighter);
}

.bg-gray-100 {
  background: var(--bg-lightest-gray);
}

.border-gray {
  border: var(--border-gray);
}

.border-light {
  border: 1px solid var(--border-light);
}

.hover-shadow {
  &:hover {
    box-shadow: var(--shadow-light);
  }
}

.hover-primary {
  &:hover {
    color: var(--primary-color);
  }
}

.outline-none {
  outline: none !important;
}

.shadow-none {
  box-shadow: none !important;
}

.text-highlight {
  background: #faf5cd;
}

// Color Picker CSS
.color-list {
  margin-top: 15px;
  display: flex;
  align-items: center;

  .color-picker {
    width: 35px;
    height: 35px;
    margin-right: 10px;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    display: inline-block;

    &.custom-color-picker {
      background: var(--bg-white);
      border: 1px solid var(--border-gray);
      position: relative;

      svg {
        width: 100%;
        height: 60%;
        fill: var(--white) !important;
        position: relative;
        top: 6px;
        transform: rotate(45deg);
      }
    }

    &.checked:after {
      position: absolute;
      content: '\2713';
      top: calc(50% - 16px);
      left: calc(50% - 10px);
      color: var(--white);
      font-size: 20px;
    }
  }

  .ant-input {
    display: inline-block;
    width: 120px;
  }

  .color-or {
    margin-right: 8px;
    font-weight: 600;
  }
}

.bg-white {
  background: var(--bg-white);
}

.bg-gray {
  background: var(--bg-gray);
}

.bg-gray-300 {
  background: var(--bg-light);
}

.bg-gray-200 {
  background: var(--bg-lighter);
}

.bg-gray-100 {
  background: var(--bg-lightest-gray);
}

.border-gray {
  border: var(--border-gray);
}

.border-light {
  border: 1px solid var(--border-light);
}

.appearance-color-picker {
  &.sketch-picker-container {
    position: relative;

    .sketch-color-picker {
      position: absolute;
      right: 0;
      z-index: 9;
    }
  }

  .sketch-picker-position {
    bottom: 10px;
  }

  .ant-col {
    position: static;
  }

  .ant-form-item-children {
    position: static;
  }
}

.edit-contact-modal,
.apply-contacts-tag-modal,
.apply-tag-modal {
  .ant-modal-body .ant-select-selection {
    padding: 5px;
    height: auto !important;
    border: 1px solid var(--border-dark);
  }
}

.border-gray {
  border-color: var(--border-gray);
}

.border-light {
  border-color: var(--border-light);
}

.border-dark {
  border-color: var(--border-dark);
}

.border-primary {
  border-color: var(--primary-color);
}

.border-yellow-light {
  border-color: $yellow-300;
}

.bg-yellow {
  background-color: $yellow-600;
}

.border-green-light {
  border-color: $green-100;
}

.bg-green {
  background-color: $green-300;
}

.select-width-auto {
  width: auto !important;
  .ant-select-selection__rendered {
    width: auto !important;
  }
}

// DARK THEME
.ant-slider-rail {
  background-color: var(--border-light);
}

.ant-radio-inner {
  background: transparent;
}

.ant-select-arrow {
  color: var(--text-light);
}
