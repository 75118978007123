@import "variable";

%drawer-wrapper {
  .ant-drawer-content-wrapper[style] {
    width: 600px;
    background: var(--bg-white);
    //margin-top: 58px;
    box-shadow: $default-box-shadow;
  }
}

%heading {
  .heading {
    margin-bottom: 20px;
    h2 {
      font-size: 18px;
      color: var(--text-secondary);
      margin-bottom: 5px;
    }
    p {
      font-size: 13px;
      color: var(--text-light);
      margin-bottom: 0;
    }
  }
}

.meeting-drawer {
  @extend %drawer-wrapper;
  .ant-drawer-body {
    padding: 30px;
    .ant-drawer-close:focus, .ant-drawer-close:hover {
      color: var(--text-light);
      .fa-circle-xmark {
        font-size: 20px !important;
      }
    }
    @extend %heading;
    .heading {
      margin-bottom: 15px !important;
    }
    .content {
      .ant-row {
        margin-bottom: 20px;
        .ant-form-item-label {
          line-height: normal;
          margin-bottom: 8px;
          color: var(--text-secondary);
          font-weight: 500;
          .ant-form-item-required {
            padding-right: 10px;
            color: var(--text-secondary);
            &:before {
              content: ''; // hide the star mark
              right: 0;
              margin-right: 0;
              position: absolute;
            }
            &:after {
              display: none;
            }
          }
        }
        .ant-input {
          &:focus {
            border: 1px solid var(--primary-color);
            box-shadow: none;
          }
          color: var(--text-primary);
        }
        .ant-form-item-control {
          line-height: normal;
          .ant-form-text {
            color: var(--text-light);
            margin-bottom: 8px;
            font-weight: 500;
          }
        }
      }
      .cancel {
        margin-left: 20px;
      }
    }
  }
}

.drawer {
  .ant-drawer-body {
    .other-actions {
      color: var(--primary-color);
      background: var(--bg-lightest-blue);
      border-radius: 5px;
      padding: 10px 15px;
      margin-bottom: 0;
      cursor: pointer;
      #change {
        float: none !important;
      }
    }
    .text-trigger {
      margin-bottom: 8px;
      color: var(--text-light);
      cursor: pointer;
      text-decoration: underline;
    }
    .back-icon {
      margin-bottom: 10px;
      &:hover {
        cursor: pointer;
        font-weight: 600;
      }
      i {
        margin-right: 5px;
        font-size: $font-size-base !important;
      }
    }
  }
}

.tour-panel-drawer {
  .ant-drawer-body {
    padding: 0 24px;
  }
}