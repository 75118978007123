.maintenance-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    padding: 0;
    background-color: aliceblue;
    box-sizing: border-box;
    text-align: center;
}

.maintenance-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0 40px;
}

.text-section {
    font-size: 20px;
    padding: 20px;
    text-align: left;
}

.image-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.image-section img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}
