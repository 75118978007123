.flex {
  display: flex !important;
}

.flex-0 {
  flex: 0 !important;
}

.flex-1 {
  flex: 1 !important;
}

.flex-2 {
  flex: 2 !important;
}

.flex-3 {
  flex: 3 !important;
}

.flex-4 {
  flex: 4 !important;
}

.flex-5 {
  flex: 5 !important;
}

.flex-6 {
  flex: 6 !important;
}

.flex-7 {
  flex: 7 !important;
}

.flex-8 {
  flex: 8 !important;
}

.flex-9 {
  flex: 9 !important;
}

.flex-10 {
  flex: 10 !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-grow {
  flex-grow: 1 !important;
}

.flex-col {
  flex-direction: column !important;
}

.flex-col-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.place-content-center {
  place-content: center !important;
}

.place-content-between {
  place-content: space-between !important;
}

.place-items-center {
  place-items: center !important;
}

.content-center {
  align-content: center !important;
}

.content-start {
  align-content: flex-start !important;
}

.content-between {
  align-content: space-between !important;
}

.items-start {
  align-items: flex-start !important;
}

.items-end {
  align-items: flex-end !important;
}

.items-center {
  align-items: center !important;
}

.items-baseline {
  align-items: baseline !important;
}

.items-stretch {
  align-items: stretch !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-around {
  justify-content: space-around !important;
}

.justify-evenly {
  justify-content: space-evenly !important;
}

.justify-items-center {
  justify-items: center !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 4px !important;
}

.gap-2 {
  gap: 8px !important;
}

.gap-3 {
  gap: 12px !important;
}

.gap-4 {
  gap: 16px !important;
}

.gap-5 {
  gap: 20px !important;
}

.gap-6 {
  gap: 24px !important;
}

.gap-8 {
  gap: 32px !important;
}

.gap-9 {
  gap: 36px !important;
}

.gap-10 {
  gap: 40px !important;
}

.gap-12 {
  gap: 48px !important;
}

.gap-14 {
  gap: 56px !important;
}

.gap-16 {
  gap: 64px !important;
}

.gap-20 {
  gap: 80px !important;
}

.gap-1\.5 {
  gap: 6px !important;
}

.gap-px {
  gap: 1px !important;
}

.gap-x-1 {
  column-gap: 4px !important;
}

.gap-x-2 {
  column-gap: 8px !important;
}

.gap-x-3 {
  column-gap: 12px !important;
}

.gap-x-4 {
  column-gap: 16px !important;
}

.gap-x-5 {
  column-gap: 20px !important;
}

.gap-x-8 {
  column-gap: 32px !important;
}

.gap-x-10 {
  column-gap: 40px !important;
}

.gap-x-12 {
  column-gap: 48px !important;
}

.gap-y-1 {
  row-gap: 4px !important;
}

.gap-y-2 {
  row-gap: 8px !important;
}

.gap-y-3 {
  row-gap: 12px !important;
}

.gap-y-4 {
  row-gap: 16px !important;
}

.gap-y-5 {
  row-gap: 20px !important;
}

.gap-y-6 {
  row-gap: 24px !important;
}

.gap-y-8 {
  row-gap: 32px !important;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-8 {
  grid-column: span 8 / span 8;
}

.col-span-10 {
  grid-column: span 10 / span 10;
}

.col-start-1 {
  grid-column-start: 1;
}

.col-start-2 {
  grid-column-start: 2;
}

.col-start-3 {
  grid-column-start: 3;
}

.col-start-4 {
  grid-column-start: 4;
}

.col-start-6 {
  grid-column-start: 6;
}

.row-start-3 {
  grid-row-start: 3;
}

.row-start-4 {
  grid-row-start: 4;
}
