.popover-sm {
  .ant-popover-inner-content {
    padding: 9px 15px !important;
    font-size: 12px;
  }
}
.color-popover{
  .ant-popover-inner {
    min-width: 220px !important;
    .ant-popover-inner-content{
      padding: 0 !important;
    }
  }
}

.popover-text-light-color {
  color: var(--text-light);
}

.popover-info-content {
  max-width: 350px;
}