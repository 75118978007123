.omnisearch-component {
  position: fixed;
  will-change: transform;
  inset: 0;
  display: flex;
  z-index: 2147483631;
  align-items: center;
  justify-content: center;

  .omnisearch-modal {
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    will-change: transform;
    background: var(--bg-white);
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.5) 0 16px 70px 0;
    max-width: 700px;
    font-size: 14px;
    color: var(--text-primary);
    opacity: 2;
    transform: translate3d(0px, 0px, 0px);
    position: relative;

    .jungle-select-filter input {
      padding: 15px 20px;
      -webkit-box-flex: 1;
      flex-grow: 1;
      flex-shrink: 0;
      margin: 0;
      border: none;
      appearance: none;
      font-size: 18px;
      background: transparent;
      color: var(--text-primary);
      caret-color: $primary-color;
      outline: none;
      min-height: 60px;
      width: 100%;

      &::placeholder {
        color: var(--text-secondary);
      }

      &:focus,
      &:active,
      &:hover {
        outline: none;
        box-shadow: none;
      }
    }

    .jungle-select.jungle-select-filtered
      .jungle-select-filter
      .jungle-select-selected-values {
      display: none;
    }

    .jungle-select .jungle-select-filter .jungle-select-selected-value {
      display: none;
    }

    .snippets-component {
      &.jungle-select {
        width: 50%;
      }
    }

    .jungle-select-placeholder {
      position: absolute;
      top: 20px;
      left: 20px;
      font-size: 17px;
      color: var(--text-light);
    }

    .jungle-select .jungle-select-clear {
      display: none;
    }

    .command-list-container {
      height: 100%;
      overflow: hidden auto;

      .command-list-inner {
        position: relative;
        height: 100%;
        width: 100%;
        will-change: transform;
        direction: ltr;

        .articles-list {
          .jungle-select-filter {
            input {
              max-width: 70% !important;
            }
          }
            .supported-languages {
              position: absolute;
              top: 10px;
              right: 10px;
              width: 25%;
            }
        }

        .jungle-select-controls {
          border-radius: 8px;
          border-bottom: 1px solid var(--border-light);
          width: 700px;
        }

        .jungle-select-list {
          max-height: calc(100vh - 100px);
          max-height: 600px;
          overflow-y: scroll;
        }

        .snippet-container {
          display: flex;
          flex-direction: row;
          min-height: 661px;

          .snippet-list {
            .category-item {
              display: flex;
              align-items: center;
              gap: 5px;

              i {
                color: var(--text-light);
                margin-right: 5px;
              }
              .title {
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .snippet-count {
                color: var(--text-light);
                font-size: 90%;
                margin-left: 8px;
              }
              .right-icon {
                flex-grow: 1;
                text-align: right;
              }
            }

            .command-title {
              margin-left: 7px;
            }

            .no-data {
              text-align: center;
              margin-top: 5px;
            }
            .jungle-select-group:nth-child(2) .command-separator-container{
              display: none;
            }
          }

          .snippet-content-preview {
            margin-top: 60px;
            height: 596px;
            width: 50%;
            border-left: 1px solid var(--border-light);

            .header {
              padding: 5px 10px 0px 20px;

              .title-container {
                display: flex;
                align-items: baseline;
                justify-content: space-between;

                .title {
                  font-size: 16px;
                  font-weight: 500;
                  margin-bottom: 10px;
                  padding-right: 5px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                }

                .ant-btn {
                  padding: 1.5px 8px;
                }
              }

              .meta-info {
                font-size: 11px;

                .profile-avatar {
                  height: 18px;
                  width: 18px;
                }
              }
            }

            .content {
              overflow: auto;
              flex-grow: 1;
              line-height: 1.66;
              height: 480px;
              padding: 5px 20px;
              word-break: break-word;

              br {
                margin-bottom: 15px;
              }

              .notes {
                padding: 6px 8px;
                border-radius: 4px;
                margin-bottom: 10px;
                background: var(--conversation-note-message-bg);
                color: var(--conversation-note-message-text);
              }

              img {
                width: 100%;
              }
            }

            .divider {
              //margin: 10px;
              width: 100%;
            }

            .empty-snippet-container{
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              color: var(--alert-default-text);
            }
          }
        }

        .command-list {
          width: 100%;

          .jungle-select-group {
            &:first-of-type {
              .list-heading-container {
                margin-top: 10px;
              }
              .command-separator-container {
                display: none;
              }
            }
          }

          .jungle-select-item {
            &:last-of-type {
              margin-bottom: 10px;
            }
            &.hover {
              .command-item {
                background-color: var(--bg-lighter);
                color: var(--text-primary);
                transition: color 0s ease 0s;
                border-left: 2px solid $primary-color;
                &.disabled {
                  cursor: not-allowed !important;
                  opacity: 0.4;
                  pointer-events: unset !important;
                }
              }
            }

            .command-item {
              min-height: 46px;
              background-color: transparent;
              color: var(--text-primary);
              white-space: nowrap;
              display: flex;
              flex: 0 0 100%;
              -webkit-box-flex: 0;
              -webkit-box-align: center;
              align-items: center;
              transition: color 0.15s ease 0s;
              cursor: pointer;
              border-left: 2px solid transparent;

              &:hover,
              &.active {
                background-color: var(--bg-lighter);
                color: var(--text-primary);
                transition: color 0s ease 0s;
                border-left: 2px solid $primary-color;
              }

              .command-item-inner {
                display: flex;
                flex-shrink: initial;
                flex-basis: initial;
                flex-direction: row;
                -webkit-box-flex: 1;
                flex-grow: 1;
                overflow: hidden;
                height: 100%;
                -webkit-box-align: center;
                align-items: center;
                padding: 0px 20px;

                .icon-container {
                  display: flex;
                  flex: initial;
                  flex-direction: row;
                  -webkit-box-align: center;
                  align-items: center;
                  -webkit-box-pack: center;
                  justify-content: center;
                  margin-right: 16px;
                  width: 16px;

                  i {
                    color: var(--text-secondary);
                  }
                }

                .command-content {
                  flex-grow: 1;
                  display: inline-flex;
                  align-items: center;
                  padding-top: 2px;

                  i {
                    margin-right: 10px;
                  }

                  .command-title {
                    flex-shrink: 0.01;
                    margin-right: 8px;
                    -webkit-box-flex: 1;
                    flex-grow: 1;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }

                  .avatar,
                  .team-emoji {
                    height: 20px;
                    width: 20px;
                    border-radius: 50%;
                    margin-right: 10px;
                  }

                  .team-emoji {
                    font-size: 20px !important;
                    line-height: 20px !important;
                    overflow: visible;
                  }

                  &.article-meta {
                    display: flex;
                    flex-direction: column;
                    align-items: initial;
                    padding: 15px 0px;

                    .article-title {
                      font-weight: 500;
                      margin-bottom: 5px;
                    }

                    .article-body {
                      color: var(--text-secondary);
                      max-height: 42px;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                      overflow: hidden;
                      white-space: pre-wrap;
                      text-overflow: ellipsis;
                    }
                  }
                }
              }
            }
          }

          .list-heading-container {
            position: relative;
            margin: 1px 0px;
            height: 24px;
            width: 100%;

            .list-heading {
              text-align: left;
              font-size: 12px;
              font-weight: 500;
              color: var(--text-secondary);
              padding: 0 20px;
              width: 100%;
              line-height: 22px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }

          .command-separator-container {
            height: 11px;
            width: 100%;
            padding: 5px 0px;

            .command-separator {
              background: var(--border-light);
              height: 1px;
            }
          }
        }
        .ai-icon {
          color: var(--gray-700);
          padding: 0px 2px;
          font-size: 10px;
          font-weight: 600;
          border: 1px solid var(--gray-700);
          border-radius: 4px;
        }
      }
    }
  }
}

.omnisearch-component,
.keyboard-shortcuts-component {
  .custom-text {
    color: #727276;
  }
}

.shortcut-indicator {
  margin-left: 8px;
  flex-shrink: 0;
  width: min-content;

  & > .shortcut-key {
    margin-right: 3px;
  }

  .no-shortcut-key {
    color: rgb(107, 111, 118);
    font-size: 11px;
    margin: 0px 4px 0 1px;
  }

  & > .shortcut-key {
    display: inline-block;
    vertical-align: baseline;
    text-align: center;
    text-transform: capitalize;
    //color: rgb(238, 239, 241);
    color: rgb(60, 65, 73);
    font-size: 11px;
    line-height: 110%;
    //background: rgb(45, 45, 48);
    background: rgb(239, 241, 244);
    padding: 4px 3px 4px 4px;
    border-radius: 3px;
    min-width: 20px;

    &:last-child {
      margin-right: 0px;
    }
  }
}

.keyboard-shortcuts-overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 400;
}

@keyframes slideFromRight {
  from {
    right: -100%;
  }
  to {
    right: 12px;
  }
}

.keyboard-shortcuts-component {
  position: fixed;
  top: 12px;
  bottom: 12px;
  right: 12px;
  display: flex;
  overflow: auto;
  flex-direction: column;
  z-index: 500;
  border-radius: 6px;
  padding: 15px 20px;
  width: 330px;
  max-width: calc(100vw - 24px);
  background-color: var(--bg-white);
  border: 1px solid var(--border-light);
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px 0px;
  animation: slideFromRight 0.2s;

  .keyboard-shortcuts-container {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-self: center;

    .header {
      display: flex;
      flex: initial;
      flex-direction: row;
      -webkit-box-pack: justify;
      justify-content: space-between;

      .heading {
        font-style: normal;
        line-height: normal;
        text-align: left;
        color: var(--text-primary);
        font-weight: 500;
        font-size: 18px;
      }

      .delete-icon {
        color: var(--text-light);
        font-size: 16px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .search-container {
      margin-top: 15px;
      margin-bottom: 10px;
    }

    .keyboard-shortcuts-list {
      display: flex;
      flex-grow: initial;
      flex-basis: initial;
      flex-direction: column;
      flex-shrink: 0;

      .keyboard-shortcuts-list-block {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        margin-top: 20px;

        &:first-of-type {
          margin-top: 10px;
        }

        .category-heading {
          font-style: normal;
          line-height: normal;
          text-align: left;
          color: var(--text-primary);
          font-weight: 600;
          font-size: 13px;
          margin-bottom: 16px;
        }

        .keyboard-shortcuts-list-item {
          display: flex;
          flex-shrink: initial;
          flex-basis: initial;
          flex-direction: row;
          flex-grow: 1;
          overflow: hidden;
          height: 100%;
          align-items: center;
          padding: 0px;
          border-left: 2px solid transparent;
          margin-bottom: 10px;

          .keyboard-shortcuts-list-item-inner {
            flex-grow: 1;
            display: inline-flex;
            align-items: center;

            .command-title {
              flex-shrink: 0.01;
              margin-right: 8px;
              flex-grow: 1;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .shortcut-indicator {
              display: flex;
              justify-content: space-around;
            }
          }
        }
      }
    }
  }
}

.supported-language-dropdown {
  z-index: 23223212368790;
}