.task-widget {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 350px;
  z-index: 1025;

  &::before {
    content: '';
    position: absolute;
    top: -40px;
    left: 0;
    right: 0;
    height: 40px;
    background: transparent;
  }

  &__container {
    background-color: var(--bg-white);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }

  &:hover &__close-button {
    opacity: 1;
    pointer-events: auto;
  }

  &__close-button {
    position: absolute;
    top: -35px;
    right: 0;
    background-color: var(--bg-darker);
    border: none;
    color: var(--text-white);
    font-size: 14px;
    padding: 4px 12px;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s, opacity 0.3s;
    opacity: 0;
    pointer-events: none;

    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }

  &__header {
    width: 100%;
    padding: 15px;
    background-color: var(--primary-color);
    color: var(--text-white);
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s;

    &:hover {
      background-color: var(--primary-color-hover);
    }
  }

  &__content {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;

    &--expanded {
      max-height: 600px;
      opacity: 1;
    }
  }

  &__content-container {
    overflow-y: auto;
    max-height: 600px;
  }

  &__title {
    padding: 15px 24px 0;
    margin: 0;
    font-weight: bold;
    border-bottom: 1px solid var(--border-color);
  }
}