// Dark theme overrides
.dark-theme {
  .ant-btn-disabled,
  .ant-btn.disabled,
  .ant-btn[disabled],
  .ant-btn-disabled:hover,
  .ant-btn.disabled:hover,
  .ant-btn[disabled]:hover,
  .ant-btn-disabled:focus,
  .ant-btn.disabled:focus,
  .ant-btn[disabled]:focus,
  .ant-btn-disabled:active,
  .ant-btn.disabled:active,
  .ant-btn[disabled]:active,
  .ant-btn-disabled.active,
  .ant-btn.disabled.active,
  .ant-btn[disabled].active,
  {
    background: var(--bg-dark);
    color: var(--text-primary);
    border: 1px solid var(--border-darker);
  }
  .ant-radio-wrapper-disabled{
    background: #3b3b3b !important;
    color: var(--text-primary) !important;
    .ant-radio-inner{
      background-color: #777777 !important;
      border-color: #3b3b3b !important;
    }
  }

  .emoji-mart {
    background: var(--bg-lighter) !important;
    color: var(--text-primary) !important;

    .emoji-mart-search input {
      background: transparent !important;
      border: 1px solid var(--border-gray) !important;
    }

    .emoji-mart-search-icon {
      svg {
        fill: var(--text-primary);
      }
    }

    .emoji-mart-bar {
      &:first-child {
        border-bottom: 1px solid var(--border-gray) !important;
      }

      &:last-child {
        border-top: 1px solid var(--border-gray) !important;
      }
    }

    .emoji-mart-category-label span {
      background: var(--bg-lighter) !important;
    }
  }

  // FROALA DARK MODE CSS
  /* Based on Sublime Text's Monokai theme */
  .froalaEditor {
    .CodeMirror {
      height: 100% !important;
      background: #1c1c1c;
      color: #d4d4d4;

      div.CodeMirror-selected {
        background: rgba(252, 69, 133, 0.478);
      }
      .CodeMirror-selectedtext,
      .CodeMirror-selected,
      .CodeMirror-line::selection,
      .CodeMirror-line > span::selection,
      .CodeMirror-line > span > span::selection,
      .CodeMirror-line::-moz-selection,
      .CodeMirror-line > span::-moz-selection,
      .CodeMirror-line > span > span::-moz-selection {
        background: rgba(252, 67, 132, 0.47);
      }
      .CodeMirror pre {
        padding-left: 0px;
      }
      .CodeMirror-gutters {
        background: #1c1c1c;
        border-right: 0px;
      }
      .CodeMirror-linenumber {
        color: #777777;
        padding-right: 10px;
      }
      .CodeMirror-activeline .CodeMirror-linenumber.CodeMirror-gutter-elt {
        background: #1c1c1c;
        color: #fc4384;
      }
      .CodeMirror-linenumber {
        color: #777;
      }
      .CodeMirror-cursor {
        border-left: 2px solid #fc4384;
      }
      .cm-searching {
        background: rgba(243, 155, 53, 0.3) !important;
        outline: 1px solid #f39b35;
      }
      .cm-searching.CodeMirror-selectedtext {
        background: rgba(243, 155, 53, 0.7) !important;
        color: white;
      }
      .cm-keyword {
        color: #00a7aa;
      } /**/
      .cm-atom {
        color: #f39b35;
      }
      .cm-number,
      span.cm-type {
        color: #a06fca;
      } /**/
      .cm-def {
        color: #98e342;
      }
      .cm-property,
      span.cm-variable {
        color: #d4d4d4;
        font-style: italic;
      }
      span.cm-variable-2 {
        color: #da7dae;
        font-style: italic;
      }
      span.cm-variable-3 {
        color: #a06fca;
      }
      .cm-type.cm-def {
        color: #fc4384;
        font-style: normal;
        text-decoration: underline;
      }
      .cm-property.cm-def {
        color: #fc4384;
        font-style: normal;
      }
      .cm-callee {
        color: #fc4384;
        font-style: normal;
      }
      .cm-operator {
        color: #fc4384;
      } /**/
      .cm-qualifier,
      .cm-tag {
        color: #fc4384;
      }
      .cm-tag.cm-bracket {
        color: #d4d4d4;
      }
      .cm-attribute {
        color: #a06fca;
      }
      .cm-comment {
        color: #696d70;
        font-style: italic;
        font-weight: normal;
      } /**/
      .cm-comment.cm-tag {
        color: #fc4384;
      }
      .cm-comment.cm-attribute {
        color: #d4d4d4;
      }
      .cm-string {
        color: #e6db74;
      } /**/
      .cm-string-2 {
        color: #f39b35;
      } /*?*/
      .cm-meta {
        color: #d4d4d4;
        background: inherit;
      }
      .cm-builtin {
        color: #fc4384;
      } /*?*/
      .cm-header {
        color: #da7dae;
      }
      .cm-hr {
        color: #98e342;
      }
      .cm-link {
        color: #696d70;
        font-style: italic;
        text-decoration: none;
      } /**/
      .cm-error {
        border-bottom: 1px solid #c42412;
      }
      .CodeMirror-activeline-background {
        background: #272727;
      }
      .CodeMirror-matchingbracket {
        outline: 1px solid grey;
        color: #d4d4d4 !important;
      }
    }
  }

  .code-mirror-parent {
    background: #1c1c1c;
  }
}
