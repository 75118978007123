// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system
// scss-docs-start color-variables

// Global colors
$light-blue-200: #e6f7ff !default;

$blue-100: #f3faff !default;
$blue-200: #eaf1ff !default;
$blue-300: #e3e7fa !default;
$blue-400: #51b2ff !default;
$blue-500: #1872ff !default;
$blue-700: #334bfa !default;

$dark-blue-200: #919bb0 !default;
$dark-blue-300: #577592 !default;
$dark-blue-500: #354052 !default;
$dark-blue-700: #182c40 !default;
$dark-blue-900: #242f3a !default;

$red-100: #fbf0ef !default;
$red-300: #fed9db !default;
$red-400: #ff7e7e !default;
$red-500: #f5695f !default;
$red-600: #d44b4b !default;
$red-700: #b41d34 !default;

$yellow-100: #fff7ed !default;
$yellow-300: #fff5d9 !default;
$yellow-400: #ecd01a !default;
$yellow-500: #ffe120 !default;
$yellow-600: #fbd181 !default;
$yellow-700: #f9bf1f !default;
$yellow-800: #f6b620 !default;

$orange-300: #fde6dd !default;
$orange-500: #e58e2a !default;
$orange-700: #ff854b !default;

$green-100: #d7efdc !default;
$green-300: #1fdb7d !default;
$green-400: #29d24a !default;
$green-500: #1abc9c !default;
$green-700: #0f8a40 !default;

$pink-300: #f7e5fc !default;
$pink-400: #f4afdf !default;
$pink-700: #c701f1 !default;

$brown-300: #feedaf !default;
$brown-700: #735f1f !default;

// Other Brand colors
$mail-color: #ff4751;
$facebook-color: #0084ff;
$twitter-color: #1ea1f2;
$pinterest-color: #bd081c;

// Workflow colors
$workflow-connector-bg-color: #cfd1d2;
$workflow-success-bg-color: #d4f9ef;
$workflow-success-text-color: #2b9c7e;
$workflow-path-bg-color: #f2f4f5;
$workflow-path-text-color: #818384;
$workflow-danger-bg-color: #f9d4d4;
$workflow-danger-text-color: #d44b4b;

// Global colors
$primary-color: $blue-500; // primary color for all components
$primary-color-secondary: $blue-400; // primary hover color for all components
$primary-color-hover: rgba(24, 114, 255, 0.8);

$bg-lightest-blue: $dark-blue-700;
$bg-success: $green-500;

$success-color: $green-500; // success state color
$warning-color: $yellow-700;
$error-color: $red-500; // error state color
$error-color-dark: $red-600;

// KB Editor colors
$kb-editor-link-color: #195fe9;

// Messenger Appearance Preview
$messenger-tab-bg-color: #f5f9fb;

// Messages > Post
$messages-post-h1-color: #006ba9;

// Messages > Bot
$bot-button-bg-color: #e6e9fe;
$bot-button-text-color: #041595;
$bot-button-outline-color: #2948ff;

// Misc colors
$bot-connector-bg-color: #cfd1d2;
$conversation-typing-indicator-dot-color: #3b3745;
// scss-docs-end color-variables

// FUNCTIONS

@function toRGB($color) {
  @return red($color) + ', ' + green($color) + ', ' + blue($color);
}

// scss-docs-start gray-color-variables
// Dark
$white-dark: #131315 !default;
$white-dark-rgb: toRGB($white-dark) !default;
$gray-100-dark: #101012 !default;
$gray-200-dark: #090909 !default;
$gray-300-dark: #343434 !default;
$gray-400-dark: #222428 !default;
$gray-500-dark: #464646 !default;
$gray-600-dark: #a6a6a6 !default;
$gray-700-dark: #949597 !default;
$black-dark: #fff !default;
$black-dark-rgb: toRGB($black-dark) !default;
$text-dark: #c4c8ce;

// Light
$white-light: #fff !default;
$white-light-rgb: toRGB($white-light) !default;
$gray-100-light: #fafafa !default; // important
$gray-200-light: #f6f6f6 !default;
$gray-300-light: #f1f1f1 !default; // important
$gray-400-light: #e8e8e8 !default; // important
$gray-500-light: #c7c7c7 !default;
$gray-600-light: #8f8f8f !default;
$gray-700-light: #707070 !default;
$black-light: #000 !default;
$black-light-rgb: toRGB($black-light) !default;
$text-light: $dark-blue-700;
// scss-docs-end gray-color-variables

// Defaults
$font-size-base: 14px; // major text font size
$gist-space: 20px;
$default-bg-opacity: 0.1;
$default-bg-opacity-light: 0.1;
$default-bg-opacity-dark: 0.1;
$default-box-shadow: 0 20px 30px 0 rgba(20, 29, 62, 0.1);

@mixin border-with-opacity(
  $hex-color,
  $opacity: $default-bg-opacity,
  $size: 1px
) {
  border: $size solid rgba($hex-color, $opacity);
}

@mixin color-with-opacity($hex-color, $opacity: $default-bg-opacity) {
  color: rgba($hex-color, $opacity);
}

@mixin background-with-opacity($hex-color, $opacity: $default-bg-opacity) {
  background: rgba($hex-color, $opacity);
}

// ======================================================================================= //

// Screen sizes
$screen-xxl: 1920px;
$screen-xl: 1440px;
$screen-lg: 1199px;
$screen-md: 768px;
$screen-sm: 600px;

// Top Navbar
$header-border-bottom: var(--border-gray);

// Workspace notification bar
$notification-top-bar-bg-color: #feedaf;

// Select box
$select-box-hover-bg: $light-blue-200;

// Input
$input-validation-success: $green-500;
$input-validation-failed: $red-500;

// Icons
$icon-color-light: $dark-blue-200;

// Tag components
$label-success-bg: $green-100;
$label-success-text: $green-700;

$label-info-bg: $blue-300;
$label-info-text: $blue-700;

$label-warning-bg: $brown-300;
$label-warning-text: $brown-700;

$label-danger-bg: $red-300;
$label-danger-text: $red-700;

// Alert components
$alert-success-bg: $green-100;
$alert-success-text: $green-700;

$alert-info-bg: $blue-300;
$alert-info-text: $blue-700;

$alert-warning-bg: $brown-300;
$alert-warning-text: $brown-700;

$alert-danger-bg: $red-300;
$alert-danger-text: $red-700;

// FroalaEditor
$chat-message-image-max-width: 600px !important;

//grapesjs editor
$form-template-header-height: 65px;
$editor-drawer-width: 350px;
$editor-top-bar-height: 70px;

// Conversation Inbox
$conversation-teammate-away-color: $yellow-400;

// Preview browser
$preview-browser-close-color: $red-400;
$preview-browser-expand-color: $yellow-500;
$preview-browser-minimize-color: $green-400;

// Message > Chat
$messages-chat-notification-bg: #fff;
$messages-chat-notification-text: #000;

// Workflow
$workflow-invalid-component-bg: $red-100;

// Settings
$settings-delete-button: $red-500; //delete button color
$defaultGradientColorCode: (
  'bg-1': #f54654,
  'bg-2': #2e4ae5,
  'bg-3': #483baa,
  'bg-4': #58ceb6,
  'bg-5': #801f8d,
  'bg-6': #1db8ab,
); // b

$shadow-light: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
  rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(62, 67, 108, 0.1) 0px 4px 8px 0px;
