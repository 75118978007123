@import '../../style/variable';

.notification-layout {
  background: $notification-top-bar-bg-color;
  display: flex;
  flex-direction: row;
  height: 48px;
  align-items: center;
  justify-content: center;
  font-size: $font-size-base;
  flex-shrink: 0;
  font-weight: 600;
  &.update-banner {
    background: #7f54ce;
    p {
      color: white;
    }
  }
  &.prime {
    background: #4a2bac;
    justify-content: space-between;
    padding: 0 50px;
    p {
      color: #fff;
    }
    .ant-statistic-content {
      display: flex;
      justify-content: center;
      .ant-statistic-content-value {
        font-size:14px;
        color: #fff;
      }
    }
    a {
      font-weight: 700;
      white-space: nowrap;
      background: var(--black);
      border-color: var(--black);
      color: var(--text-white);
    }
  }
  .action {
    cursor: pointer;
    padding: 5px 12px;
    background-color: var(--text-primary);
    color: var(--text-white);
    border-radius: 4px;
    margin-left: 10px;
    &:hover {
      background: rgba(24, 44, 64, 0.9);
    }
  }
  p {
    color: #6f5602;
    margin-bottom: 0;
    margin-right: 10px;
  }
  .switch-back {
    margin-right: 10px;
  }
  i {
    position: absolute;
    right: 20px;
    font-size: 12px !important;
    cursor: pointer;
    &:hover {
      color: var(--text-secondary);
    }
  }
  &.overlay {
    position: absolute;
    width: 100%;
  }
  &.allow-notification {
    padding: 20px 30px;
    justify-content: left;
    p,
    button {
      font-weight: normal;
    }
    button {
      padding: 5px 10px;
    }
  }
  &.ant-modal-body {
    h2 {
      font-size: 18px;
      font-weight: 600;
    }
    p {
      font-size: 13px;
      margin-bottom: 20px;
    }
  }
}
.notification-hide {
  display: none;
}
.email-subscription {
  .ant-modal-content .ant-modal-close {
    display: none;
  }
  .ant-modal-body .ant-modal-close {
    [class^='fa-'] {
      font-size: 25px !important;
    }
    :hover {
      color: $error-color;
    }
    display: block;
    margin-top: 10px;
  }
  label {
    font-weight: 500;
    color: var(--text-black);
  }
  .ant-form-item-label {
    line-height: 2;
  }
  .ant-form-item {
    margin-bottom: 10px;
  }
}
.email-notification-layout {
    width: 100%;
    background: #feedaf;
    min-height: 48px;
    align-items: center;
    display: flex;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-bottom: -48px;
    transition: margin-bottom 0.3s ease-in-out;
    p {
      margin: 0;
      color: #6f5602;
      font-weight: 600;
    }

    &.slide-down {
      margin-bottom: 0;
    }

    &.slide-up {
      visibility: hidden;
      margin-bottom: -48px;
      transition: margin-bottom 0.3s ease-in-out;
    }
}
