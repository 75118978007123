@import '../../../style/variable';
@import '../../../style/mixin';

%flex-align-center {
  display: flex;
  align-items: center;
}

.inbox-sidebar-container {
  overflow: hidden;
  min-width: 420px;
  max-width: 540px;
  flex-basis: 24%;
  background: var(--sidebar-bg-light) !important;
  border-left: 1px solid var(--black-opacity-bg);
  transition: min-width 150ms ease-in-out, flex-basis 150ms ease-in-out;

  &.collapsed {
    width: 0 !important;
    flex-basis: 0 !important;
    min-width: 0 !important;
  }

  @media screen and (max-width: $screen-lg) {
    height: 0;
    position: absolute;
    transition: all 0.5s 0s;
    display: none;
    width: 330px;

    &.active {
      height: calc(100% - 58px);
      right: 0;
      position: fixed;
      z-index: 2;
      display: block;
    }

    .close-icon {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 1;

      &:hover {
        background: var(--search-box-bg);
      }
    }
  }

  .inbox-sidebar-content {
    height: 100%;
  }

  .user-properties-container {
    height: 100%;

    .ant-collapse {
      font-size: 13px;
    }

    .close-icon {
      display: none;
    }

    .no-profile {
      position: absolute;
      top: 50%;
      text-align: center;
      min-width: 330px;
      flex-basis: 20%;
      font-weight: 500;
      font-size: 18px;
    }

    .ant-skeleton {
      padding: 20px 20px 0 20px;
    }

    .info-section {
      overflow: hidden;
      padding: 10px 15px 15px;
      border-bottom: 1px solid var(--black-opacity-bg);
      font-size: 13px;

      .profile-info {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .profile-url {
          text-decoration: none;
        }

        .user-info {
          position: relative;
          overflow: hidden;
          display: flex;

          a {
            display: flex;

            .profile-avatar {
              margin-right: 10px;
            }
          }
          .active-status {
            border: 1px solid var(--border-white);
            height: 10px;
            width: 10px;
            border-radius: 6px;
            position: absolute;
            z-index: 1;
            left: 20px;
            top: 20px;
          }
          .user-name {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-left: 8px;

            @media screen and (max-width: 1700px) {
              max-width: 200px;
            }

            h3 {
              font-size: 16px;
              color: var(--text-secondary);
              font-weight: 600;
              margin: 0;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .fa-badge-check {
              color: var(--green-400);
              font-size: 18px;
              margin-left: 5px;
            }
            .fa-stack {
              .fa-badge {
                color: var(--orange-500);
                font-size: 18px;
              }
              .fa-question {
                font-size: 10px;
                color: var(--white);
              }
            }
          }
        }

        .options {
          display: flex;
          align-items: center;
        }

        .user-more-info {
          cursor: pointer;
        }
      }

      .user-location,
      .user-time {
        margin-top: 10px;
        color: var(--text-secondary);

        i,
        img {
          margin-right: 8px;
        }
      }
    }

    .additional-info {
      padding: 0 10px;
      overflow-y: auto;
      height: calc(100% - 120px);

      .actions-container {
        padding: 5px 5px 20px 5px;

        .link {
          cursor: pointer;
          color: var(--text-primary);

          i {
            color: var(--text-primary);
            margin-right: 6px;
            opacity: 0.5;
          }

          &:hover {
            color: var(--primary-color);

            i {
              color: var(--primary-color);
            }
          }
        }

        .btn {
          padding: 2px 15px;
        }
      }
    }

    .list-collapse {
      margin-top: 10px;

      &.hidden {
        display: none;
      }

      // override ant design
    .ant-collapse-item {
      background: var(--bg-white);
      border: 1px solid var(--black-opacity-bg);
      border-radius: 5px;
      margin: 8px 0;

        //:first-of-type {
        //  margin-top: 0;
        //}

        &.qualification-properties {
        .ant-collapse-header {
          .text-light {
            font-size: 12px;
            color: var(--text-light);
            margin-left: 5px;
          }
        }
      }

        .ant-collapse-header {
          padding: 8px 10px 8px 15px;
          font-size: $font-size-base;
          font-weight: 500;
          color: var(--text-primary);
        display: flex;

          .ant-collapse-extra {
            margin-left: 10px;
            float: none;

            i {
              font-size: 13px;
            }
          }

        i {
          right: 15px;
          left: auto;
        }
      }
    }

      // Property list section, detail view and qualification
      .property-list {
        display: flex;
        align-items: center;
        color: var(--text-light);
        margin-bottom: 10px;
        gap: 6px;
        max-height: 20px;
        font-size: 13px;

        &:last-of-type {
          margin-bottom: 0;
        }

        .editing-state {
          min-width: 130px !important;
        }
        .list-item-list-type {
          min-width: 120px !important;
        }

        .property-icon {
          width: 16px;
          font-size: $font-size-base;
          color: var(--text-light);
          padding: 0;
        }

        .property-name {
          color: var(--text-light);
          width: 40%;
          min-width: 0;
        }

        .property-value {
          color: var(--text-primary);
          width: 60%;
          padding-left: 12px;
          .disabled {
            cursor: not-allowed;
          }
        }

        .list-item {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &.active {
            color: $success-color;
          }

          &.in-active {
          }

          .ant-calendar-picker-input {
            height: 21px;
            padding: 0 5px;

            & + i {
              display: none;
            }
          }

          .editable-cell-value-wrap {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      .editable-input {
        height: 21px;
        padding: 0 5px;
      }

      .ant-form-item-control {
        line-height: 0 !important;
      }

      // Qualification attribute section
      .qualification-properties {
        .list-item {
          &:nth-child(2) {
            flex-shrink: 0;
          }

          & + .list-item::first-letter:not(:nth-child(2)) {
            text-transform: capitalize;
          }
        }

        .fa-circle {
          color: var(--gray-400);
        }
      }

      // last viewed section
      .last-viewed {
        .list-item {
          &:first-child {
            color: var(--text-light);
            flex-shrink: 0;
            width: 30px;
          }
        }
      }

      .search {
        margin-bottom: 12px;
      }
      .manage-link,
      .show-more,
      .show-less {
        cursor: pointer;
        font-size: 13px;
        color: var(--text-primary);
        font-weight: 500;
        display: inline-block;
        margin-top: 8px;
      }

      .show-less {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .email-panel {
        margin-bottom: 130px;
      }


    }
    .editable-cell-value-wrap {
      .first-option-tag {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .single-list-tag {
        max-width: 100px;
      }
      .multi-select-tag,
      .single-list-tag {
        cursor: pointer;
      }
      &:hover {
        background: var(--search-box-bg);
        border-radius: 4px;
      }
    }
  }
}

// Tag modal
.tag-modal {
  .ant-select {
    .ant-select-selection {
      padding: 8px;
      background: var(--search-box-bg);
      border-radius: 30px;

      .ant-select-selection__rendered {
        ul {
          li.ant-select-selection__choice {
            border-radius: 50px;
            height: 35px;
            line-height: 33px;
            padding: 0 30px 0 20px;
            background: var(--bg-white);

            .ant-select-selection__choice__remove {
              margin-right: 8px;
            }
          }
        }
      }
    }
  }

  .ant-modal-footer {
    text-align: left;
  }
}

.tag-note {
  margin-left: 3px;
  margin-bottom: -10px;
  margin-top: 10px;
}

.cancel-tag {
  margin-left: 10px;
}

.apply-tag-select-dropdown {
  .ant-select-dropdown-menu-item {
    display: block;
  }
}

.customize-widgets-editor {
  padding-bottom: 100px;
  &.hidden {
    display: none;
  }

  .widget-item {
    background: var(--bg-white);
    border: 1px solid var(--black-opacity-bg);
    border-radius: 5px;
    margin: 8px 0;
    cursor: grab;

    .widget-header {
      padding: 10.5px 10px 10.5px 15px;
      font-size: 14px;
      font-weight: 500;
      display: flex;

      .drag-icon-container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-right: 8px;
        touch-action: none;

        [class^='fa-'],
        [class*=' fa-'] {
          font-size: 13px;
        }
      }

      .widget-content {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .fa-minus-circle {
          color: var(--error-color);
          cursor: pointer;
        }

        .fa-plus-circle {
          color: var(--success-color);
          cursor: pointer;
        }
      }
    }
  }

  .separate-line {
    background: var(--black-opacity-bg);
  }

  .available-widgets-container {
    .available-widgets-inner {
      display: flex;
      justify-content: space-between;

      .left {
        display: flex;

        .widget-count {
          color: var(--text-light);
          padding-left: 5px;
        }
      }

      .right {
        i {
          color: var(--primary-color);
        }
      }
    }
  }
}

.properties .multi-select-list,
.properties .single-select-list {
  min-width: 150px;
  .ant-select-selection__placeholder {
    margin-top: -10px !important;
  }
  .ant-select-selection__choice {
    max-width: 100px;
    margin-top: 3px !important;
  }
  .ant-select-selection--multiple,
  .ant-select-selection--single {
    max-height: 22px;
    overflow: scroll;
    overflow-x: hidden;
    border: 1px solid var(--primary-color);
    padding: 10px 4px;
    max-width: 125px;
    .ant-select-arrow {
      margin-top: -6px;
    }
    .ant-select-selection__rendered {
      width: 90% !important;
    }
  }
  .ant-select-selection--multiple {
    .ant-select-selection__choice__content {
      max-width: 40px;
    }
  }
  .ant-select-selection__clear {
    right: 35px;
    svg {
      width: 15px;
      height: 15px;
    }
  }
  .ant-select-selection--single {
    display: flex;
    align-items: center;
    overflow: hidden !important;
    .ant-select-selection__clear {
      right: 30px;
    }
  }
}

.properties .editable-cell-value-wrap {
  text-overflow: ellipsis;
  overflow: hidden;
}

.properties {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.editable-cell-value-wrap {
  .fa-caret-down {
    padding: 0 7px;
    cursor: pointer;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
  .placeholder {
    display: inline;
    color: var(--text-light);
    cursor: pointer;
    &:hover {
      color: var(--text-secondary);
    }
    &.add {
      cursor: text;
    }
  }
}

.customize-widgets-editor {
  width: 250px;

  .widget-item {
    background: var(--bg-white);
    border: 1px solid var(--black-opacity-bg);
    border-radius: 5px;
    margin: 8px 0;
    cursor: grab;

    .widget-header {
      padding: 6px 10px 6px 15px;
      font-size: 13px;
      font-weight: 500;
      display: flex;

      .drag-icon-container {
        position: relative;
        top: 2px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-right: 8px;
        touch-action: none;

        i {
          font-size: 12px;
        }
      }

      .widget-content {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .fa-minus-circle {
          color: var(--error-color);
          cursor: pointer;
        }

        .fa-plus-circle {
          color: var(--success-color);
          cursor: pointer;
        }
      }
    }
  }

  .separate-line {
    margin: 15px 0 10px;
  }

  .available-widgets-container {
    .available-widgets-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        display: flex;
        align-items: center;
        font-size: 13px;

        .widget-count {
          color: var(--text-light);
          padding-left: 5px;
        }
      }

      .right {
        i {
          color: var(--primary-color);
        }
      }
    }
  }
}
.error-boundary {
  padding: 0px 10px;
  span {
    color: var(--primary-color);
    cursor: pointer;
  }
}
.widgets-preference-popover.ant-popover {
  //TODO: Need to optimize this
  top: unset !important;
  bottom: -100vh !important;
  .ant-popover-content {
    .ant-popover-arrow {
      visibility: hidden;
    }
    .ant-popover-inner-content {
      padding: 10px !important;
      max-height: 100vh;
      overflow: auto;
    }
  }
}

.conversation-widget {
      .more-tags {
        padding: 3px 8px;
        color: var(--text-light);
        font-size: 12px;
        cursor: pointer;
      }

      .previous-conversations-container {
        overflow-y: auto;
        max-height: 422px;

        .fa-phone {
          margin-right: 5px;
        }
        .block-quote {
          color: orange;
          font-size: 10px;
        }
      }

      .load-more {
        border-top: 1px solid var(--border-light);
      }


  .conversation {
    display: block;
    color: var(--text-light);
    cursor: pointer;
    padding: 10px 15px;
    border-radius: 0;
    border-bottom: 1px solid var(--border-gray);

    &:last-of-type {
      border-bottom: none;
    }

    & + .conversation {
      padding-top: 10px;
      border-top: none;

      &:last-of-type {
        margin-bottom: 0;
        border-bottom: none !important;
      }
    }

    &:first-child {
      margin-top: 0;
    }

    &:hover {
      background: var(--conversation-hover-bg-color);
    }

    > div:first-child {
      // username and image
      @extend %flex-align-center;

      h4 {
        margin: 0 0 2px 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: var(--text-primary);
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        flex: 1;
      }

      .time {
        //flex: 1;
        font-size: 13px;
        text-align: right;
        float: right;
        //min-width: 90px;
      }
    }

    > div:nth-child(2) {
      // Content
      display: flex;
      margin-top: 6px;
      overflow: hidden;
      position: relative;

      > p {
        color: var(--text-secondary);
        margin-bottom: 0;
        line-height: 1.5;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        word-break: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
      }
    }

    .team-emoji {
      font-size: 16px;
    }

    .profile-avatar {
      &.custom {
        @include profileContainer(24px, 24px, 11px);
      }
    }
    .fa-badge-check {
      color: var(--green-400);
      font-size: 16px;
      margin: 0 5px;
    }
    .fa-stack {
      .fa-badge {
        color: var(--orange-500);
        font-size: 16px;
      }
      .fa-question {
        font-size: 8px;
        color: var(--white);
      }
    }
  }

  .email-panel {
    margin-bottom: 130px;
  }

  .load-more {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    font-weight: 500;
    color: var(--primary-color);
    cursor: pointer;
  }
}
.no-padding-lr {
  .ant-collapse-content-box {
    padding: 0 !important;
  }
  .search {
    margin: 5px 10px;

    .ant-input-suffix {
      .anticon {
        display: block;
        font-size: 14px;
      }
    }
  }
  .no-results-previous-conversation {
    text-align: center;
    margin: 10px;
  }
  .conversation-align-search-icon {
    i {
      top: 40%;
    }
  }
  .no-conversation-align-search-icon, {
    i {
      top: 0;
    }
  }
}

.sheet-drawer {
  .ant-drawer-body {
    padding: 0px !important;
    height: calc(100vh - 57px);

    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }
}
