@import 'variable';
@import 'mixin';

%vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

%profile {
  @include prefixer(display, inline-flex);
  align-items: center;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  justify-content: center;
  border-radius: 30px;
  font-size: 13px;
}

// Messages editor nav tab (Common code)
.editor-main-tabs {
  & > .ant-tabs-content {
    & > .ant-tabs-tabpane {
      height: calc(100vh - 109px);
      overflow-y: auto;
      padding: 0;
    }
  }

  & > .ant-tabs-bar {
    position: relative;
    text-align: center;
    background: var(--bg-white);
    margin-bottom: 0;
    padding: 0;

    .ant-tabs-extra-content {
      float: none !important;

      .back,
      .next {
        cursor: pointer;
        display: inline-block;
      }

      .back {
        i {
          margin-right: 8px;
        }
      }

      .next {
        i {
          margin-left: 8px;
        }
      }

      .left {
        display: flex;
        align-items: center;
        left: 20px;
        gap: 15px;
        @extend %vertical-center;

        a {
          color: var(--text-primary);
        }

        .title-container {
          display: flex;
          gap: 5px;

          .title {
            max-width: 240px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 700;
          }
        }
      }

      .right {
        display: flex;
        align-items: center;
        right: 30px;
        gap: 12px;
        @extend %vertical-center;

        .text-muted {
          i {
            margin-right: 4px;
          }
        }

        button {
          i {
            margin-right: 5px;
          }
        }

        .ant-switch {
          height: 30px;

          .ant-switch-inner {
            width: 52px;
          }

          &.ant-switch-checked {
            background-color: #1abc9c;
          }

          &:after {
            top: 1.4px !important;
            width: 25px !important;
            height: 25px !important;
          }
        }
      }
    }

    .ant-tabs-tab {
      padding-top: 15px;
      padding-bottom: 15px;

      .number {
        padding: 2px 5px;
        border-radius: 3px;
        color: var(--text-white);
        background-color: var(--text-light);
        margin-right: 5px;
        font-size: 15px;
      }

      &.ant-tabs-tab-active {
        .number {
          background-color: var(--primary-color);
        }
      }

      .anticon-right {
        font-size: 10px;
        margin-left: 10px;
        margin-right: 0;
      }
    }

    .ant-menu-item-selected {
      color: var(--text-primary);
      font-weight: 600;
    }
  }
  .view-report {
    line-height: 1.499;
  }
}

// Messages editor (Common code)
.messages-editor-component {
  background: var(--bg-lightest-gray) !important;

  .editor-tab-content {
    padding: 30px;
    background: var(--bg-white);
    border: 1px solid var(--border-light);
    border-radius: 5px;
    min-height: 400px;
    max-width: 1200px;
    margin: 20px auto;
    &:not(.segment-builder) {
      width: 60%;
    }
    .goalPropInvisible {
      display: none;
    }
    .goalPropVisible, .filter-properties-flex {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .filters .add-goal {
      display: inline-block;
      color: var(--primary-color);
      font-size: 14px;
      cursor: pointer;
    }
    .js {
      .tracking-code-editor {
        position: relative;
        .ace_editor {
          height: 40px !important;
          font-size: 14px !important;
        }
        .tracking-code-copy {
          display: none;
          position: absolute;
          top: 5px;
          right: 10px;
          font-size: 11px;
          padding: 5px;
        }
        &:hover {
          .tracking-code-copy {
            display: block;
          }
        }
      }
    }
  }

  .heading {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px !important;
    color: var(--text-secondary);
  }

  .ant-form-text {
    margin-left: 10px;
    font-weight: bolder;
  }

  .message-editor-container {
    display: flex;
    justify-content: center;
    padding: 20px;

    @media screen and (max-width: $screen-lg) {
      padding: 40px;
    }
  }

  .ant-form-item-label {
    font-weight: 500;
  }

  .editor-schedule-content {
    &.scheduled-details {
      min-height: auto;
      align-items: center !important;
      text-align: center;
      justify-content: center;
    }

    &.review-send {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    & > .step-item,
    div > .step-item {
      margin-bottom: 20px;
    }

    .step-item {
      display: flex;
      gap: 20px;

      .step-number {
        color: var(--primary-color);
        background: var(--light-blue-color);
        border-radius: 5px;
        font-weight: 600;
        //padding: 10px 15px;
        width: 35px;
        height: 35px;
        text-align: -webkit-center;
        display: inline-block;
        font-size: 18px;
        padding: 5px;
      }
    }

    .ant-calendar-picker-input,
    .ant-time-picker-input {
      border: 1px solid var(--input-box-border);
      color: var(--text-secondary);
    }

    .pause {
      background-color: var(--search-box-bg);
      border-color: var(--search-box-bg);
      color: var(--text-secondary);
      margin-left: 10px;
      margin-right: 20px;
    }

    h3 {
      font-size: 17px;
      color: var(--text-secondary);
    }

    p {
      color: var(--text-primary);
      font-size: $font-size-base;
    }

    .select-md {
      width: 432px;
    }

    .desc {
      font-weight: 600;
      margin-bottom: 10px;

      .fal fa-circle-question {
        margin-left: 3px;
      }
    }

    .send-now {
      margin-left: 52px;
      width: 432px;
      .resend-email {
        .ant-form-item {
            margin-bottom: 10px;
        }
      }
    }

    .later {
      .resend-email {
        margin-left: 55px;
        .ant-form-item {
            margin-bottom: 8px;
        }
      }
    }

    .border-class {
      max-width: 432px;
      border: 1px solid var(--border-light);
      border-radius: 5px;
      padding: 20px;
      margin-bottom: 15px;

      .ant-form-item,
      .ant-form-item-control-wrapper {
        width: 100%;
        margin-bottom: 0;
      }
    }
    .batch-sending-info {
      border-radius: 5px;
      margin-bottom: 15px;
      padding: 10px;
      background: var(--conversation-note-message-bg);
    }
  }

  .option-desc {
    display: none;
  }
  .workflow-dropdown-button {
    .fas.fa-ellipsis {
      font-size: 14px;
      height: 5px;
    }
    button:has(.fas.fa-ellipsis) {
      margin-left: 0.5px !important;
    }
    button[disabled] {
      background-color: var(--primary-color);
      color: #fff;
    }
  }
}

// Email Composer/Editor Tab
.message-editor {
  display: flex;
  border: 1px solid var(--border-light);
  border-radius: 5px;
  width: 100%;
  max-width: 2000px;
  height: calc(100vh - 170px);

  .text-editor {
    background: var(--bg-white);
    flex: 1;
    flex-shrink: 0;
    padding: 20px;
    max-width: 50%;
    overflow: scroll;

    .ant-select-selection {
      .ant-select-dropdown-menu-item,
      .ant-select-selection-selected-value {
        img {
          height: 25px;
          width: 25px;
          border-radius: 50%;
          margin-right: 15px;
        }
      }
    }
    .ant-input {
      &:hover,
      &:active {
        border: 1px solid var(--primary-color) !important;
      }
    }

    .fr-box:not(.fr-fullscreen) .fr-wrapper {
      min-height: 200px;
      overflow-y: auto;
    }

    .element {
      display: flex;
      .ant-form-item {
        flex-basis: 50%;
        overflow: hidden;
        &:last-child {
          margin-left: 25px;
        }
      }
    }
    .ant-form-item {
      margin-bottom: 10px;
      padding-bottom: 0;
      .ant-form-item-label {
        padding-bottom: 3px;
      }
      .ant-input {
        font-size: $font-size-base;
        color: var(--text-secondary);
        height: 40px;
      }
      .ant-select-selection {
        border: 1px solid var(--border-light);
        border-radius: 4px;
        color: var(--text-secondary);
      }
    }
    .footer {
      margin-top: 25px;
      .submit {
        background: var(--primary-color);
        margin-right: 10px;
        font-weight: 600;
      }
      .test-email {
        background: var(--search-box-bg);
        color: var(--text-light);
        border: 1px solid var(--border-light);
        margin-right: 25px;
        font-weight: 600;
      }
    }
  }

  &.one-off-email {
    .text-editor {
      padding: 0;
    }
  }

  &.campaign {
    .footer {
      background-color: var(--white);
      position: sticky;
      bottom: -20px;
      padding-top: 10px;
      z-index: 4;
    }
    .text-editor {
      padding: 0 !important;
      .fr-toolbar {
        position: sticky;
        z-index: 3;
        top: -20px;
      }
      .fr-toolbar.fr-bottom,
      .fr-toolbar.fr-top {
        box-shadow: none !important;
        border: 1px solid var(--border-gray) !important;
      }
      .fr-box:not(.fr-fullscreen) .fr-wrapper {
        border-top: none !important;
      }
    }
  }

  .message-preview-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-shrink: 0;
    padding-left: 20px;

    h2 {
      font-size: 18px !important;
      margin-bottom: 20px;
      color: var(--text-secondary);
      display: inline;
      margin-left: 10px;
    }

    .device-switcher {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 15px 0;
    }
  }
}

.message-preview {
  flex: 1;
  border-radius: 5px;
  height: calc(100% - 52px);
  overflow: hidden;

  .preview-container {
    height: calc(100% - 103px);

    .content {
      color: var(--text-primary);
      margin-bottom: 0;
      overflow: scroll;
      //white-space: pre-line;
      word-break: break-word;
      height: 100%;

      iframe {
        height: 100%;
        width: 100%;
        border: none;
        background: var(--bg-white);
      }
    }
    .content:has(> iframe) {
      overflow: hidden;
    }
  }

  &.is-mobile {
    width: 380px;
    margin: 0 auto;
    .ellipse {
      //display: none;
    }
  }

  .ellipse {
    background: var(--bg-white);
    padding: 12px 20px;
    border-bottom: 1px solid var(--border-light);
    border-radius: 0 5px 0 0;

    .label {
      display: inline-block;
      margin-right: 10px;
      padding: 0;
      width: 10px;
      height: 10px;
      border-radius: 8px;
      &.danger {
        background-color: $preview-browser-close-color;
      }
      &.yellow {
        background-color: $preview-browser-expand-color;
      }
      &.success {
        background-color: $preview-browser-minimize-color;
      }
    }
  }

  .subject {
    padding: 10px 20px;
    border-bottom: 1px solid var(--border-light);
    font-weight: 600;
    color: var(--text-secondary);
    background: var(--bg-white);
  }
}

.bee-container {
  height: calc(100vh - 163px);

  .message-preview {
    .ellipse {
      h2 {
        margin-left: 10px;
        margin-bottom: 0;
      }

      .actions {
        margin-right: 30px;
      }
    }
  }

  .device-switcher {
    position: fixed;
    text-align: center;
    width: 100%;
    margin-top: 15px;
  }

  .bee-preview-close {
    position: absolute;
    top: 64px;
    right: 20px;
    color: #000;
    font-size: 20px;
    cursor: pointer;
  }

  .bee-custom-template-preview-close {
    color: #000;
    font-size: 20px;
    cursor: pointer;
  }
}

.bottom-toolbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 8px 20px;
  background: var(--bg-white);
  border-top: 1px solid var(--gray-500);
  display: flex;
  align-items: center;
  gap: 15px;
  .fas.fa-ellipsis {
    font-size: 14px;
    height: 5px;
  }
  button:has(.fas.fa-ellipsis) {
    margin-left: 0.5px !important;
  }
}

// Recipients Tab
.segment-builder {
  max-width: 1400px !important;
  padding: 0;

  .show-messenger {
    display: flex;

    .ant-radio-wrapper {
      flex-grow: 1;
      flex-basis: 425px;

      .ant-radio {
        margin-right: 15px;
      }

      .heading {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 3px;
        color: var(--text-primary);
      }

      .description {
        width: 100%;
        font-weight: 400;
        overflow: hidden;
        white-space: pre-wrap;
        color: var(--text-light);
      }

      display: flex;
      align-items: center;
      border-radius: 5px;
      flex-direction: row;
      border: 1px solid var(--border-light);
      padding: 15px;
      margin-bottom: 8px;
    }

    .ant-radio-wrapper-checked {
      border: 1px solid var(--primary-color);
      background: var(--light-blue-color);
    }

    .only-one {
      .ant-switch {
        bottom: 0;
      }
    }
  }

  //.ant-radio-group {
  //  padding: 50px 70px 35px;
  //
  //  .ant-radio-button-wrapper-checked {
  //    background: var(--bg-lightest-blue);
  //    border-color: var(--primary-color);
  //    color: inherit;
  //  }
  //
  //  .ant-radio-button-wrapper {
  //    height: auto;
  //    line-height: normal;
  //    padding: 32px 55px;
  //    text-align: left;
  //    border-radius: 5px;
  //
  //    & + .ant-radio-button-wrapper {
  //      margin-left: 10px;
  //    }
  //
  //    h3 {
  //      font-size: 17px;
  //      margin-bottom: 5px;
  //      color: var(--text-secondary);
  //    }
  //
  //    p {
  //      margin-bottom: 0;
  //      font-size: $font-size-base;
  //      color: var(--text-light);
  //    }
  //
  //    input[type='radio'] {
  //      margin-left: -32px;
  //    }
  //  }
  //}

  &.display-rules {
    .item-title {
      min-width: 0 !important;
    }

    .filters .filter .title {
      min-width: 40px;
    }
  }

  .subscription-type {
    margin-top: 30px;
    display: flex;
    align-items: center;
    gap: 20px;

    .title {
      text-align: left;
      color: var(--text-primary);
    }
  }

  .desc {
    margin: 20px 0 0 145px;

    .ant-checkbox-wrapper {
      margin-right: 8px;
    }
  }

  .recipient-left {
    flex-grow: 1;
    flex-shrink: 0;
    max-width: calc(100% - 400px);
    padding: 30px;
  }

  .recipient-right {
    color: var(--text-secondary);
    flex-basis: 400px;
    border: none;
    border-left: 1px solid var(--border-light);
    border-bottom: 0;

    .total-recipients {
      display: flex;
      justify-content: space-between;
      padding: 15px 20px;
      border-bottom: 1px solid var(--border-light);
      font-weight: 500;
      font-size: 15px;
    }

    .recipients-list {
      display: flex;
      flex-direction: column;
      min-height: calc(100vh - 250px) !important;
      overflow: auto;
      padding: 20px;
      gap: 20px;

      .ant-spin {
        justify-content: center;
        display: flex;
        height: 100vh;
        align-items: center;
      }

      .recipient-users {
        display: flex;
        align-items: center;
        font-size: 15px;
        gap: 10px;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .remaining-people {
        text-align: center;
        color: var(--text-light);
        font-weight: 600;
      }
    }
  }
}

.send-live-modal {
  width: 600px !important;

  .ant-modal-header {
    padding-top: 30px;
    text-align: center;

    .ant-modal-title {
      h2 {
        font-size: 22px !important;
        font-weight: bold !important;
        margin-bottom: 5px;
      }
      span {
        color: var(--text-light);
        font-size: $font-size-base;
        font-weight: normal;
      }
    }
  }

  .ant-modal-body {
    table {
      width: 100%;
    }

    table,
    tr {
      border: 1px solid var(--border-light);
      border-collapse: collapse;

      td {
        padding: 20px;
      }

      & > td:first-child {
        width: 30%;
      }
    }
  }
}

.segment-builder {
  &.segment-drawer,
  &.trigger-section {
    max-width: 100% !important;
  }
  .filters {
    display: flex;
    flex-direction: column;
    gap: 25px;
    overflow: auto;

    .filter {
      display: flex;
      align-items: center;

      .title {
        min-width: 107px;
        text-align: left;
        color: var(--text-primary);
      }

      .content {
        display: flex;
        flex-direction: column;
        gap: 12px;
        flex: 1;
        margin-left: 30px;
        border: 1px solid var(--border-light);
        border-radius: 4px;
        padding: 15px 25px;
        border-left-color: var(--primary-color);
        border-left-width: 3px;
        &.goals{
          border: none;
          margin: 0px;
          padding: 0px;
          .item:first-child {
            justify-content: flex-start;
            .filter-group > div:first-child {
                width: 180px;
            }
          }
        }
        &.no-border {
          display: flex;
          flex-direction: row;
          gap: 50px;
          border: none;
          padding: 0;
          color: var(--text-secondary);
          font-weight: 600;
          font-size: 17px;
        }

        .item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;

          &:nth-child(2) {
            .ant-input {
              width: 150px;
              color: var(--text-secondary);
            }
          }

          &.event {
            & + .time-frame {
              margin-left: 133px;
            }

            .form-item:first-of-type {
              min-width: 162px;
            }
          }

          &:not(.time-frame) {
            .item-title {
              min-width: 132px;
            }
          }

          .item-title {
            & > span {
              color: var(--text-light);
              margin-right: 5px;
            }
          }

          .filter-group {
            display: flex;
            align-items: center;
            gap: 10px;

            .ant-select-selection--multiple {
              .ant-select-selection__rendered {
                ul {
                  & > li {
                    &:not(:nth-last-child(-n + 2)) {
                      margin-bottom: 3px;
                    }
                  }
                }
              }
            }

            .ant-calendar-picker {
              & + .ant-input {
                margin-left: 0 !important;
                margin-top: 5px;
              }
            }

            .ant-input {
              min-width: 144px;
              color: var(--text-secondary);
            }

            .label {
              color: var(--text-secondary);
            }

            .between-date {
              .ant-calendar-picker-input {
                min-width: 240px;
                padding: 8px 15px;
              }
              .ant-calendar-picker-clear {
                top: 45%;
              }
            }

            .longer-textbox {
              min-width: 50%;
            }

            .input-number-md {
              min-width: 80px;
            }

            .ant-select-selection__choice__content {
              img {
                @extend %profile;
              }
            }

            .ant-select-selection--multiple {
              min-width: 250px;
            }
          }

          .cancel-icon {
            i {
              font-size: 11px !important;
              cursor: pointer;
            }
          }
        }

        .actions {
          display: flex;
          align-items: center;
          gap: 10px;

          .label {
            color: var(--text-light);
          }
        }
      }
    }
  }
}
