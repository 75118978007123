@import '../../../style/variable';
@import '../../../style/mixin';

%drawer-wrapper {
  .ant-drawer-content-wrapper[style] {
    background: var(--bg-white);
    //margin-top: 58px;
    box-shadow: $default-box-shadow;
  }
}

%heading {
  .drawer-heading {
    margin-bottom: 20px;
    h2 {
      font-size: 18px;
      color: var(--text-secondary);
      margin-bottom: 5px;
    }
    p {
      font-size: 13px;
      color: var(--text-light);
      margin-bottom: 0;
    }
  }
}

.fa-caret-down {
  font-size: 12px !important;
}

%button {
  button {
    height: 36px;
  }
  .cancel {
    font-weight: 500;
    margin-left: 10px;
    border-radius: 4px;
  }
}

.broadcast-template-drawer {
  .drawer-content {
    height: calc(100vh - 175px);
  }
}
.broadcast-drawer {
  @extend %drawer-wrapper;
  .ant-drawer-body {
    padding: 30px;
    @extend %heading;
    .drawer-content {
      .ant-row {
        margin-bottom: 30px;
        h3 {
          font-size: $font-size-base;
          font-weight: 500;
          margin-bottom: 5px;
          color: var(--text-primary);
        }
        p {
          font-size: 13px;
          color: var(--text-light);
          margin-bottom: 0;
        }
        @extend %button;
      }
      .ant-input {
        border: 1px solid var(--primary-color);
        color: var(--text-primary);
      }
      .ant-form-item-label label:after {
        content: '';
      }
      .submit {
        background: var(--primary-color);
        margin-right: 10px;
        font-weight: 600;
      }
      .cancel {
        margin-right: 25px;
        font-weight: 600;
      }
    }
    .mail-builder {
      margin-bottom: 15px;
      .ant-radio-inner {
        display: none;
      }
      .ant-radio-wrapper {
        .ant-radio {
          display: none;
          & + * {
            padding: 0;
          }
        }
        .ant-card-cover {
          img {
            width: 60px;
            margin: 30px auto 0;
          }
          &::after {
            content: '';
            font-family: 'Font Awesome 6 Pro';
            color: var(--primary-color);
            position: absolute;
            left: 10px;
            font-size: 16px;
            top: 10px;
            width: 16px;
            height: 16px;
            border: 2px solid gray;
            border-radius: 50%;
          }
        }
        .ant-card-meta-detail {
          text-align: center;
          .ant-card-meta-description {
            white-space: normal;
            color: var(--text-primary);
          }
        }
        &.ant-radio-wrapper-disabled {
          img {
            filter: grayscale(1);
          }
        }
      }
      .ant-radio-wrapper-checked {
        .ant-card-cover::after {
          content: '\f106';
          border: none;
          top: 6px;
        }
      }
    }
  }
}

.template-drawer {
  @extend %drawer-wrapper;
  .ant-drawer-body {
    padding: 30px 0 !important;
    .drawer-heading {
      padding: 0 30px;
      div {
        cursor: pointer;
        margin-bottom: 10px;
      }
      h2 {
        font-size: 18px;
        color: var(--text-secondary);
        margin-bottom: 0;
      }
    }
    .drawer-content {
      margin: 5px 0;
      padding: 0 30px;
      overflow: auto;

      .ant-tabs-bar {
        border-bottom: none;
        margin-bottom: 0;

        .ant-tabs-tab {
          margin: 0;
          padding: 10px;

          &:hover {
            color: var(--text-primary);
          }
          &.ant-tabs-tab-active {
            color: var(--text-primary);
          }
          &.ant-tabs-tab-disabled {
            color: var(--text-light);
          }
        }
      }

      .ant-tabs-content {
        .ant-tabs-tabpane {
          padding: 0;
        }

        .all-template-tab {
          padding: 30px 0 20px;

          .no-data {
            padding: 100px;
            text-align: center;
            font-size: 18px;
            color: var(--text-secondary);
          }

          .template {
            width: 250px;
            height: 300px;
            border-radius: 5px;
            cursor: pointer;

            .overlay {
              top: 0;
              left: 0;
              width: 90%;
              height: 100%;
              position: absolute;
            }

            & + p {
              margin: 8px 0 25px 0;
              font-size: 15px;
              font-weight: 600;
              color: var(--text-secondary);
              text-transform: capitalize;
            }

            & iframe {
              border: 5px solid transparent;
              @include prefixer(transform-origin, 0 0);
              @include prefixer(transform, scale(0.5));
              width: 500px;
              height: 600px;
            }

            .active-image {
              border-color: var(--primary-color);
              box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
            }
            //& img {
            //  border: none;
            //  height: inherit;
            //  width: inherit;
            //}
          }
        }
        .visual-builder-template-tab {
          padding: 15px 0 !important;
          margin-top: 5px;
          .template {
            height: 180px !important;
            width: 160px !important;
            iframe {
              height: 360px !important;
              width: 320px !important;
              border: 4px solid var(--border-light);
              border-radius: 4px;
            }
            .active-image {
              border: 4px solid var(--primary-color);
            }
          }
        }
      }
      h3 {
        font-size: $font-size-base;
        margin-bottom: 0;
      }
    }
    .action-button {
      padding: 0 30px;
      @extend %button;
    }
    .bee-templates {
      padding: 15px 0;
      .ant-col {
        margin-bottom: 10px;
      }
      .overlay {
        top: 0;
        left: 0;
        width: 90%;
        height: 100%;
        position: absolute;
        cursor: pointer;
        & :hover {
          cursor: pointer;
        }
      }
      img {
        border-radius: 4px;
        width: 160px;
        margin-bottom: 5px;
        border: 1px solid var(--border-light);
        &.active-image {
          border: 2px solid var(--primary-color);
        }
      }
    }
  }
}

.tag-enabled {
  @include color-with-opacity(
    $hex-color: var(--black-rgb),
    $opacity: 0.25
  ); // Jitta TODO Check once
  background-color: var(--bg-lighter) !important;
  border-color: var(--border-light) !important;
}

.ant-table-column-has-actions:hover .new-tag {
  display: block;
}
.ant-table-column-has-actions:hover .tag-inline {
  display: inline;
  margin: 0;
}
.error-message {
  color: var(--error-color);
}
