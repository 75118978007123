.cursor-default {
  cursor: default;
}

.cursor {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-text {
  cursor: text;
}

.cursor-move {
  cursor: move;
}

.cursor-grab {
  cursor: grab;
}

.cursor-grabbing {
  cursor: grabbing;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.select-none {
  user-select: none;
}

.select-all {
  user-select: all;
}

.resize-none {
  resize: none;
}

.resize {
  resize: both;
}
