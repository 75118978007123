.pointer-events-none {
  pointer-events: none !important;
}

.pointer-events-auto {
  pointer-events: auto !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.static {
  position: static !important;
}

.fixed {
  position: fixed !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.sticky {
  position: sticky !important;
}

.inset-0 {
  inset: 0px;
}

.inset-x-0 {
  left: 0px;
  right: 0px;
}

.inset-y-0 {
  top: 0px;
  bottom: 0px;
}

.start-0 {
  inset-inline-start: 0px;
}

.end-0 {
  inset-inline-end: 0px;
}

.top-0 {
  top: 0px;
}

.right-0 {
  right: 0px;
}

.bottom-0 {
  bottom: 0px;
}

.left-0 {
  left: 0px;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

.box-border {
  box-sizing: border-box !important;
}

.box-content {
  box-sizing: content-box !important;
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block !important;
}

.inline {
  display: inline !important;
}

.flex {
  display: flex !important;
}

.inline-flex {
  display: inline-flex !important;
}

.table {
  display: table !important;
}

.inline-table {
  display: inline-table !important;
}

.table-caption {
  display: table-caption !important;
}

.table-cell {
  display: table-cell !important;
}

.table-column {
  display: table-column !important;
}

.table-column-group {
  display: table-column-group !important;
}

.table-footer-group {
  display: table-footer-group !important;
}

.table-header-group {
  display: table-header-group !important;
}

.table-row-group {
  display: table-row-group !important;
}

.table-row {
  display: table-row !important;
}

.flow-root {
  display: flow-root !important;
}

.grid {
  display: grid !important;
}

.contents {
  display: contents !important;
}

//.list-item {
//  display: list-item !important;
//}

.hidden {
  display: none !important;
}

.place-self-center {
  place-self: center !important;
}

.self-start {
  align-self: flex-start !important;
}

.self-end {
  align-self: flex-end !important;
}

.self-center {
  align-self: center !important;
}

.justify-self-end {
  justify-self: end !important;
}

.justify-self-center {
  justify-self: center !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.overflow-ellipsis {
  text-overflow: ellipsis !important;
}

.whitespace-normal {
  white-space: normal !important;
}

.whitespace-nowrap {
  white-space: nowrap !important;
}

.whitespace-pre {
  white-space: pre !important;
}

.whitespace-pre-line {
  white-space: pre-line !important;
}

.whitespace-pre-wrap {
  white-space: pre-wrap !important;
}

.break-words {
  overflow-wrap: break-word !important;
}

.break-all {
  word-break: break-all !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-justify {
  text-align: justify !important;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.font-mono {
  font-family: monospace !important;
}

.transition-all {
  transition-property: all;
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
}

.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke;
}

.transition-opacity {
  transition-property: opacity;
}

.transition-shadow {
  transition-property: box-shadow;
}

.duration-100 {
  transition-duration: 0.1s;
}

.duration-150 {
  transition-duration: 150ms;
}

.duration-200 {
  transition-duration: 0.2s;
}

.duration-300 {
  transition-duration: 0.3s;
}

.duration-500 {
  transition-duration: 0.5s;
}

.duration-1000 {
  transition-duration: 1s;
}

.ease-linear {
  transition-timing-function: linear;
}

.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
