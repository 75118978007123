@import '../../../style/variable';
@import '../../../style/mixin';

%flex-align-center {
  display: flex;
  align-items: center;
}

%drawer-wrapper {
  .ant-drawer-content-wrapper[style] {
    background: var(--bg-white);
    //margin-top: 58px;
    box-shadow: $default-box-shadow;
  }
}

%heading {
  .drawer-heading {
    margin-bottom: 30px;
    h2 {
      font-size: 18px;
      color: var(--text-secondary);
      margin-bottom: 5px;
    }
    p {
      font-size: 13px;
      color: var(--text-light);
      margin-bottom: 0;
    }
  }
}

.fa-caret-down {
  font-size: 12px !important;
}

%button {
  button {
    height: 36px;
  }
  .cancel {
    border: 1px solid var(--input-box-border);
    background-color: var(--search-box-bg);
    font-weight: 500;
    color: var(--text-light);
    margin-left: 20px;
    border-radius: 4px;
  }
}

.broadcast-drawer {
  @extend %drawer-wrapper;
  .ant-drawer-body {
    padding: 30px;
    @extend %heading;
    .drawer-content {
      .ant-row {
        margin-bottom: 30px;
        h3 {
          font-size: $font-size-base;
          font-weight: 500;
          margin-bottom: 5px;
          color: var(--text-primary);
        }
        p {
          font-size: 13px;
          color: var(--text-light);
          margin-bottom: 0;
        }
        @extend %button;
      }
      .ant-input {
        border: 1px solid var(--primary-color);
        color: var(--text-primary);
      }
      .submit {
        background: var(--primary-color);
        margin-right: 10px;
        font-weight: 600;
      }
      .cancel {
        background: var(--search-box-bg);
        color: var(--text-light);
        border: 1px solid var(--border-light);
        margin-right: 25px;
        font-weight: 600;
      }
    }
  }
}

.template-drawer {
  .drawer-content {
    height: calc(100vh - 175px);
  }
  @extend %drawer-wrapper;
  .ant-drawer-body {
    padding: 30px;
    .drawer-heading.on-going {
      display: flex;
      h2 {
        flex-grow: 1;
        font-size: 18px;
        color: var(--text-secondary);
        margin-bottom: 0;
      }
      @extend %button;
    }
    .drawer-content {
      margin: 5px 0 20px;
      .no-data {
        padding: 100px;
        text-align: center;
        font-size: 18px;
        color: var(--text-secondary);
      }
      .ant-tabs-bar {
        border-bottom: none;
        margin-bottom: 0;
        .ant-tabs-tab {
          margin: 0;
          padding: 10px;
          &:hover {
            color: var(--text-primary);
          }
          &.ant-tabs-tab-active {
            color: var(--text-primary);
          }
          &.ant-tabs-tab-disabled {
            color: var(--text-light);
          }
        }
      }

      .ant-tabs-content {
        .all-template-tab {
          padding: 30px 0 20px;

          .template {
            width: 250px;
            height: 300px;
            border-radius: 5px;
            cursor: pointer;

            .overlay {
              top: 0;
              left: 0;
              width: 90%;
              height: 100%;
              position: absolute;
            }

            & + p {
              margin: 8px 0 25px 0;
              font-size: 15px;
              font-weight: 600;
              color: var(--text-secondary);
              text-transform: capitalize;
            }

            & iframe {
              border: 5px solid transparent;
              @include prefixer(transform-origin, 0 0);
              @include prefixer(transform, scale(0.5));
              width: 500px;
              height: 600px;
            }

            .active-image {
              border-color: var(--primary-color);
              box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
            }

            & img {
              border: none;
              height: inherit;
              width: inherit;
            }
          }
        }
      }
    }
  }
}
