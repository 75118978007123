.text-xxs-ib2 {
  font-size: 8px !important;
}

.text-xs-ib2 {
  font-size: 10px !important;
}

.text-sm-ib2 {
  font-size: 12px !important;
}

.text-sm {
  font-size: 13px !important;
}

.text-base {
  font-size: 14px !important;
}

.text-md {
  font-size: 16px !important;
}

.text-lg {
  font-size: 18px !important;
}

.text-xl {
  font-size: 22px !important;
}

.text-2xl {
  font-size: 32px !important;
}

.text-4xl {
  font-size: 38px !important;
}

.font-light {
  font-weight: 300 !important;
}

.font-normal {
  font-weight: 400 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.font-bold {
  font-weight: 700 !important;
}

.font-extrabold {
  font-weight: 800 !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.normal-case {
  text-transform: none !important;
}

.italic {
  font-style: italic !important;
}

.not-italic {
  font-style: normal !important;
}

.leading-4 {
  line-height: 16px !important;
}

.leading-5 {
  line-height: 20px !important;
}

.leading-6 {
  line-height: 24px !important;
}

.leading-8 {
  line-height: 32px !important;
}

.leading-12 {
  line-height: 48px !important;
}

.leading-none {
  line-height: 1 !important;
}

.leading-tight {
  line-height: 1.25 !important;
}

.leading-snug {
  line-height: 1.375 !important;
}

.leading-normal {
  line-height: 1.5 !important;
}

.leading-relaxed {
  line-height: 1.625 !important;
}

.leading-4\.5 {
  line-height: 18px !important;
}

.tracking-wide {
  letter-spacing: 0.025em !important;
}

.tracking-widest {
  letter-spacing: 0.1em !important;
}

.underline {
  text-decoration: underline !important;
}

.line-through {
  text-decoration: line-through !important;
}

.no-underline {
  text-decoration: none !important;
}

.placeholder-gray::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgba(115, 115, 118, var(--tw-placeholder-opacity)) !important;
}

.placeholder-opacity-50::placeholder {
  --tw-placeholder-opacity: 0.5 !important;
}

.placeholder-opacity-75::placeholder {
  --tw-placeholder-opacity: 0.75 !important;
}

.color-inherit {
  color: inherit !important;
}

.icon-hover:hover {
  color: var(--primary-color);
}

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3
}

.line-clamp-5 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5
}

.line-clamp-6 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6
}

.line-clamp-none {
  -webkit-line-clamp: unset
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}
