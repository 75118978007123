@import 'variable';
@import 'mixin';

// RESET
.ant-checkbox-inner {
  background: var(--bg-white);
  &:before {
    content: ' ';
    width: 2px;
    height: 2px;
    background: var(--bg-white);
    border-radius: 50%;
    position: absolute;
    transform: translate(50%);
    top: calc(50% - 1px);
    right: 50%;
  }
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  &:before {
    content: none;
  }
}

.ant-tag {
  cursor: default;
  padding: 0 6px;
  height: auto;
  font-size: 13px;
  border-radius: 3px;
  vertical-align: middle;
}

.ant-select-dropdown-menu-item-group-title {
  font-size: $font-size-base;
  margin: 10px 0 0 0 !important;
  font-weight: 600;
  color: var(--text-secondary);
}

.ant-select-dropdown {
  .ant-select-not-found {
    padding: 0 !important;
  }
  .ant-select-not-found,
  .ant-empty-normal {
    display: block;
    text-align: center;
    .ant-empty,
    .ant-empty-image,
    .ant-empty-description {
      display: none;
    }
    &:after {
      //TODO:need to change text once get confirmation
      content: 'No results found';
      font-size: 13px;
      color: var(--text-secondary);
    }
  }
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: var(--text-white);
}

// dropdown and select background colour change
.ant-select-dropdown-menu-item {
  background-color: transparent !important;

  .ant-dropdown-menu-submenu-title {
    font-size: 13px;
  }
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-select-dropdown-menu-item:hover {
  background-color: var(--select-hover-color) !important;
}

.ant-select-dropdown-menu-item-selected {
  color: var(--primary-color) !important;
  background-color: var(--select-selected-color) !important;

  i {
    color: var(--primary-color) !important;
  }
}

.ant-form-item-label > label::after {
  content: '';
}

.ant-calendar-footer-extra {
  display: inline-block;
  float: left;
}

.ant-input-search {
  &.rounded {
    .ant-input {
      border-radius: 50px !important;
    }
  }
}

.ant-calendar-footer-extra {
  display: inline-block;
  float: left;
}

.ant-tooltip .ant-tooltip-inner {
  background-color: rgb(38, 38, 38);
}

.ant-tooltip .ant-tooltip-arrow {
  color: rgb(38, 38, 38);
}

.ant-table-column-title .fal fa-circle-question {
  padding-left: 6px;
  font-size: 12px;
}
.ant-drawer-content .fal fa-circle-question {
  padding-left: 6px;
  font-size: $font-size-base;
}

.ant-form-item-label {
  line-height: 1.5;
  margin-bottom: 8px;
}

.ant-select-disabled {
  .ant-select-selection {
    color: var(--text-light);
    background: transparent;
    border: none;

    .ant-select-arrow {
      display: none;
    }
  }
}

input::placeholder,
textarea::placeholder,
.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  color: var(--text-light) !important;
}

textarea.ant-input {
  height: auto;
  min-height: 40px;
}

.ant-select-selection--multiple .ant-select-selection__choice {
  background-color: rgb(245, 248, 250);
  color: rgb(51, 71, 91);
  border-color: rgb(124, 152, 182);
}
.ant-select-selection--multiple .ant-select-selection__choice__remove {
  color: rgb(51, 71, 91);
  top: 1px;
}
.form-item.tree-select-md
  .ant-select-selection--multiple
  .ant-select-selection__rendered {
  padding: 0;
}

.ant-form .ant-form-item input,
textarea.ant-input,
.ant-form .ant-form-item .ant-select-selection,
input.form-item,
.form-item input,
.form-item .ant-select-selection,
.ant-form input,
.ant-form .ant-select-selection,
.ant-form-item input,
.ant-form-item .ant-select-selection,
.form-item.input-md,
.form-item.input-sm,
.form-item.input-lg,
.form-item.input-password-md input,
.form-item.time-period .ant-input,
.form-item.select-sm .ant-select-selection,
.form-item.select-md .ant-select-selection,
.form-item.select-lg .ant-select-selection,
.form-item.multi-select .ant-select-selection,
.form-item.input-number-sm,
.form-item.input-number-md,
.form-item.input-number-lg,
.form-item.input-date-picker-sm input,
.form-item.input-date-picker-md input,
.form-item.input-date-picker-lg input,
.form-item.select-with-profile-md .ant-select-selection,
.form-item.select-with-profile-sm .ant-select-selection,
.form-item.tree-select-md .ant-select-selection--single,
.form-item.tree-select-md .ant-select-selection--multiple,
.form-item.input-addon-md .ant-input,
.form-item.ant-input-password .ant-input {
  border-radius: 4px;
  //border: 1px solid #cbd6e2!important;
  transition: all 0.15s ease-out;
  //background-color: #f5f8fa!important;
  color: var(--text-primary) !important;
}
.ant-input[disabled] {
  color: var(--border-dark);
  background-color: var(--bg-lighter);
  border: 1px solid var(--border-dark) !important;
  cursor: not-allowed;
  box-shadow: none !important;
}
.ant-input-number {
  width: 60px;
  &.hubspot-close-days-input {
    width: 75px;
  }
}
input::placeholder,
textarea::placeholder,
.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  color: var(--text-light) !important;
}

textarea.ant-input {
  height: auto;
  min-height: 40px;
}

.ant-select-selection--multiple .ant-select-selection__choice {
  background-color: rgb(245, 248, 250);
  color: rgb(51, 71, 91);
  border-color: rgb(124, 152, 182);
}
.ant-select-selection--multiple .ant-select-selection__choice__remove {
  color: rgb(51, 71, 91);
  top: 1px;
}
.form-item.tree-select-md
  .ant-select-selection--multiple
  .ant-select-selection__rendered {
  padding: 0;
}

.ant-form-item-children p {
  font-size: $font-size-base;
  color: var(--text-light);
}

.ant-form-extra-desc {
  display: block;
  text-align: right;
  margin: 6px 0 0;
  font-size: 13px;
  color: var(--text-light);
}

.ant-btn-default {
  background-color: var(--search-box-bg);
  border-color: var(--search-box-bg);
  color: var(--text-secondary);
  font-weight: 500;
  font-size: $font-size-base;

  &:disabled {
    @include color-with-opacity($hex-color: var(--black-rgb), $opacity: 0.25);
    background-color: var(--bg-lighter);
    border: 1px solid var(--border-light);
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

input.form-item,
.ant-input,
textarea,
.ant-input-number {
  border: 1px solid var(--border-gray);
  background: transparent;
}

.ant-popover {
  .ant-popover-inner {
    border-radius: 6px;

    .ant-popover-inner-content {
      color: var(--text-secondary);
      .ant-popover-message {
        .ant-popover-message-title {
          & > * {
            color: var(--text-primary);
          }
        }
      }
    }

    .ant-popover-title {
      color: var(--text-primary);
    }
  }

  .ant-input-affix-wrapper input {
    border: 1px solid var(--border-gray) !important;
  }

  .ant-popover-arrow,
  .ant-popover-inner {
    background: var(--popover-bg);
  }
  .ant-popover-arrow {
    border-right-color: var(--popover-bg) !important;
    border-bottom-color: var(--popover-bg) !important;
  }
}

.ant-menu-submenu > .ant-menu {
  background: var(--dropdown-bg);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: var(--select-selected-color) !important;
}

.divider.ant-divider {
  &::before,
  &::after {
    border-top: 1px solid var(--border-gray);
  }
}

.ant-layout {
  background: var(--bg-white);
}

.ant-calendar-picker-icon {
  color: var(--icon-color-dark);
}

.ant-menu-submenu-popup {
  background: var(--bg-white);
}

.ant-checkbox-disabled + span {
  @include color-with-opacity($hex-color: var(--black-rgb), $opacity: 0.25);
  cursor: not-allowed;
}

.ant-card-bordered {
  border: 1px solid var(--border-gray);
}

.ant-collapse {
  border: 1px solid var(--border-gray);

  &.ant-collapse-borderless {
    border: none;
  }
}

.ant-spin-container {
  &::after {
    background: var(--bg-white);
  }
}

.ant-spin {
  .ant-spin-dot-item {
    background-color: var(--primary-color);
  }
  i {
    color: var(--primary-color) !important;
  }
}

.ant-select-dropdown-menu-item {
  &:hover {
    background-color: var(--select-hover-color) !important;
  }
}

.ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
  background-color: transparent !important;
  color: var(--text-secondary) !important;
}

.ant-select {
  .ant-select-selection-selected-value {
    & > img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }

  &.select-sm {
    .ant-select-selection-selected-value {
      & > img {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
  }
}

.ant-skeleton {
  .ant-skeleton-content {
    .ant-skeleton-avatar,
    .ant-skeleton-title,
    .ant-skeleton-paragraph > li {
      background: var(--skeleton-bg);
    }
  }

  &.ant-skeleton-active {
    .ant-skeleton-content {
      .ant-skeleton-title,
      .ant-skeleton-paragraph > li {
        background: var(--skeleton-active-bg);
        background-size: 400% 100%;
        animation: ant-skeleton-loading 1.4s ease infinite;
      }
    }

    .ant-skeleton-avatar {
      background: var(--skeleton-bg) !important;
    }
  }
}

.ant-layout-sider {
  background: var(--sidebar-bg-light) !important;
}

.ant-layout-sider .ant-menu {
  background: var(--sidebar-bg-light) !important;
}

.ant-layout-sider .ant-menu .ant-menu-submenu .ant-menu-sub {
  background: var(--sidebar-bg-light) !important;
}

body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
.ant-table-thead > tr > th,
.ant-form-item-label > label,
.ant-collapse > .ant-collapse-item > .ant-collapse-header,
.ant-select-dropdown-menu-item,
.ant-select-dropdown-menu-item-selected,
.ant-divider-horizontal.ant-divider-with-text-center,
.ant-divider-horizontal.ant-divider-with-text-left,
.ant-divider-horizontal.ant-divider-with-text-right,
.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: var(--text-primary);
}

.ant-steps,
.ant-form,
.ant-form-item,
.ant-checkbox-wrapper,
.ant-input,
.ant-radio-group,
.ant-radio-button-wrapper,
.ant-calendar-range-picker-separator,
.ant-form-explain,
.ant-form-extra,
.ant-select,
.ant-select-dropdown,
.ant-dropdown,
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a,
.ant-select-tree,
.ant-select-tree-dropdown,
.ant-select-tree li .ant-select-tree-node-content-wrapper,
.ant-menu,
.ant-table,
.ant-menu-item > a,
.ant-tabs,
.ant-modal,
.ant-modal-confirm-body .ant-modal-confirm-title,
.ant-collapse-content,
.ant-card,
.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title,
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: var(--text-secondary);
}

.ant-modal-confirm-content {
  color: var(--text-secondary) !important;
}

a,
.ant-tabs-nav .ant-tabs-tab-active {
  color: var(--primary-color);
  &:hover {
    color: var(--primary-color-hover);
  }
}

.ant-menu-item-selected > a,
.ant-menu-item-selected > a:hover,
.ant-menu-item > a:hover {
  color: var(--primary-color);
}

.ant-tabs-nav {
  .ant-tabs-tab:hover {
    color: var(--primary-color-hover);
  }
}

.ant-btn-primary {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  box-shadow: none;
  &:hover {
    background-color: var(--primary-color-hover);
    border: 1px solid var(--primary-color-hover);
  }
}

.ant-btn-default {
  &:hover {
    background-color: transparent;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
  }
}

.ant-btn-background-ghost.ant-btn-primary {
  color: var(--primary-color);
  &:hover {
    color: var(--primary-color-hover);
  }
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled),
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--primary-color);
  box-shadow: none;
}

.ant-checkbox-checked::after {
  border: 1px solid var(--primary-color);
}

.ant-switch-checked {
  background: var(--primary-color);
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: var(--primary-color);
  border-color: var(--primary-color);
  &:hover {
    background: var(--primary-color-hover);
    border-color: var(--primary-color-hover);
  }
}
.ant-radio-button-wrapper:hover {
  color: var(--primary-color);
}

.ant-tabs-ink-bar,
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: var(--primary-color);
}

.ant-table-thead
  > tr
  > th
  .ant-table-column-sorter
  .ant-table-column-sorter-inner
  .ant-table-column-sorter-up.on,
.ant-table-thead
  > tr
  > th
  .ant-table-column-sorter
  .ant-table-column-sorter-inner
  .ant-table-column-sorter-down.on {
  color: var(--primary-color);
}

.ant-select-tree-dropdown .ant-select-dropdown-search {
  background: transparent;
}

.ant-select-selection--multiple .ant-select-selection__choice {
  background-color: var(--bg-lightest-gray);
  border: 1px solid var(--border-dark);
  @include color-with-opacity($hex-color: var(--black-rgb), $opacity: 0.65);
}

// Pagination button
.ant-pagination-item-active {
  background: var(--white);
  border-color: var(--primary-color) !important;
}
.ant-pagination-item {
  background-color: var(--white);
  border: 1px solid var(--border-gray);
  color: var(--text-secondary);
  a {
    color: var(--text-secondary) !important;
    &:hover {
      border-color: var(--primary-color) !important;
    }
  }
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  background-color: var(--white);
  border: 1px solid var(--border-gray);
  color: var(--text-secondary);
  &:hover {
    border-color: var(--primary-color);
    color: var(--primary-color);
  }
}
.ant-pagination-item-ellipsis {
  color: var(--text-light) !important;
}
.ant-pagination-disabled a,
.ant-pagination-disabled:hover a,
.ant-pagination-disabled:focus a,
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
  color: var(--text-light);
}

.ant-select-arrow {
  right: 9px;
}
.ant-dropdown-menu,
.ant-select-dropdown-menu {
  padding: 0;
}

.ant-layout-sider .ant-menu .ant-menu-item:hover,
.ant-layout-sider .ant-menu .ant-menu-item:active {
  color: var(--text-primary);
  background: rgba(232, 233, 235, 0.5);
}

.ant-modal-body {
  padding: 20px;
  .ant-form-vertical .ant-form-item {
    padding-bottom: 0;
  }
}

.ant-table-tbody > tr:last-of-type > td {
  border-bottom: none;
}

.form-item .ant-input {
  box-shadow: none;
  box-shadow: none !important;
  border: 1px solid var(--border-gray);
}

.ant-collapse.ant-collapse-borderless {
  background: var(--bg-white);
}

.ant-select-selection:active,
.ant-select-selection:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.form-item.input-md:hover,
.form-item.input-sm:hover,
.form-item.input-lg:hover,
.form-item.input-password-md input:hover,
.form-item.time-period .ant-input:hover,
.form-item.select-sm .ant-select-selection:hover,
.form-item.select-md .ant-select-selection:hover,
.form-item.select-lg .ant-select-selection:hover,
.form-item.multi-select .ant-select-selection:hover,
.form-item.input-number-sm:hover,
.form-item.input-number-md:hover,
.form-item.input-number-lg:hover,
.form-item.input-date-picker-sm input:hover,
.form-item.input-date-picker-md input:hover,
.form-item.input-date-picker-lg input:hover,
.form-item.select-with-profile-md .ant-select-selection:hover,
.form-item.select-with-profile-sm .ant-select-selection:hover,
.form-item.tree-select-md .ant-select-selection--single:hover,
.form-item.tree-select-md .ant-select-selection--multiple:hover,
.form-item.input-addon-md .ant-input:hover,
.form-item.ant-input-password .ant-input:hover,
.form-item.input-md:active,
.form-item.input-sm:active,
.form-item.input-lg:active,
.form-item.input-password-md input:active,
.form-item.time-period .ant-input:active,
.form-item.select-sm .ant-select-selection:active,
.form-item.select-md .ant-select-selection:active,
.form-item.select-lg .ant-select-selection:active,
.form-item.multi-select .ant-select-selection:active,
.form-item.input-number-sm:active,
.form-item.input-number-md:active,
.form-item.input-number-lg:active,
.form-item.input-date-picker-sm input:active,
.form-item.input-date-picker-md input:active,
.form-item.input-date-picker-lg input:active,
.form-item.select-with-profile-md .ant-select-selection:active,
.form-item.select-with-profile-sm .ant-select-selection:active,
.form-item.tree-select-md .ant-select-selection--single:active,
.form-item.tree-select-md .ant-select-selection--multiple:active,
.form-item.input-addon-md .ant-input:active,
.form-item.ant-input-password .ant-input:active,
.form-item.input-md:focus,
.form-item.input-sm:focus,
.form-item.input-lg:focus,
.form-item.input-password-md input:focus,
.form-item.time-period .ant-input:focus,
.form-item.select-sm .ant-select-selection:focus,
.form-item.select-md .ant-select-selection:focus,
.form-item.select-lg .ant-select-selection:focus,
.form-item.multi-select .ant-select-selection:focus,
.form-item.input-number-sm:focus,
.form-item.input-number-md:focus,
.form-item.input-number-lg:focus,
.form-item.input-date-picker-sm input:focus,
.form-item.input-date-picker-md input:focus,
.form-item.input-date-picker-lg input:focus,
.form-item.select-with-profile-md .ant-select-selection:focus,
.form-item.select-with-profile-sm .ant-select-selection:focus,
.form-item.tree-select-md .ant-select-selection--single:focus,
.form-item.tree-select-md .ant-select-selection--multiple:focus,
.form-item.input-addon-md .ant-input:focus,
.form-item.ant-input-password .ant-input:focus {
  border: 1px solid var(--primary-color) !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 12px;
}
.ant-tabs-nav .ant-tabs-tab {
  margin: 0 10px 0 0;
}
.ant-modal-close {
  display: none;
}
.ant-modal-body .ant-modal-close {
  display: block;
}
.ant-form-item-control {
  line-height: 30px;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  font-size: 13px;
}
.ant-select-selection--single .ant-select-selection__rendered {
  margin-right: 30px;
  margin-left: 0;
  min-width: 40px;
}

// TODO - need to investigate why this was added
.ant-select-selection {
  //border: 1px solid var(--border-light);
  //padding: 3px 0 5px 8px;
  //height: 40px;
}

.ant-collapse {
  //border: 1px solid var(--border-gray);

  & > .ant-collapse-item {
    border-bottom: 1px solid var(--border-gray);
  }
}

.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
  background: var(--bg-gray);
}

.ant-drawer-content {
  background: var(--white);
  border-left: 1px solid var(--border-gray);
}

.ant-modal-content {
  background: var(--white);
  //border: 1px solid var(--border-gray);
  border-radius: 6px;

  .ant-modal-header {
    background: transparent;
    color: var(--text-secondary);
    border-bottom: 1px solid var(--border-gray);

    .ant-modal-title {
      font-weight: 600;
      color: var(--text-primary);
    }
  }
}

// OVERRIDE DEFAULT ANT MODAL
.ant-modal {
  .ant-modal-header {
    border-bottom: none;

    .ant-modal-title {
      font-size: 18px;
      font-weight: bold;
      line-height: 1.56;
    }
  }

  .ant-modal-body {
    padding: 24px;

    .ant-form-item {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    .flex.gap-4 {
      .ant-form-item {
        &:last-of-type {
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 20px;
        }
      }
    }
  }

  .ant-modal-header + .ant-modal-body {
    padding: 0 24px 24px 24px;
  }
}

.ant-select-tree-dropdown
  .ant-select-search__field__wrap
  .ant-select-search__field {
  border: 1px solid var(--border-gray);
  background: transparent;
}

.ant-tabs-bar {
  margin-bottom: 0;
  border-bottom: 1px solid var(--border-gray);
}

.ant-table table {
  //border-radius: 0;
  //TODO: shall we remove this border radius there are few issue in table corners
}

.ant-collapse-content {
  background: var(--white);
}

.ant-card {
  background: transparent;
}

.ant-table-placeholder {
  background: transparent;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  background: transparent;
}

.ant-radio-button-wrapper:first-child {
  border-left: 1px solid var(--border-gray);
}

.ant-menu > .ant-menu-item-divider,
.ant-divider {
  background: var(--bg-gray);
}

.ant-select-selection {
  background: transparent;
}

.ant-table-tbody > tr > td {
  border-bottom: 1px solid var(--border-gray);
}

.ant-radio-button-wrapper {
  background: transparent;
  border: 1px solid var(--border-gray);

  &:hover {
    border: 1px solid var(--primary-color);
  }
}

.ant-select-dropdown,
.ant-dropdown-menu {
  background: var(--dropdown-bg);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 24px;
}

.ant-select-tree li .ant-select-tree-node-content-wrapper:hover {
  background: var(--bg-gray);
}

.ant-switch:not(.ant-switch-checked) {
  background: var(--bg-dark);
}

.ant-select-dropdown {
  .ant-select-dropdown-menu-item-group-list {
    .ant-select-dropdown-menu-item {
      img {
        height: 18px;
        width: 18px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
  }

  .ant-select-dropdown-menu-item {
    display: flex;
    align-items: center;
    img,
    svg {
      height: 18px;
      width: 18px;
      border-radius: 50%;
      margin-right: 10px;
    }

    .anticon svg {
      margin-right: 0;
    }
  }
}

.ant-form-item {
  margin-bottom: 20px;
}

.ant-form-item-children {
  line-height: 1.5;
}

.ant-select-selection--multiple {
  height: auto;

  .ant-select-selection__rendered {
    margin: 0;
  }

  & > ul > li,
  .ant-select-selection__rendered > ul > li {
    margin-top: 3px;
    border-radius: 4px;
  }

  .ant-select-selection__placeholder {
    margin-left: 0;
  }
}

.ant-select-dropdown--multiple {
  .ant-select-selected-icon {
    svg {
      height: 16px;
      width: 16px;
    }
  }
}

.ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane,
.ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane {
  padding: 15px 20px;
}

.ant-input-affix-wrapper .ant-input:not(:first-child) {
  border: none;
  height: auto;
  padding: 0 12px 0 20px;
}

.ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 8px 12px;
}

.ant-btn.disabled,
.ant-btn[disabled] {
  opacity: 0.5;
}

.ant-menu-vertical.ant-menu-sub > .ant-menu-item i,
.ant-select-dropdown .ant-select-dropdown-menu-item i,
.ant-dropdown-menu .ant-dropdown-menu-item i {
  width: 12px;
  font-size: 14px;
  color: var(--text-secondary);
  padding: 0;
  margin: 0 8px 0 0;
}

.ant-menu-vertical > .ant-menu-item {
  height: 32px;
  line-height: 32px;
}
