#messenger-canvas {
  // Start CSS code from Messenger
  --text-secondary: #354052;
  --text-primary: #182c40;
  --text-light: #707070;
  --text-lighter: #bcbcbc;
  --error-color: #d44b4b;
  --border-gray: #e8e8e8;
  --text-white: #fff;
  --bg-white: #fff;
  --border-dark: #c7c7c7;
  --border-light: #f1f1f1;
  --gray-300: #f1f1f1;
  --conversation-hover-bg-color: rgba(232, 234, 236, 0.35);

  .gist-card-component {
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 8px;
    word-break: break-word;

    input[disabled],
    select[disabled],
    textarea[disabled] {
      pointer-events: none;
    }

    button[disabled],
    input[disabled] {
      cursor: default;
    }

    button,
    input[type='button'],
    input[type='reset'],
    input[type='submit'] {
      -webkit-appearance: button;
      cursor: pointer;
    }

    &.margin-bottom-none {
      margin-bottom: 0 !important;
    }

    // TEXT COMPONENT
    &.text-component {
      margin-bottom: 8px;

      p {
        font-size: 14px;
        line-height: 21px;
        margin: 0;

        &.header {
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
        }

        &.align-left {
          text-align: left;
        }

        &.align-center {
          text-align: center;
        }

        &.align-right {
          text-align: right;
        }

        &.muted {
          color: var(--text-lighter);

          .link-text {
            color: var(--text-lighter);
          }
        }

        &.error {
          color: var(--error-color);

          .link-text {
            color: var(--error-color);
          }
        }

        strong {
          font-weight: 600;
        }

        .link-text {
          text-decoration: underline;
        }
      }

      a {
        pointer-events: auto;
      }
    }

    // DATA TABLE COMPONENT
    &.data-table-component {
      .data-table {
        display: table;
        border-spacing: 8px;
        margin: 0 0 8px;

        .list-item {
          display: table-row;
          margin-bottom: 8px;
          height: 16px;
          line-height: 16px;
          font-size: 14px;
          font-weight: 400;

          .list-item-field {
            display: table-cell;
            white-space: normal;
            overflow: visible;
            text-overflow: clip;
            vertical-align: top;
            max-width: none;
            line-height: 19px;

            &:first-of-type {
              color: var(--text-light);
              word-break: normal;
            }

            &:last-of-type {
              color: var(--text-primary);
              padding-left: 10px;
            }
          }
        }
      }
    }

    // IMAGE COMPONENT
    &.image-component {
      position: relative;
      margin-top: 0;
      margin-bottom: 10px;

      .image-container {
        position: relative;
        max-width: 100%;

        &.align-left {
          text-align: left;
        }

        &.align-center {
          text-align: center;
          margin-left: auto;
          margin-right: auto;
        }

        &.align-right {
          text-align: right;
          margin-left: auto;
        }

        .final-image {
          max-width: 100%;
          height: auto;

          &.round {
            border-radius: 50%;
          }
        }

        [data-action-type='url'] {
          pointer-events: auto;
          cursor: pointer;
        }
      }
    }

    // SPACER COMPONENT
    &.spacer-component {
      margin: 0;

      .spacer-xs {
        width: 100%;
        height: 4px;
      }

      .spacer-s {
        width: 100%;
        height: 8px;
      }

      .spacer-m {
        width: 100%;
        height: 16px;
      }

      .spacer-l {
        width: 100%;
        height: 24px;
      }

      .spacer-xl {
        width: 100%;
        height: 32px;
      }
    }

    // DIVIDER COMPONENT
    &.divider-component {
      margin-bottom: 8px;
      padding: 0;

      .divider {
        border-top: 1px solid var(--border-gray);
        width: 100%;
      }
    }

    // BUTTON COMPONENT
    &.button-component {
      [data-action-type='url'] {
        &:after {
          content: ' ↗';
        }
      }

      button {
        font-size: 14px;
        line-height: 21px;
        border: 1px solid rgba(34, 34, 34, 0.2);
        border-radius: 3px;
        padding: 5px 12px 7px;
        display: inline-block;
        text-decoration: none;
        cursor: pointer;
        appearance: none;
        user-select: none;
        box-sizing: border-box;
        transition: box-shadow linear 40ms;
        vertical-align: bottom;
        width: 100%;
        height: 40px;
        outline: 0;

        &:active {
          box-shadow: 0 0 0 3px rgba(51, 75, 250, 0.2);
        }

        &:focus {
          outline: 0;
          box-shadow: 0 0 0 3px rgba(51, 75, 250, 0.2);
        }

        &:focus:active {
          outline: 0;
        }

        &.primary {
          color: var(--text-white);
          background-color: var(--primary-color);

          &:hover:not(.disabled) {
            background-color: var(--primary-color-hover);
          }
        }

        &.disabled {
          cursor: not-allowed;
          box-shadow: unset;
          border: none;
          background-color: var(--border-gray);
          color: var(--text-lighter);
        }

        &.secondary {
          background-color: var(--bg-white);
          color: var(--primary-color);
          border: 1px solid var(--primary-color);

          &.disabled {
            background-color: var(--bg-white);
            border-color: var(--text-lighter);
            color: var(--text-lighter);
          }

          &:hover:not(.disabled) {
            border: 1px solid var(--primary-color-hover);
            color: var(--primary-color-hover);
          }
        }

        &.link {
          border: none;
          background: none;
          color: var(--primary-color);

          &:active {
            box-shadow: unset;
          }

          &:focus {
            border: none;
            box-shadow: unset;
          }

          &:hover:not(.disabled) {
            color: var(--primary-color);
            box-shadow: none;
          }

          &.disabled {
            color: var(--text-lighter);
          }
        }

        &.loading {
          color: transparent;

          .messenger-card-loader {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 18px;
            height: 18px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 18px;
            animation: gist-spin 1.1s infinite linear;
            //background-image: url('../../Assets/img/loader-blue.svg');
          }
        }
      }
    }

    // INPUT COMPONENT
    &.input-component {
      .label-input-container {
        .input-label {
          display: block;
          margin-bottom: 8px;
          line-height: 21px;
          font-size: 14px;
          font-weight: 400;
          color: var(--text-light);
        }

        .input-container {
          position: relative;
          display: flex;
          flex: 1 1 auto;

          input {
            flex: 1;
            box-sizing: border-box;
            height: 40px;
            padding: 7px 35px 7px 12px;
            min-width: 35px;
            font-size: 14px;
            line-height: 20px;
            border-radius: 4px;
            color: var(--text-primary);
            background: transparent;
            border: 1px solid var(--border-gray);
            box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 3px 0px inset;

            &:hover {
              outline: 0;
            }

            &:focus {
              outline: 0;
              border-color: var(--primary-color);
            }
          }

          .input-button {
            box-shadow: none;
            position: relative;
            width: 24px;
            flex: 0 0 24px;
            margin-left: 8px;
            border-radius: 3px;
            background-position: center;
            //background-image: url(${AngleRightLight});
            background-size: 8px 28px;
            background-repeat: no-repeat;
            background-color: var(--primary-color);
            border: none;

            &.disabled {
              opacity: 0.5;
              pointer-events: none;
            }

            &.loading {
              opacity: 0.5;
              pointer-events: none;
              background-image: none;
            }
          }

          .input-success-icon {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            width: 24px;
            background-position: center;
            background-size: 14px 12px;
            background-repeat: no-repeat;
            //background-image: url('../../Assets/img/green-check-icon.png');
          }

          textarea {
            width: 100%;
          }
        }

        &.disabled {
          .input-container {
            cursor: not-allowed;

            input {
              background-color: #f1f1f1;

              &:hover {
                border-color: #de5e54;
              }
            }
          }
        }

        &.invalid {
          .input-label {
            color: #ff5a5a;
          }

          .input-container {
            input {
              color: #ff5a5a;
              border-color: #fbdbdb;
              background-color: #fef0f0;
            }
          }
        }
      }

      .messenger-card-loader {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 18px;
        height: 18px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 18px;
        animation: gist-spin 1.1s infinite linear;
        //background-image: url('../../Assets/img/loader-white.svg');
      }
    }

    // TEXTAREA COMPONENT
    &.textarea-component {
      .textarea-container {
        display: block;

        .textarea-label {
          display: block;
          margin-bottom: 8px;
          line-height: 21px;
          font-size: 14px;
          font-weight: 400;
          color: var(--text-light);
        }

        textarea {
          width: 100%;
          height: 80px;
          min-height: 48px;
          padding: 8px 36px 8px 12px;
          font-size: 14px;
          line-height: 20px;
          box-sizing: border-box;
          border-radius: 4px;
          color: var(--text-primary);
          overflow-wrap: break-word;
          background: transparent;
          border: 1px solid var(--border-gray);
          box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 3px 0px inset;

          &:hover {
            border-color: #307fff;
          }

          &:focus {
            outline: 0;
            border-color: var(--primary-color);
            box-shadow: 0 0 0 3px rgba(51, 75, 250, 0.2);
          }
        }

        &.disabled {
          cursor: not-allowed;

          textarea {
            background-color: var(--gray-300);
          }
        }

        &.error {
          textarea {
            color: #ff5a5a;
            border-color: #fbdbdb;
            background-color: #fef0f0;
          }
        }
      }
    }

    // LIST COMPONENT
    &.list-component {
      margin-bottom: 8px;
      padding-left: 0;
      padding-right: 0;
      margin-left: -16px;
      margin-right: -16px;

      &.last-component {
        margin-bottom: -16px !important;
      }

      &:last-child {
        padding-bottom: 0;
      }

      .list {
        border-top: 1px solid var(--border-gray);
        border-bottom: 1px solid var(--border-gray);

        .list-item {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          background-color: transparent;
          border-bottom: 1px solid var(--border-gray);
          font-size: 14px;
          line-height: 21px;
          padding: 16px 24px;

          &:first-child {
            border-top: none;
          }

          &:last-child {
            border-bottom: none;
          }

          &.loading {
            opacity: 0.5;
          }

          .text-container {
            flex: 1;

            .title {
              font-weight: 400;
              margin-bottom: 2px;

              &.disabled {
                color: var(--text-light);
              }
            }

            .subtitle {
              font-size: 14px;
              color: var(--text-light);

              .secondary-text,
              .tertiary-text {
                display: inline-block;
              }

              .tertiary-text {
                &:not(:first-child) {
                  &::before {
                    content: '\00a0\2022\00a0';
                  }
                }
              }
            }
          }

          .image-container {
            position: relative;
            max-width: 48px;
            max-height: 48px;
            margin-right: 10px;

            .final-image {
              height: auto;
              max-width: 100%;

              &.round {
                border-radius: 50%;
              }
            }
          }

          &.disabled {
            cursor: default;
            pointer-events: none;
          }

          .messenger-card-loader {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 18px;
            height: 18px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 18px;
            animation: gist-spin 1.1s infinite linear;
            //background-image: url('../../Assets/img/loader-blue.svg');
            position: relative;
            flex: 0 0 18px;
          }
        }

        [data-action-type='submit'],
        [data-action-type='url'] {
          cursor: pointer;

          &:hover {
            background-color: var(--conversation-hover-bg-color);
          }
          .title {
            color: var(--primary-color);
          }
        }
      }
    }

    // SINGLE SELECT COMPONENT
    &.single-select-component {
      .single-select-container {
        .label-single-select-container {
          display: block;
          margin-bottom: 8px;
          line-height: 21px;
          font-size: 14px;
          font-weight: 400;
          color: var(--text-light);
        }

        .single-select-options {
          display: flex;
          box-sizing: border-box;
          height: 36px;
          max-width: 100%;
          margin-bottom: 8px;

          .single-select-option-radio {
            display: none;

            &:checked + .single-select-option-label {
              color: var(--bg-white);
              border-color: var(--primary-color);
              background-color: var(--primary-color);
            }
          }

          .single-select-option-label {
            position: relative;
            flex: 1 0 0;
            margin: 0;
            padding: 8px 2px;
            overflow: hidden;
            font-weight: 400;
            font-size: 13px;
            line-height: 19px;
            white-space: nowrap;
            text-align: center;
            text-overflow: ellipsis;
            border-radius: 0;
            cursor: pointer;
            opacity: 1;
            border: 1px solid var(--primary-color);
            color: var(--primary-color);
            border-right: none;

            &:first-of-type {
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            }

            &:last-of-type {
              border-right: 1px solid var(--primary-color);
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
            }

            &.disabled {
              cursor: not-allowed;
              color: var(--text-lighter);
              border-color: var(--text-lighter);
            }
          }
        }

        &.single-select-failed {
          .label-single-select-container {
            color: #ff5a5a;
          }
        }
      }

      //&.single-select-component-has-value {
      //  .single-select-failed {
      //    .single-select-option-label {
      //      border: 1px solid rgba(0, 0, 0, 0.2);
      //      color: var(--text-primary);
      //      background-color: unset;
      //    }
      //
      //    .single-select-option-selected {
      //      color: #307fff;
      //      border-color: #307fff;
      //      background-color: #cddcfa;
      //    }
      //  }
      //}
    }

    // CHECKBOX COMPONENT
    &.checkbox-component {
      .checkbox-container {
        .label-checkbox-container {
          display: block;
          margin-bottom: 8px;
          line-height: 21px;
          font-size: 14px;
          font-weight: 400;
          color: var(--text-light);
        }

        .checkbox-option-block {
          display: flex;
          flex-direction: row;

          .checkbox-option-box {
            margin: 3px 6px 3px 0;
            width: auto; //Added a fix for reset style
          }

          .checkbox-option-label {
            &.disabled {
              color: var(--text-lighter);
            }
          }
        }

        &.checkbox-failed {
          .label-checkbox-container {
            color: #ff5a5a;
          }
        }
      }
    }

    // DROPDOWN COMPONENT
    &.dropdown-component {
      .dropdown-container {
        width: 100%;

        .label-dropdown-container {
          display: block;
          margin-bottom: 2px;
          line-height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: var(--text-light);
        }

        .dropdown-options {
          padding: 8px;
          font-size: 14px;
          text-decoration: none;
          border-radius: 4px;
          cursor: pointer;
          background-color: var(--bg-white);
          border: 1px solid var(--border-light);
          box-shadow: none;
          width: 100%;
          overflow: auto;
          list-style: none;
          outline: none;
          box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 3px 0px inset;
        }

        &.disabled {
          cursor: not-allowed;

          .dropdown-options {
            color: var(--text-lighter);
          }

          select {
            cursor: auto;
            pointer-events: none;
          }
        }

        &.dropdown-failed {
          .label-dropdown-container {
            color: #ff5a5a;
          }
        }
      }
    }
  }
  // End CSS code from Messenger
}

// Additional CSS
#messenger-canvas {
  width: 380px;

  .card {
    width: 100%;
    background-color: var(--bg-white);
    border: none;

    .inbox-widget-card {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 15px 0px,
        rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
      padding: 16px 0;

      .gist-card-component {
        padding-left: 16px;
        padding-right: 16px;

        &.data-table-component .data-table .list-item .list-item-field {
          width: auto !important;
        }

        &.single-select-component
          .single-select-container.single-select-failed
          .single-select-option-label {
          background-color: transparent;
        }
      }
    }
  }
}
