@import '../../style/variable';

.float-right {
  float: right;
}
sup.top-menu {
  margin-left: 5px;
  color: white;
  background: var(--primary-color);
  font-weight: 600;
  border-radius: 5px;
  padding: 3px 8px;
  opacity: 1;
  font-size: 11px;
  top: -0.2em;
}
.top-navbar {
  color: var(--header-text-color);
  background: var(--top-nav-bg);
  // Override ant layout header height
  height: 58px;
  line-height: 58px;
  padding: 0 40px;
  .ant-menu-horizontal {
    border: 0 !important;
  }
  // Top navbar menu left
  .left {
    // Project logo
    img {
      height: 45px;
      width: 45px;
      margin: 6px 0px 6px 0;
      float: left;
    }
    // Menu and menu items override
    > .ant-menu {
      background: var(--top-nav-bg);
      color: var(--header-text-color);
      line-height: 55px;
      margin-left: 70px;
      .item {
        border-bottom: none;
        opacity: 1;
        font-weight: 500;
        a {
          color: var(--header-text-color);
          opacity: 1;
        }
        &.ant-menu-item-selected,
        &.ant-menu-submenu-selected {
          border-bottom: 1px solid var(--primary-color);
          color: var(--header-active-text-color);
          opacity: 1;
          background-color: rgba(84, 147, 245, 0.05);
          a {
            color: var(--header-active-text-color);
          }
        }
        &:hover,
        & .ant-menu-submenu-title:hover,
        &.ant-menu-submenu-active,
        a:hover {
          color: var(--header-active-text-color);
          opacity: 1;
        }
        .ant-menu-submenu-title {
          padding-right: 33px;
          &:after {
            content: "\f107";
            font-family: 'Font Awesome 6 Pro', serif;
            display: inline-block;
            margin-left: 8px;
            position: absolute;
            top: 1px;
            font-size: 10px;
            font-weight: 600;
          }
        }
      }
    }
  }
  // Top navbar right side
  .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    white-space: pre;
    > * + * {
      margin-left: 20px;
    }
    img {
      height: 24px;
      width: 24px;
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
    }
    //@extend %three-dot-icon;
    .right-content {
      display: flex;
      align-items: center;
      position: relative;
      min-height: 58px; 
      cursor: pointer;
      .active-status-dot {
        border: 2px solid var(--white);
        height: 10px;
        width: 10px;
        border-radius: 6px;
        position: absolute;
        z-index: 1;
        left: 6px;
        top: 32px;
      }
      span {
        margin: 0px 10px;
        max-width: 250px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

.ant-menu-submenu-popup {
  z-index: 999;
}

.user-profile-dropdown {
  color: var(--text-primary);
  border-radius: 5px;
  box-shadow: 0 3px 10px 0 rgba(35, 58, 132, 0.08);
  border: 1px solid var(--border-light);
  max-width: 250px;

i {
    width: 14px;
    margin-right: 10px;
    }  .ant-avatar {
    width: 50px;
    height: 50px;
    margin-right: 10px;}

  p {
    margin-bottom: 0;
    text-overflow: ellipsis;
    overflow: hidden;}

  .profile {
    display: inline-block;
    vertical-align: middle;

    .profile-name {
      width: 152px;
      text-overflow: ellipsis;
      overflow: hidden;
      color: var(--text-primary);
      font-weight: 600;
    }

    p {
      width: 152px;
      text-overflow: ellipsis;
      overflow: hidden;
      color: var(--text-light);
    }
  }

  div {
    p:first-child {
      font-weight: 600;
      max-width: 200px;
      text-overflow: ellipsis;
    }
  }

  .ant-dropdown-menu-submenu-arrow {
    top: 20px;
  }

  .ant-dropdown-menu {
    min-width: 200px;
  }

  .ant-dropdown-menu-item {
    padding: 10px 15px;
    color: var(--text-primary);

    .progress-title {
      .ant-progress-inner {
        background: var(--search-box-bg);
      }

      h1 {
        color: var(--text-primary);
        font-weight: 600;
        font-size: $font-size-base;
      }

      p {
        color: var(--text-primary);
        font-weight: normal;
        margin-bottom: 0;
      }
    }

    a {
      color: var(--text-primary);
    }
  }
}

.user-profile-submenu {
  max-width: 250px;
  .ant-dropdown-menu-submenu-title {
    padding: 10px 20px;
    color: var(--text-primary);
  }
  .ant-dropdown-menu-item {
    padding: 10px 20px;
    color: var(--text-primary);
  }
  .ant-dropdown-menu-sub {
    li {
      display: flex;
      &:first-child {
        a {
          font-weight: 600;
          color: var(--primary-color);
        }
      }
      a {
        display: flex;
      }
      span {
        max-width: 100px;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: 5px;
      }
    }
  }
}

.navbar-submenu {
  .ant-menu-item-selected > a,
  .ant-menu-item-selected {
    background-color: var(--select-hover-color) !important;
    color: var(--primary-color) !important;
  }
}

.navbar-submenu {
  .ant-menu-item {
    padding: 0px;
    a {
      padding: 0 16px;
    }
  }
}
.addon-modal {
  .ant-modal {
    padding-bottom: unset;
  }
  .ant-modal-body {
    padding: 0;
    overflow: auto;
  }
  .ant-modal-close {
    display: unset;
  }

  .body {
    max-height: 95vh;
    .content {
      padding: 15px 20px;

      .description {
        margin-bottom: 0px;
      }
      .divider {
        margin: 10px 0;
      }
      h3 {
        margin-bottom: 13px;
      }
      .flex-coloumn {
        height: 85%;
        line-height: 1.35;

        > div {
          float: left;
          width: 50%;
          > div {
            margin-bottom: 13px;
          }
        }
        > :nth-child(2) {
          padding-left: 20px;
        }
        h4 {
          margin-bottom: 13px;
        }
        li {
          color: var(--text-primary);
          list-style: none;
          margin-bottom: 10px;

          i {
            font-size: 12px;
            margin-right: 10px;
            font-weight: 600;

            &.fa-check {
              color: var(--primary-color);
            }
          }
        }
      }
    }

    .details {
      padding: 90px 20px;
      background: var(--bg-light);
      border-radius: 5px;

      .fa-arrow-alt-circle-up {
        font-size: 25px;
        color: var(--primary-color);
        padding: 0;
      }
      .heading {
        margin-top: 20px;
        font-weight: 700;
        font-size: 17px;
      }
      button {
        width: 100%;
        background-color: var(--primary-color);
        color: var(--white);
        margin-top: 30px;
      }
      .cost {
        .price {
          font-size: 15px;
          display: flex;
          justify-content: space-between;
        }

        .per {
          font-size: 12px;
        }
        margin-bottom: 10px;
      }
      .light {
        color: var(--text-light);
      }
    }
  }
}
