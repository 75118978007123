%messageComposerPopover {
  height: 260px;
  overflow-y: auto;
}
.popover-container {
  @extend %messageComposerPopover;
  width: 420px;
  .no-content {
    position: relative;
    top: 40%;
    text-align: center;
    font-weight: 500;
  }
  .popover-content {
    cursor: pointer;
    padding: 10px;
    margin-bottom: 0;
    .content-details {
      display: flex;
      div:first-child {
        flex-grow: 1;
      }
      .insert {
        font-weight: 400;
        color: var(--primary-color);
        font-size: 13px;
        display: none;
      }
    }
    &:hover {
      background-color: var(--select-hover-color);
      border-radius: 5px;
      .insert {
        display: block;
      }
    }
    &.active {
      background-color: var(--select-hover-color);
      border-radius: 5px;
    }
    &.inactive {
      background-color:transparent;
    }
  }
  h4 {
    margin-bottom: 0;
    font-size: 13px;
    font-weight: 600;
    color: var(--text-primary);
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
  }
  p {
    margin-bottom: 0;
    font-size: 13px;
    color: var(--label-default-text);
    overflow: hidden;
    max-height: 40px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &.no-articles {
    height: 340px;
    .no-content {
      top: 0;
      padding: 0px 33px 0px 34px;
      img {
        margin-top: 28px;
        margin-bottom: 55px;
      }
      h4 {
        font-size: 16px;
        font-weight: 600;
        color: var(--text-primary);
        text-align: center;
        margin-bottom: 20px;
      }
      p {
        font-size: $font-size-base;
        font-weight: normal;
        margin-bottom: 30px;
      }
    }
  }
}
.snippet-popover {
  .ant-input-affix-wrapper {
    margin-bottom: 15px;
  }
  .popover-column-parent {
    flex-direction: row;
    display: flex;
    .popover-column {
      flex-direction: column;
      flex: 1;
      margin-right: 0;
      max-width: 50%;
      display: flex;
      &:first-of-type {
        border-right: 1px solid var(--gray-400);
      }
      .popover-container {
        padding-right: 20px;
        height: 320px;
        width: 284px;
      }
      .current-snippet-content {
        padding: 5px 5px 10px 20px;
        word-break: break-word;
        overflow-y: auto;
        height: 320px;
        margin-bottom: 5px;
      }
    }
  }
  .ant-popover-content {
    width: 700px;
  }
  .ant-popover-title {
    display: flex;
    align-items: center;
    .left {
      flex: 1;
    }
  }
  .snippet-container-hash {
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    background-clip: padding-box;
    background: var(--bg-white);
    overflow: auto;
    z-index: 1050;
    max-width: 250px;
    display: inline-block;
    max-height: 200px;
    .snippet-hash {
      padding: 5px 16px;
      margin: 0;
      clear: both;
      font-size: $font-size-base;
      font-weight: normal;
      color: var(--text-primary);
      white-space: nowrap;
      cursor: pointer;
      -webkit-transition: all .3s;
      transition: all .3s;
      line-height: 22px;
      &:hover {
        background-color: $select-box-hover-bg;
        border-radius: 5px;
        cursor: pointer;
      }
      & > div {
        overflow: hidden;
        text-overflow: ellipsis;
      }

    }
    .snippet-empty {
      padding: 5px 16px;
    }
  }
}
