@import 'variable';

.settings-sider,
.message-sider,
.support-bot-sider {
  &.ant-layout-sider {
    padding-bottom: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    flex: 0 0 260px !important;
    max-width: 260px !important;
    min-width: 230px !important;
    width: 260px !important;
    border: 1px solid var(--border-gray);

    @media (max-width: 1600px) {
      flex: 0 0 220px !important;
      max-width: 220px !important;
      min-width: 200px !important;
      width: 220px !important;
    }

    .ant-menu-submenu-title,
    .ant-menu-item {
      margin: 0;
      color: var(--text-primary);

      &:hover,
      &:active {
        color: var(--primary-color) !important;
        background: none !important;
      }

      a {
        &:hover {
          color: var(--primary-color);
        }
      }

      &.ant-menu-item-selected {
        font-weight: 500;
        color: var(--primary-color) !important;
        background: none !important;
        a {
          font-weight: 500;
          color: var(--primary-color);
        }
      }
    }

    .heading {
      font-size: 20px;
      color: var(--text-secondary);
      padding: 20px 20px 10px 20px;
      cursor: text;
      font-weight: bold;
      line-height: 1.4;
      letter-spacing: normal;
    }

    .ant-menu {
      background: var(--sidebar-bg-light);
      color: var(--text-primary);
      font-size: 15px;

      .ant-menu-item:hover,
      .ant-menu-submenu-title:hover {
        .right {
          display: inline-block;
        }

        .count {
          display: none;
        }
      }

      .ant-menu-item,
      .ant-menu-submenu-title {
        display: flex;
        align-items: center;
        &.ant-menu-item-selected {
          background: transparent;
          color: var(--text-secondary);
        }
        .fa-folder {
          margin-right: 10px;
          font-size: $font-size-base;
          transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
            margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          color: var(--text-light);
        }
        &.margin-none {
          margin: 0;
        }
        &:hover,
        &:active,
        &.ant-menu-item-active {
          color: var(--text-primary);
          span {
            color: var(--text-primary);
          }
        }
        &.active-menu,
        &:hover {
          color: var(--text-primary);
        }
        &:after,
        &:after {
          border: none;
          transition: none;
        }
        > .title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          flex: 1;
        }
        > .count {
          margin-left: 5px;
          color: var(--text-light);
          &.light {
            opacity: 0.4;
          }
        }
        .ant-menu-submenu-arrow {
          left: 20px;
        }
        > .right {
          display: none;
          flex-shrink: 0;
          span {
            i {
              font-size: $font-size-base;
              color: var(--text-light);
            }
            &:first-child {
              margin-right: 10px;
            }
          }
        }
      }
      // Active the menu title while collapse the submenu and active any of submenu
      .ant-menu-submenu.active-menu {
        .ant-menu-submenu-title {
          color: var(--text-primary);
        }
      }
      .ant-menu-submenu {
        &.ant-menu-submenu-selected {
          .ant-menu-submenu-arrow {
            color: var(--primary-color) !important;
          }
        }
        &.ant-menu-submenu-selected:not(.ant-menu-submenu-open) {
          background: transparent !important;
        }
        .ant-menu-submenu-title {
          padding-right: 17px;
          color: var(--text-primary);
          padding-left: 38px !important;
          font-size: $font-size-base;
          font-weight: 500;
        }
      }
      &:not(.ant-menu-horizontal) {
        .ant-menu-item-selected {
          background: $select-box-hover-bg;
          color: var(--text-primary);
        }
      }
    }
    .no-data {
      padding: 100px 20px 20px;
      text-align: center;
    }
    .folder-action {
      padding-left: 20px;
      margin: 5px 0px 20px;
      .add-folder {
        background: none;
        border: none;
        box-shadow: none;
        color: var(--primary-color);
        padding: 0;
        &::after {
          background: var(--primary-color);
          span {
            color: var(--text-white);
          }
        }
      }
    }
    .ant-menu-item-divider {
      margin: 0 20px 0;
      width: 80%;
      min-width: 80%;
    }
  }
}

.settings-sider {
  // align the submenus and menus in same vertical spacing
  &.ant-layout-sider {
    .ant-menu-submenu-title,
    .ant-menu-item {
      padding-left: 38px !important;
      padding-right: 20px !important;
    }
    .ant-menu {
      .sub-menu-title {
        padding: 20px 20px 5px 20px;
        font-weight: 600;
      }
    }
    .ant-menu .ant-menu-submenu .ant-menu-sub {
      .ant-menu-item {
        padding: 11px 38px;
        a {
          font-size: 13px;
        }
      }
    }
  }
}

.support-bot-sider {
  .ant-layout-sider-children {
    overflow: hidden;

    .separate-line {
      margin: 10px 0;
      background: var(--bg-gray);
    }

    h4 {
      padding: 5px 20px 0 24px;
    }

    .ant-menu-item {
      font-size: 13px;
      height: 36px;
      line-height: 36px;
    }
  }
}

.header-container {
  padding: 0 20px;
  width: 100%;

  .header-content {
    display: flex;
    position: relative;
    flex-direction: column;
    margin: 12px 0 0;

    .header-inner {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      min-height: 40px;

      .title {
        display: flex;
        flex-basis: 0;
        flex-grow: 1;
        align-items: center;
        margin-bottom: 10px;

        .heading {
          font-size: 24px;
          font-weight: 500;
          margin: 0;
          max-width: 100%;
          margin-bottom: 2px;
          padding-right: 0;
        }
      }

      .description {
        display: flex;
        flex-grow: 1;
        align-items: flex-start;
        justify-content: space-between;
        max-width: 100%;
        width: 100%;
        font-size: 15px;
        margin-bottom: 10px;
      }
    }
  }

  .tabs-container {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    width: 100%;

    .tabs-list-wrapper {
      max-width: 100%;
      position: relative;

      .tabs-list {
        display: inline-flex;
        width: 100%;
        margin-bottom: -1px;
        border: 1px solid rgb(203, 214, 226);
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        border-bottom: none;

        .tab-list-item {
          font-weight: 500;
          font-size: 16px;
          padding: 12px 28px;
          color: rgb(51, 71, 91);
          position: relative;
          white-space: nowrap;
          text-align: center;
          width: 100%;
          transition: background-color 200ms cubic-bezier(0.25, 0.1, 0.25, 1) 0s;
          background-color: rgb(255, 255, 255);
          border-left: 1px solid rgb(203, 214, 226);
          border-bottom: solid 1px #dcdee5;

          &:first-child {
            border-left: 0px;
            border-top-left-radius: inherit;
          }

          &:hover {
            cursor: pointer;
          }

          &:not(.active):hover {
            color: #737376;
          }

          &.active {
            background-color: #f4f4f4 !important;
            border-bottom: none !important;
          }

          .tab-list-item-inner {
            display: flex;
            flex-grow: 1;
            align-items: center;
            justify-content: flex-start;
            max-width: 100%;
            width: 100%;

            img {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}