@import './variable';

@mixin prefixer($property, $val) {
  -webkit-#{$property}: $val;
  -moz-#{$property}: $val;
  -ms-#{$property}: $val;
  #{$property}: $val;
}

//Profile avatar styles
@mixin profileContainer($height, $width, $fontSize) {
  height: $height;
  width: $width;
  border-radius: 50%;
  font-size: $fontSize;
  font-weight: 500;

  img {
    width: inherit;
    height: inherit;
    object-fit: cover;
    object-position: center;
    @include prefixer(border-radius, $height);
  }
}

%editor-image {
  display: block;
  width: 100%;
  max-width: $chat-message-image-max-width;
}

%link {
  color: var(--primary-color);
  cursor: pointer;
}

%light-btn {
  &, &:focus {
    color: var(--text-primary);
    background: var(--bg-light);
  }
  &:active, &:hover {
    color: var(--light-btn-text-hover);
    background-color: var(--light-btn-bg-hover);
  }
}

%tag-list {
  display: flex;
  align-items: center;
  cursor: default;
  font-size: 13px;
  border: 1px solid var(--black-opacity-bg);
  color: var(--text-light);
  max-width: 100%;
  margin: 0;
  padding: 2px 8px;
  border-radius: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;

  i {
    color: $icon-color-light;
  }
}