@import '../../style/variable';
@import '../../style/mixin';

.content {
  height: 100%;

  .ant-row {
    height: 100%;

    .ant-col {
      height: 100%;

      .toolbar {
        width: 100%;
        background: var(--bg-white);
        padding: 10px 25px;
        text-align: right;
      }

      .react-codemirror2 {
        height: 100%;

        .CodeMirror {
          height: 100% !important;

          .CodeMirror-cursor {
            border-left: 1px solid #ea1b1b;
          }

          .CodeMirror-scroll {
            height: 100%;

            .CodeMirror-gutters {
              height: 100% !important;
            }
          }
        }
      }
    }
  }
}

.canvas-header {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 15px;
  border-left: 1px solid var(--bg-gray);

  span {
    color: var(--text-light);
  }

  .canvas-tabs {
    gap: 10px;
    display: flex;

    .tab-switcher {
      position: relative;
      display: inline-block;
      box-sizing: border-box;
      vertical-align: bottom;
      margin: 0;
      border: 0;
      background-color: transparent;
      cursor: pointer;
      padding: 14px 10px;

      &.active {
        border-bottom: 2px solid var(--primary-color);
      }
    }
  }
}

.canvas-preview {
  overflow-y: auto;
  height: 100%;
  background: var(--alert-default-bg);

  .canvas {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 16px 0;

    .card {
      background: var(--bg-white);
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      box-sizing: border-box;
      box-shadow: 0 0 0 transparent;
      transition: box-shadow linear 40ms, border-color linear 0.2s;
      position: relative;
      width: 360px;

      .card-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 12px;

        i {
          color: #334bfa;
        }

        .app-icon {
          width: 15px;
          height: 15px;
          outline: 0;
          background-size: cover;
        }

        .app-name {
          padding-left: 10px;
          font-weight: 600;
        }
      }

      .card-body {
        .inbox-widget-canvas .inbox-widget-card .gist-card-component {
          [data-action-type='submit'] {
            pointer-events: none;
          }
        }
      }
    }
  }
}

.add-block-modal {
  .add-block-modal-header {
    display: flex;
    align-items: center;

    .back {
      cursor: pointer;
      margin-bottom: 8px;
      margin-right: 10px;
    }
  }

  .link {
    @extend %link;
    display: block;
    margin-bottom: 20px;
  }

  .delete {
    cursor: pointer;
    height: 35px;
    display: flex;
    align-items: center;
  }

  .container {
    display: flex;
    flex-direction: row;

    .scrollable {
      height: 100%;
      max-height: 800px;
      overflow-y: auto;
    }

    .list-item-block {
      border: 1px solid var(--border-gray);
      border-radius: 6px;
      padding: 10px;
      margin-bottom: 10px;
    }

    .inbox-widget-canvas {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      background-color: var(--border-gray);
      padding: 16px 0;

      .inbox-widget-card {
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        box-sizing: border-box;
        box-shadow: 0 0 0 transparent;
        position: relative;
        padding: 12px 0;
        width: 260px;
      }
    }
  }

  label {
    i {
      padding-left: 8px;
    }
  }

  .block-container {
    .block {
      padding: 10px 20px;
      width: 100%;
      border: 1px solid var(--border-gray);
      border-radius: 6px;
      margin-bottom: 10px;
      cursor: pointer;

      &:hover {
        border: 1px solid var(--primary-color);
      }
    }
  }
  .ant-modal {
    .ant-modal-body {
      .ant-form-item:last-of-type {
        margin-bottom: 20px;
      }
    }
  }
}
