@import '../../style/variable';

.feedback-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;

  .email-feedback-content {
    margin-bottom: 40px;

    h1 {
      font-size: 16px;
      font-weight: bolder;
      color: $gray-500-dark;

    }

    h2 {

      font-size: 25px;
      margin-bottom: 1.5rem;
      color: var(--primary-color);
    }
    .divider {
      margin: 24px auto;
      border: 1px solid var(--border-gray);
      width: 120px;

    }

    p {
      font-size: 1rem;
      color: $gray-500-dark;
      line-height: 12px;
    }
  }
  .branding {
    position: absolute;
    bottom: 20px;
  }
}

