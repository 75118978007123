// ======================================================================================= //

$themes: (
  'dark-theme': (
    'colors': (
      'white': $white-dark,
      'white-rgb': $white-dark-rgb,
      'gray-100': $gray-100-dark,
      'gray-200': $gray-200-dark,
      'gray-300': $gray-300-dark,
      'gray-400': $gray-400-dark,
      'gray-500': $gray-500-dark,
      'gray-600': $gray-600-dark,
      'gray-700': $gray-700-dark,
      'black': $black-dark,
      'black-rgb': $black-dark-rgb,
      'text': $text-dark,
      'top-nav-bg': #040404,
      'dropdown-bg': $gray-100-dark,
      'header-text-color': $gray-600-dark,
      'file-uploader-border': 1px solid $gray-400-dark,
      'text-primary': $text-dark,
      // primary text color
      'text-secondary': $gray-600-dark,
      // major text color
      'text-light': $gray-700-dark,
      // light text color
      'text-black': $gray-700-dark,
      'table-header-bg': #181818,
      'table-header-hover-bg': $gray-100-dark,
      'header-active-text-color': $black-dark,
      'conversation-agent-message-bg': #2c2d30,
      'conversation-agent-message-text': #f7f8f8,
      'conversation-person-message-bg': #17181b,
      'conversation-person-message-text': $text-dark,
      'conversation-bot-message-bg-color': #353535,
      'conversation-bot-message-text': #bdbdbd,
      'conversation-note-message-bg': #201c15,
      'conversation-note-message-text': $text-dark,
      'conversation-note-editor-placeholder-text': #b2b2b2,
      'conversation-note-message-mention-bg': rgba(255, 178, 29, 0.4),
      'conversation-composer-edit-mode-bg-color': #2b2b2b,
      'conversation-selected-bg-color': rgb(21, 22, 24),
      'conversation-hover-bg-color': rgba(23, 24, 27, 0.5),
      'skeleton-bg': $gray-200-dark,
      'skeleton-active-bg':
        linear-gradient(
          90deg,
          var(--gray-200) 25%,
          #0f0f10 37%,
          var(--gray-200) 63%
        ),
      'form-editor-sidebar-bg': $gray-200-dark,
      'segment-preview-bg': $gray-200-dark,
      'select-hover-color': rgba(32, 32, 32, 0.5),
      'select-selected-color': #181818,
      'popover-bg': $gray-200-dark,
      'scroll-transparent-bg':
        linear-gradient(0deg, $white-dark, rgba(255, 255, 255, 0)),
      'deals-column-border': $gray-300-dark,
      'deal-expired-bg': #221b1b,
      'primary-color': #0f75d4,
      'primary-color-hover': #66adf0,
      'success-color': $green-500,
      'error-color': $red-600,
      'light-btn-bg-hover': #212f4d,
      'light-btn-text-hover': #6e9eff,
      'light-blue-color': $gray-400-dark,
      'tour-step-hover-color': $gray-200-dark,
      'tour-connector-stroke-color': $gray-400-dark,
      'tour-canvas-color': $gray-100-dark,
      'tour-column-border': $gray-400-dark,
      'green-400': $green-400,
      'orange-500': $orange-500,
      'ai-answer-background': #353535,
      'bot-builder-button-text': $text-dark,
      'bot-builder-button-bg-color': rgba(131, 131, 173, 0.36),
      'shadow-light': $shadow-light,
    ),
  ),
  'light-theme': (
    'colors': (
      'white': $white-light,
      'white-rgb': $white-light-rgb,
      'gray-100': $gray-100-light,
      'gray-200': $gray-200-light,
      'gray-300': $gray-300-light,
      'gray-400': $gray-400-light,
      'gray-500': $gray-500-light,
      'gray-600': $gray-600-light,
      'gray-700': $gray-700-light,
      'black': $black-light,
      'black-rgb': $black-light-rgb,
      'text': $text-light,
      'top-nav-bg': #00162d,
      'header-text-color': #d5d5d5,
      'header-active-text-color': $white-light,
      'dropdown-bg': $white-light,
      'file-uploader-border': 1px solid $gray-400-light,
      // TODO
      'text-primary': $dark-blue-700,
      'text-secondary': $dark-blue-500,
      'text-light': $gray-700-light,
      // light text color
      'text-black': $black-light,
      'table-header-bg': #f9f9fb,
      'table-header-hover-bg': #f0f0f0,
      'conversation-agent-message-bg': $blue-200,
      'conversation-agent-message-text': $dark-blue-700,
      'conversation-person-message-bg': $gray-400-light,
      'conversation-person-message-text': $dark-blue-500,
      'conversation-bot-message-bg-color': $gray-200-light,
      'conversation-bot-message-text': $dark-blue-500,
      'conversation-note-message-bg': $yellow-300,
      'conversation-note-message-text': $dark-blue-500,
      'conversation-note-editor-placeholder-text': $gray-600-light,
      'conversation-note-message-mention-bg': rgba(255, 178, 29, 0.4),
      'conversation-composer-edit-mode-bg-color': #fdfbeb,
      'conversation-selected-bg-color': rgb(241, 246, 255),
      'conversation-hover-bg-color': rgba(232, 234, 236, 0.35),
      'skeleton-bg': #f2f2f2,
      'skeleton-active-bg':
        linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%),
      'form-editor-sidebar-bg': $white-light,
      'segment-preview-bg': $gray-400-light,
      'select-hover-color': rgba(232, 234, 236, 0.35),
      'select-selected-color': #edf2ff,
      'popover-bg': $white-light,
      'scroll-transparent-bg':
        linear-gradient(0deg, rgb(255, 255, 255), rgba(255, 255, 255, 0)),
      'deals-column-border': #dfe3eb,
      'deal-expired-bg': #fff9f9,
      'primary-color': $primary-color,
      'primary-color-hover': $primary-color-hover,
      'success-color': $green-500,
      'error-color': $red-600,
      'light-btn-bg-hover': $blue-300,
      'light-btn-text-hover': $blue-700,
      'light-blue-color': #eef7fe,
      'tour-step-hover-color': #fbfdff,
      'tour-connector-stroke-color': #e1e9fa,
      'tour-canvas-color': #eaeaea,
      'tour-column-border': #dadada,
      'green-400': $green-400,
      'orange-500': $orange-500,
      'ai-answer-background':
        linear-gradient(
          90deg,
          rgba(248, 253, 255, 1) 0%,
          rgba(248, 246, 255, 1) 100%
        ),
      'bot-builder-button-text': #041595,
      'bot-builder-button-bg-color': rgba(4, 21, 149, 0.08),
      'shadow-light': $shadow-light,
    ),
  ),
);

// Global colors
@each $theme-name, $theme-vars in $themes {
  // Custom colors
  $colors: map-get($theme-vars, 'colors');

  // Common colors
  $theme: (
    'text-white': map-get($colors, 'white'),
    'bg-white': map-get($colors, 'white'),
    'border-white': map-get($colors, 'white'),
    'text-primary': map-get($colors, 'text'),
    'icon-color-dark': map-get($colors, 'gray-500'),
    'label-default-bg': map-get($colors, 'gray-300'),
    'label-default-text': map-get($colors, 'gray-700'),
    'alert-default-bg': map-get($colors, 'gray-300'),
    'alert-default-text': map-get($colors, 'gray-700'),
    'sidebar-bg-light': map-get($colors, 'gray-200'),
    'select-box-active': map-get($colors, 'gray-100'),
    'input-light-bg': map-get($colors, 'gray-300'),
    'ace-text-layer': map-get($colors, 'gray-200'),
    'editor-field-border-color': map-get($colors, 'gray-300'),
    'panel-btn-border-color': map-get($colors, 'gray-300'),
    'composite-property-color': map-get($colors, 'gray-200'),
    'file-uploader-bg': map-get($colors, 'gray-200'),
    'kb-editor-font-color': map-get($colors, 'text'),
    'bg-lightest-gray': map-get($colors, 'gray-100'),
    'bg-lighter': map-get($colors, 'gray-200'),
    'bg-light': map-get($colors, 'gray-300'),
    'bg-gray': map-get($colors, 'gray-400'),
    'bg-dark': map-get($colors, 'gray-500'),
    'bg-darker': map-get($colors, 'gray-700'),
    'border-light': map-get($colors, 'gray-300'),
    'border-gray': map-get($colors, 'gray-400'),
    'border-dark': map-get($colors, 'gray-500'),
    'border-darker': map-get($colors, 'gray-700'),
    'input-box-border': map-get($colors, 'gray-400'),
    'profile-picture-bg': map-get($colors, 'gray-300'),
    'dark-opacity-bg': rgba(map-get($colors, 'gray-500'), $default-bg-opacity),
    'white-opacity-bg': rgba(map-get($colors, 'white'), $default-bg-opacity),
    'black-opacity-bg': rgba(map-get($colors, 'black'), $default-bg-opacity),
    'search-box-bg': transparent,
  );

  .#{$theme-name} {
    // define a css variable for each color
    @each $name, $color in $colors {
      --#{$name}: #{$color};
    }

    // define a css variable for each theme color
    @each $name, $color in $theme {
      --#{$name}: #{$color};
    }
  }
}
