@import '../../../style/variable';
@import '../../../style/mixin';
@import '../../../style/contact-modal';

%profile {
  @include prefixer(display, inline-flex);
  align-items: center;
  width: 24px;
  height: 24px;
  justify-content: center;
  border-radius: 30px;
  font-size: 13px;
  background-color: var(--bg-light);
}

.contacts-layout {
  .custom-table-header {
    margin: 10px 0;

    .btn-secondary-outline.btn-sm {
      padding: 5px 15px;
    }

    .left,
    .right {
      height: 42px;
      display: inline-flex;
      align-items: center;
    }

    .right {
      float: right;
    }

    .item {
      display: inline-block;
      color: var(--text-secondary);
      font-weight: 400;
      font-size: 18px;

      & + .item {
        margin-left: 15px;
      }

      .recipients-count {
        margin-right: 5px;
        font-size: 17px;
        font-weight: bold;
      }

      .total-people-count {
        font-size: 13px;
      }
    }

    .ant-dropdown-trigger {
      background: transparent;
      border-color: var(--icon-color-dark);
      color: var(--text-secondary);
      font-weight: 500;
      padding: 0 10px;

      &:hover,
      &:focus {
        border-color: var(--primary-color) !important;
        color: var(--primary-color);
      }
    }
    .ant-btn-default {
      background-color: var(--search-box-bg);
    }

    .search {
      .ant-input {
        width: 340px;
      }
    }
  }

  .user-list-table {
    table {
      .ant-avatar-lg {
        width: 28px !important;
        height: 28px !important;
        margin-right: 10px !important;
      }

      a {
        font-size: $font-size-base !important;
        font-weight: 500 !important;
        color: var(--primary-color) !important;
      }
    }

    .ant-table-tbody {
      background: var(--bg-white) !important;

      td {
        word-break: unset;
      }
    }

    .ant-table-thead {
      background: var(--table-header-bg) !important;

      th {
        white-space: nowrap;
      }
    }
  }

  th {
    .ant-table-column-sorters {
      display: flex !important;
      align-items: center !important;

      .ant-table-column-title {
        text-overflow: ellipsis !important;
        overflow: hidden !important;
        text-transform: capitalize !important;
      }

      .ant-table-column-sorter {
        flex-shrink: 0 !important;
      }
    }
  }
}

.ant-input-search-icon {
  display: none;
}
.trigger-select-dropdown {
  .ant-select-dropdown-menu {
    max-height: 400px;
  }
  border-radius: 5px;
  box-shadow: 0 3px 10px 0 rgba(35, 58, 132, 0.08);
  background: var(--bg-white);
  .add-tag {
    cursor: pointer;
    font-weight: 600;
    .btn-link {
      .tag-name {
        color: var(--text-black);
      }
      .fas.fa-tag {
        margin-right: 8px;
      }
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 8px;
      width: 100%;
      text-align: left;
      color: var(--primary-color);
      font-weight: 500;
    }
    .btn-link:hover {
      background-color: ghostwhite;
    }
  }
}
.edit-contact-modal,
.apply-contacts-tag-modal {
  @extend %contact-modal;
  .ant-modal-body {
    .ant-select {
      .ant-select-selection--multiple {
        padding: 3px;
        border: 1px solid #d9d9d9;
      }
    }
  }
}

.add-contact-modal-wrap {
  z-index: 1001;
}

.add-contact-modal {
  .ant-modal-body {
    .ant-form-item {
      margin-bottom: 15px;
    }

    .label {
      font-size: 14px;
      font-weight: 500;
    }

    .ant-select {
      width: 190px !important;
    }

    .ant-select-selection {
      width: 190px !important;
      border-radius: 3px !important;
      border: 1px solid var(--input-box-border);
      padding: 0 12px;
    }
  }
}

.column-layout {
  display: flex;
  flex-direction: row;
  width: 600px;
  height: 470px;
  border-radius: 5px;
  box-shadow: 0 3px 10px 0 #233a8414; // TODO
  background: var(--bg-white) !important;

  .column-sorter-container {
    flex: 40%;
    .heading {
      font-weight: bold;
      padding: 15px 15px 10px 15px;
    }
    .column-sorter-list {
      overflow-y: auto;
      height: 425px;
      padding: 0 15px;
      .column {
        border-radius: 3px;
        background-color: var(--bg-lighter);
        display: block;
        margin-top: 10px;
        padding: 8px 12px;
        cursor: grab;
        &:first-of-type {
          margin-top: 0;
        }
        &:last-of-type {
          margin-bottom: 10px;
        }
        &.column-order {
          display: flex;
        }
        .inner-draggable-block {
          display: flex;
          justify-content: space-between;
          width: 100%;
          i {
            top: 4px;
            position: relative;
          }
        }
        div {
          font-size: 13px;
          line-height: 1.54;
          color: var(--text-secondary);
          display: inline-block;
          margin-bottom: 0;
        }
        .drag-drop-icon {
          display: inline-block;
          i {
            font-size: 15px;
            color: $icon-color-light;
            margin-right: 10px;
            vertical-align: middle;
          }
        }
        i svg {
          width: 11px;
          cursor: pointer;
        }
      }
    }
  }
  .column-selector-container {
    flex: 60%;
  }
  .footer {
    border: solid 1px 1px solid var(--border-light);
    background-color: var(--bg-lightest-gray);
    padding: 10px 25px;

    p {
      margin: 0;
    }
  }

  .ant-layout-sider {
    background-color: rgba(238, 240, 245, 0.3);

    .display-order {
      font-size: 18px;
      color: var(--text-primary);
      padding: 14px;
      opacity: 1 !important;
      background-color: rgb(255, 255, 255);
    }

    [class*='fa-'] {
      font-size: 12px !important;
    }

    .column-order {
      font-size: 15px;
      color: var(--text-secondary);
      padding: 8px !important;
      border-radius: 3px;
    }

    .column-order:hover {
      .close-icon {
        visibility: visible;
        float: right;
        margin-top: 4px;
        cursor: pointer;
        color: var(--text-light) !important;
      }
    }

    .close-icon {
      visibility: hidden;
    }
  }

  .column-selection {
    padding: 0 15px;
    background: var(--bg-white) !important;
    border-radius: 5px !important;

    .ant-tabs-tab {
      padding: 16px !important;
      font-weight: 500;
    }

    .header-contact-column {
      margin: 10px 0 0;

      .header-contact-heading {
        color: var(--text-light);
        margin: 12px 0;
      }
    }
    .column-search{
      .search {
        margin: 0px 2px 20px;
        .ant-input {
          height: 36px;
          }
        }
      }
    .our-traits {
      margin: 0;
      max-height: 400px !important;
      overflow-y: auto;

      .our-traits-heading {
        font-weight: bold;
        margin: 12px 0;
        &:first-of-type {
          margin-top: 0;
        }
      }
     .our-traits-heading-none {
        display: none;
      }

      .ant-checkbox-wrapper {
        color: var(--text-secondary) !important;
        margin-bottom: 12px;
        text-transform: capitalize;
      }

      .no-property {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 310px;
        font-weight: 500;
        text-transform: capitalize;
      }
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: var(--primary-color) !important;
      border-color: var(--primary-color) !important;
    }
  }
}
.ant-table-column-sorters{
  justify-content: center;
}
.contacts-cells{
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}
.contacts-cell {
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 125px;
  max-width: 350px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  word-wrap: break-word;

  a{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .link {
    @extend %link;
  }

  .ant-avatar {
    width: 28px;
    height: 28px;
  }

  & > img,
  .no-image-profile {
    width: 28px;
    height: 28px;
  }

  & > img {
    border-radius: 50%;
  }
}

//.ant-table-fixed {
//  border: 1px solid var(--border-gray) !important;
//}
//
//.ant-table-body {
//  .ant-table-fixed {
//    border-top: none !important;
//  }
//}
//
//.ant-table-fixed-left {
//  .ant-table-fixed {
//    border-right: none !important;
//  }
//}

.contacts-profile {
  display: inline-flex;
  align-items: center;
  width: 28px;
  height: 28px;
  justify-content: center;
  background-color: var(--profile-picture-bg);
  border-radius: 28px;
  font-size: 12px;
  flex-shrink: 0;
}

.contacts-column {
  td,
  th {
    padding: 12px 16px !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  td {
    .ant-tag {
      border: solid 1px var(--border-gray);
      color: var(--text-light);
      background: var(--bg-lighter);
      display: inline;
    }
  }
}

//create contact footer buttons styles
.create-contact-footer {
  border-top: 1px solid var(--border-gray);
  padding: 15px 0 5px;
}

.if-not-full-width {
  display: none !important;
}

@media screen and (max-width: 1270px) {
  .contacts-layout {
    .custom-table-header {
      .search {
        .ant-input {
          width: 190px;
        }
      }

      .ant-btn {
        padding-right: 10px;
        padding-left: 10px;
      }

      .if-full-width {
        display: none;
      }
      .if-not-full-width {
        display: inline !important;
      }
    }
  }
}

.export-drawer {
  .ant-drawer-content-wrapper {
    .heading {
      font-size: 18px;
      font-weight: bold;
      color: var(--text-primary);
    }

    .email-form-item {
      margin-bottom: 20px !important;
    }

    .ant-form-item {
      margin-bottom: 5px;

      label {
        font-weight: 600;
        color: var(--text-primary);
        font-size: $font-size-base;
        margin-bottom: 5px !important;
      }
    }

    .show-property {
      width: 100%;

      .ant-radio-wrapper {
        margin-right: 0;

        .ant-radio {
          margin-right: 15px;
        }

        .heading {
          font-size: 15px;
          font-weight: 500;
          margin-bottom: 3px;
          color: var(--text-secondary);
        }

        display: flex;
        align-items: center;
        border-radius: 5px;
        border: 1px solid var(--border-light);
        padding: 16px 20px;
        margin-bottom: 5px;
      }

      .ant-radio-wrapper-checked {
        border: 1px solid var(--primary-color);
        background: var(--light-blue-color);
      }
    }

    p {
      color: var(--text-primary);
      margin-top: 15px;
      font-weight: normal;
    }

    .email-label {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .email {
        font-weight: 600;
        color: var(--text-primary);
        font-size: $font-size-base;
      }

      p {
        font-size: 13px;
        margin-bottom: 4px;
      }
    }

    .export-btn {
      margin-right: 10px;
    }

    .ant-select-selection {
      .no-image-profile {
        margin-right: 10px;
        @extend %profile;
      }
    }
  }
}

.export-dropdown {
  .no-image-profile {
    margin-right: 0;
    @extend %profile;
  }
}
.additional-section {
  .tag {
    .tag-name {
      max-width: 100%;
      min-width: 20px;
      margin-right: 3px;
    }
  }
}
