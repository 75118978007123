@import '../../style/variable';

%input-element {
  color: var(--text-secondary);
  border-radius: 4px;
  height: auto;
  border: 1px solid var(--border-light);
  box-shadow: none !important;
  font-size: $font-size-base;
  &:hover,
  &:active {
    border: 1px solid var(--primary-color) !important;
  }
}

%footer {
  position: fixed;
  bottom: 30px;
  text-align: center;
  background: var(--bg-white);
  padding: 20px;
  width: 100%;
  z-index: 2;
  p {
    margin-bottom: 2px;
    color: var(--text-light);
  }
  a {
    color: var(--primary-color);
    text-decoration: underline;
  }
}

.iso404Page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  .iso404Content {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;

    h1 {
      font-size: 84px;
      font-weight: 700;
      color: var(--text-light);
      line-height: 1;
      margin: 0 0 25px;
    }

    h3 {
      font-size: 24px;
      font-weight: 400;
      color: var(--text-secondary);
      margin: 0 0 10px;
      line-height: 1.2;
    }

    p {
      font-size: $font-size-base;
      font-weight: 400;
      color: var(--text-light);
      margin: 0 0 10px;
    }
  }
  .iso404Artwork {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 100px;
    margin-right: inherit;
    height: 500px;
    img {
      max-height: 100%;
    }
  }
}

.email-options {
  display: flex;
  height: inherit;
  width: inherit;
  background: var(--bg-white);
  align-items: center;
  flex-direction: column;
  justify-content: center;
  .branding {
    margin-bottom: 300px;
    .logo {
      width: 100px;
      height: 100px;
    }
  }
  .ant-spin {
    position: absolute;
    left: calc(50% - 50px);
    display: inline-block;
    top: 45%;
  }
}

.forgot-password-layout {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: inherit;
  width: inherit;
  align-items: center;
  background: var(--bg-white);

  .forgot-password {
    width: 450px;
    padding: 70px 0;

    .branding {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;

      .logo {
        width: 60px;
        height: 55px;
        margin-right: 8px;
      }

      h1 {
        font-weight: bold;
        margin-bottom: 0;
        font-size: 30px;
      }
    }

    h1 {
      font-size: 24px;
      font-weight: bold;
      color: var(--text-primary);
      margin-bottom: 20px;
    }

    p {
      color: var(--text-light);
      margin-bottom: 40px;
    }

    .ant-form-item {
      margin-bottom: 30px;

      label {
        color: var(--text-primary);
        font-weight: 600;
      }

      .ant-form-explain {
        text-align: left;
      }
    }

    .btn-sm {
      font-size: 16px;
      font-weight: 600;
      width: 100%;
      padding: 14px;
      margin-bottom: 13px;
    }

    .link {
      color: var(--text-secondary);
      font-weight: 600;
    }
  }

  footer {
    @extend %footer;
  }
}

.change-password-layout {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: inherit;
  width: inherit;
  align-items: center;

  .change-password {
    width: 450px;
    padding: 50px 0;
    .branding {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;
      .logo {
        width: 60px;
        height: 55px;
        margin-right: 8px;
      }
      h1 {
        font-weight: bold;
        margin-bottom: 0;
        font-size: 30px;
      }
    }
    h1 {
      font-size: 24px;
      font-weight: bold;
      color: var(--text-primary);
      margin-bottom: 20px;
    }
    p {
      color: var(--text-light);
      margin-bottom: 40px;
    }
    form {
      .ant-form-item:first-child {
        margin-bottom: 5px;
        padding-bottom: 0;
      }
      .ant-form-item {
        margin-bottom: 30px;
        label {
          color: var(--text-primary);
          font-weight: 600;
        }
        .ant-form-explain {
          text-align: left;
        }
        .ant-input-password {
          .ant-input {
            @extend %input-element;
            font-size: $font-size-base;
            padding: 11px 30px 11px 12px;
          }
        }
      }
      .btn-sm {
        font-size: 16px;
        font-weight: 600;
        width: 100%;
        padding: 14px;
        margin-bottom: 13px;
      }
      .link {
        color: var(--text-secondary);
        font-weight: 600;
      }
      .form-text {
        text-align: left;
        color: var(--text-light);
        font-size: 13px;
        font-weight: normal;
      }
    }
  }
  footer {
    @extend %footer;
  }
}

.project-blocked-layout,
.not-authorised-layout {
  background: var(--bg-white);
  height: 100%;
  width: 100%;
  overflow: hidden;
  &.white {
    background: var(--bg-white);
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
    div {
      background: var(--bg-white);
      padding: 50px;
      border-radius: 5px;
      width: 630px;
      font-size: 16px;
      hr {
        border-top: 1px solid var(--gray-500);
        height: 0;
        margin: 20px 0;
      }
      h1 {
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 20px;
        color: var(--text-secondary);
      }
      .link {
        color: var(--primary-color);
        cursor: pointer;
      }
      .owner-email {
        font-weight: bold;
      }
      p {
        color: var(--text-primary);
      }
    }
  }
}
.satisfaction-rating {
  &.success {
    .ant-card-body {
      padding: 40px 80px !important;
      .success-text {
        font-size: 18px;
        font-weight: 500;
      }
      .thumb {
        font-size: 40px;
        margin-top: 10px;
      }
    }
  }
  background: var(--select-box-active);
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  .rating-card {
    text-align: center;
    padding: 0 10px;
    .greeting-text {
      margin-top: 25px;
      margin-bottom: 40px;
      font-size: 17px;
      font-weight: 500;
      color: var(--text-light);
    }
    .ant-card {
      border: 1px solid var(--border-gray);
      border-radius: 5px;
      background: var(--bg-white);
      box-shadow: 0 1px 4px 0 rgba(71, 110, 136, 0.12);
      .ant-card-body {
        padding: 30px;
        & > span {
          font-size: 17px;
          font-weight: 500;
          color: var(--text-light);
        }
        .rating-emoji {
          margin-bottom: 25px;
          margin-top: 10px;
          img {
            width: 30px;
            height: 30px;
          }
          span {
            &:not(:first-child) {
              margin-left: 30px;
            }
            &.unselected {
              filter: grayscale(1);
              &:hover {
                filter: drop-shadow(1px 1px 4px #999595) !important;
                cursor: pointer;
                color: var(--text-white);
              }
            }
            &.selected {
              filter: drop-shadow(1px 1px 4px #999595) !important;
              cursor: pointer;
              color: var(--text-white);
            }
          }
        }
        .form {
          display: flex;
          flex-direction: column;
          align-items: center;
          label {
            font-size: $font-size-base;
            font-weight: 600;
            color: var(--text-light);
            margin-bottom: 15px;
          }
          textarea {
            max-width: 537px;
          }
        }
        button {
          margin-top: 15px;
        }
      }
    }
  }
}

.unsubscription-container {
  background: var(--select-box-active);
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding: 30px 20px;
  overflow: auto;

  .unsubscription-container-inner {
    width: 100%;
    max-width: 700px;

    .unsubscription-header {
      text-align: center;
      .border-line {
        margin: 15px 0 15px;
        border-bottom: 1px solid var(--border-dark);
      }
    }

    .unsubscription-card {
      background: var(--bg-white);

      .no-active-subscription {
        text-align: center;
      }

      .form {
        .ant-form-item {
          padding-bottom: 15px;

          &:not(:last-of-type) {
            border-bottom: 1px solid var(--border-gray);
          }

          .ant-form-item-control {
            .ant-form-item-children {
              .form-item {
                display: flex;
                flex-direction: row;

                .ant-checkbox {
                  height: max-content;
                  margin-top: 6px;

                  & + span {
                    display: flex;
                    flex-direction: column;
                    font-size: 1.2em;
                    font-weight: 600;
                    padding-left: 15px;
                  }
                }
              }

              p {
                font-weight: 400;
                margin-top: 4px;
                margin-bottom: 0;
              }
            }
          }
        }

        button {
          width: 100%;
          max-width: 300px;
          margin: 0 auto;
          display: block;
        }
      }
    }

    .unsubscription-footer {
      margin-top: 10px;
      text-align: center;

      .unsubscribe-all-btn {
        text-align: center;
        font-size: 15px;
        margin-bottom: 12px;
        border: none;
      }
    }
    .unsubscribe-alert-message {
      margin-bottom: 20px;
      border-radius: 3px;
      padding: 10px 15px;
      text-align: center;
      background: #d1e7d1;
      color: #024802;
      .icon-align {
        margin-right: 5px;
      }
    }
    .error-messages {
      background: #f3d6d2;
      color: #e02615;
      .danger-icon {
        color: #ef1111;
      }
    }
    .resubscribe-link {
      text-align: center;
      .ant-btn {
        border: none;
        padding: 0;
      }
    }
  }
  .spinner-align {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.redirects-layout {
  background: var(--bg-white);
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 30px;
  }
  .link {
    display: flex;
    justify-content: center;
  }
}

.settings-sider + .not-authorised-layout {
  background: var(--bg-white) !important;
}
