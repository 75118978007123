.fr-toolbar .fr-btn-grp {
  margin: 0 17px 0 5px;
}
.fr-command.fr-btn
  + .fr-dropdown-menu
  .fr-dropdown-wrapper
  .fr-dropdown-content
  ul.fr-dropdown-list
  li
  a {
  padding: 3px 20px !important;
  margin: 0 !important;
}
.fr-command.fr-btn
  + .fr-dropdown-menu:not(#dropdown-menu-paragraphFormat-2):not(#dropdown-menu-paragraphFormat-3)
  .fr-dropdown-wrapper
  .fr-dropdown-content
  ul.fr-dropdown-list
  li
  a {
  font-size: $font-size-base;
}
.fr-command.fr-btn
  + .fr-dropdown-menu#dropdown-menu-imageAlign-2
  .fr-dropdown-wrapper
  .fr-dropdown-content
  ul.fr-dropdown-list
  li
  a {
  padding: 10px 20px !important;
}
.fr-command.fr-btn
  + .fr-dropdown-menu
  .fr-dropdown-wrapper
  .fr-dropdown-content
  ul.fr-dropdown-list {
  padding: 0 !important;
}
.far {
  margin: 0 auto !important;
  padding: 0 8px;
}
.fr-toolbar .fr-command.fr-btn,
.fr-popup .fr-command.fr-btn,
.fr-modal .fr-command.fr-btn,
.fr-toolbar .fr-command.fr-btn span,
.fr-popup .fr-command.fr-btn span,
.fr-modal .fr-command.fr-btn span {
  font-size: 13px !important;
  margin: 0px 2px !important;
  padding: 0px 5px !important;
}
.fr-command.fr-btn
  + .fr-dropdown-menu
  .fr-dropdown-wrapper
  .fr-dropdown-content
  ul.fr-dropdown-list
  li
  a.fr-active {
  color: var(--text-black) !important;
}
.fr-command.fr-btn.fr-options {
  margin: 0 !important;
}
#formatUL-3,
#formatOL-3 {
  margin: 0 !important;
}
.fr-toolbar .fr-command.fr-btn.fr-dropdown i,
.fr-toolbar .fr-command.fr-btn.fr-dropdown span,
.fr-toolbar .fr-command.fr-btn.fr-dropdown img,
.fr-toolbar .fr-command.fr-btn.fr-dropdown svg,
.fr-popup .fr-command.fr-btn.fr-dropdown i,
.fr-popup .fr-command.fr-btn.fr-dropdown span,
.fr-popup .fr-command.fr-btn.fr-dropdown img,
.fr-popup .fr-command.fr-btn.fr-dropdown svg,
.fr-modal .fr-command.fr-btn.fr-dropdown i,
.fr-modal .fr-command.fr-btn.fr-dropdown span,
.fr-modal .fr-command.fr-btn.fr-dropdown img,
.fr-modal .fr-command.fr-btn.fr-dropdown svg {
  margin-left: 3px !important;
  margin-right: 11px !important;
}

.fr-toolbar .fr-command.fr-btn,
.fr-popup .fr-command.fr-btn,
.fr-modal .fr-command.fr-btn {
  color: var(--text-secondary) !important;
}

.fr-command.fr-btn + .fr-dropdown-menu {
  color: var(--text-secondary) !important;
  background: var(--white) !important;
}

.fr-command.fr-btn
  + .fr-dropdown-menu
  .fr-dropdown-wrapper
  .fr-dropdown-content
  ul.fr-dropdown-list
  li
  a.fr-active {
  background: var(--bg-gray) !important;
}

.fr-desktop .fr-command:hover,
.fr-desktop .fr-command:focus,
.fr-desktop .fr-command.fr-btn-hover,
.fr-desktop .fr-command.fr-expanded {
  background: var(--bg-lightest-gray) !important;
}

.fr-toolbar,
.fr-box.fr-basic .fr-wrapper {
  background: var(--white) !important;
  border: 1px solid var(--border-gray) !important;
}

.fr-box.fr-basic .fr-wrapper {
  border-bottom: none !important;
}

.fr-box.fr-basic .fr-element {
  color: var(--text-secondary) !important;
}

.fr-toolbar .fr-newline {
  background: transparent !important;
}

// START: Froala Editor styles
.fr-btn {
  i {
    color: var(--text-primary) !important;
  }
}
.fr-command.fr-title {
  margin: 10px !important;
  color: var(--text-primary) !important;
}
.fr-box.fr-basic.fr-top {
  .fr-toolbar {
    border-radius: 5px 5px 0 0;
    border-width: 1px 1px 0;
    box-shadow: none !important;
    .fr-newline {
      display: none;
    }
  }
  .fr-wrapper {
    border: solid 1px var(--border-light) !important;
    border-radius: 0 0 5px 5px !important;
    box-shadow: none !important;
    color: var(--text-primary);
  }
  .second-toolbar {
    display: none;
  }
  h1 {
    font-size: 2em;
  }
}

// POPUP z-index issue fix
.fr-popup {
  z-index: 2147483644 !important;
  .fr-sr-only {
    z-index: 2147483645 !important;
  }
  .fr-dropdown-list {
    li {
      a {
        padding: 5px 20px;
      }
    }
  }
}

img.fr-dib.fr-fil {
  margin-left: 0 !important;
  margin-right: auto !important;
  text-align: left;
  //width: 100%;
}

img.fr-dib.fr-fir {
  margin-right: 0 !important;
  margin-left: auto !important;
  text-align: right;
}

.fr-toolbar {
  button {
    i {
      color: var(--text-primary) !important;
    }
  }
}
