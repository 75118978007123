.h-0 {
  height: 0 !important;
}

.h-1 {
  height: 4px !important;
}

.h-2 {
  height: 8px !important;
}

.h-3 {
  height: 12px !important;
}

.h-4 {
  height: 16px !important;
}

.h-5 {
  height: 20px !important;
}

.h-6 {
  height: 24px !important;
}

.h-7 {
  height: 28px !important;
}

.h-8 {
  height: 32px !important;
}

.h-9 {
  height: 36px !important;
}

.h-10 {
  height: 40px !important;
}

.h-11 {
  height: 44px !important;
}

.h-12 {
  height: 48px !important;
}

.h-14 {
  height: 56px !important;
}

.h-15 {
  height: 60px !important;
}

.h-16 {
  height: 64px !important;
}

.h-17 {
  height: 68px !important;
}

.h-18 {
  height: 72px !important;
}

.h-20 {
  height: 80px !important;
}

.h-24 {
  height: 96px !important;
}

.h-32 {
  height: 128px !important;
}

.h-40 {
  height: 160px !important;
}

.h-48 {
  height: 192px !important;
}

.h-64 {
  height: 256px !important;
}

.h-96 {
  height: 384px !important;
}

.h-128 {
  height: 512px !important;
}

.h-auto {
  height: auto !important;
}

.h-px {
  height: 1px !important;
}

.h-full {
  height: 100% !important;
}

.h-screen {
  height: 100vh !important;
}

.max-h-5 {
  max-height: 20px !important;
}

.max-h-16 {
  max-height: 64px !important;
}

.max-h-32 {
  max-height: 128px !important;
}

.max-h-40 {
  max-height: 160px !important;
}

.max-h-64 {
  max-height: 256px !important;
}

.max-h-96 {
  max-height: 384px !important;
}

.max-h-128 {
  max-height: 512px !important;
}

.max-h-full {
  max-height: 100% !important;
}

.min-h-0 {
  min-height: 0 !important;
}

.min-h-4 {
  min-height: 16px !important;
}

.min-h-5 {
  min-height: 20px !important;
}

.min-h-6 {
  min-height: 24px !important;
}

.min-h-7 {
  min-height: 28px !important;
}

.min-h-8 {
  min-height: 32px !important;
}

.min-h-9 {
  min-height: 36px !important;
}

.min-h-10 {
  min-height: 40px !important;
}

.min-h-14 {
  min-height: 56px !important;
}

.min-h-15 {
  min-height: 60px !important;
}

.min-h-16 {
  min-height: 64px !important;
}

.min-h-18 {
  min-height: 72px !important;
}

.min-h-24 {
  min-height: 96px !important;
}

.min-h-32 {
  min-height: 128px !important;
}

.min-h-40 {
  min-height: 160px !important;
}

.min-h-48 {
  min-height: 192px !important;
}

.min-h-64 {
  min-height: 256px !important;
}

.min-h-96 {
  min-height: 384px !important;
}

.min-h-192 {
  min-height: 768px !important;
}

.min-h-full {
  min-height: 100% !important;
}

.min-h-screen {
  min-height: 100vh !important;
}

.w-0 {
  width: 0 !important;
}

.w-1 {
  width: 4px !important;
}

.w-2 {
  width: 8px !important;
}

.w-3 {
  width: 12px !important;
}

.w-4 {
  width: 16px !important;
}

.w-5 {
  width: 20px !important;
}

.w-6 {
  width: 24px !important;
}

.w-7 {
  width: 28px !important;
}

.w-8 {
  width: 32px !important;
}

.w-9 {
  width: 36px !important;
}

.w-10 {
  width: 40px !important;
}

.w-12 {
  width: 48px !important;
}

.w-13 {
  width: 52px !important;
}

.w-14 {
  width: 56px !important;
}

.w-16 {
  width: 64px !important;
}

.w-17 {
  width: 68px !important;
}

.w-18 {
  width: 72px !important;
}

.w-20 {
  width: 80px !important;
}

.w-24 {
  width: 96px !important;
}

.w-32 {
  width: 128px !important;
}

.w-40 {
  width: 160px !important;
}

.w-42 {
  width: 168px !important;
}

.w-48 {
  width: 192px !important;
}

.w-56 {
  width: 224px !important;
}

.w-64 {
  width: 256px !important;
}

.w-85 {
  width: 340px !important;
}

.w-96 {
  width: 384px !important;
}

.w-128 {
  width: 512px !important;
}

.w-192 {
  width: 768px !important;
}

.w-224 {
  width: 896px !important;
}

.w-256 {
  width: 1024px !important;
}

.w-auto {
  width: auto !important;
}

.w-px {
  width: 1px !important;
}

.w-1\/2 {
  width: 50% !important;
}

.w-1\/3 {
  width: 33.333333% !important;
}

.w-2\/3 {
  width: 66.666667% !important;
}

.w-1\/4 {
  width: 25% !important;
}

.w-3\/4 {
  width: 75% !important;
}

.w-1\/5 {
  width: 20% !important;
}

.w-2\/5 {
  width: 40% !important;
}

.w-3\/5 {
  width: 60% !important;
}

.w-4\/5 {
  width: 80% !important;
}

.w-2\/6 {
  width: 33.333333% !important;
}

.w-3\/6 {
  width: 50% !important;
}

.w-4\/6 {
  width: 66.666667% !important;
}

.w-5\/6 {
  width: 83.333333% !important;
}

.w-1\/12 {
  width: 8.333333% !important;
}

.w-2\/12 {
  width: 16.666667% !important;
}

.w-4\/12 {
  width: 33.333333% !important;
}

.w-6\/12 {
  width: 50% !important;
}

.w-9\/12 {
  width: 75% !important;
}

.w-10\/12 {
  width: 83.333333% !important;
}

.w-11\/12 {
  width: 91.666667% !important;
}

.w-full {
  width: 100% !important;
}

.w-min {
  width: min-content !important;
}

.w-max {
  width: max-content !important;
}

.w-fit {
  width: fit-content !important;
}

.min-w-0 {
  min-width: 0 !important;
}

.min-w-2 {
  min-width: 8px !important;
}

.min-w-3 {
  min-width: 12px !important;
}

.min-w-4 {
  min-width: 16px !important;
}

.min-w-6 {
  min-width: 24px !important;
}

.min-w-7 {
  min-width: 28px !important;
}

.min-w-8 {
  min-width: 32px !important;
}

.min-w-12 {
  min-width: 48px !important;
}

.min-w-13 {
  min-width: 52px !important;
}

.min-w-15 {
  min-width: 60px !important;
}

.min-w-16 {
  min-width: 64px !important;
}

.min-w-24 {
  min-width: 96px !important;
}

.min-w-32 {
  min-width: 128px !important;
}

.min-w-40 {
  min-width: 160px !important;
}

.min-w-48 {
  min-width: 192px !important;
}

.min-w-64 {
  min-width: 256px !important;
}

.min-w-85 {
  min-width: 340px !important;
}

.min-w-96 {
  min-width: 384px !important;
}

.min-w-128 {
  min-width: 512px !important;
}

.min-w-224 {
  min-width: 896px !important;
}

.min-w-5\/6 {
  min-width: 83.333333% !important;
}

.min-w-full {
  min-width: 100% !important;
}

.min-w-min {
  min-width: min-content !important;
}

.min-w-max {
  min-width: max-content !important;
}

.min-w-fit {
  min-width: fit-content !important;
}

.max-w-4 {
  max-width: 16px !important;
}

.max-w-8 {
  max-width: 32px !important;
}

.max-w-12 {
  max-width: 48px !important;
}

.max-w-16 {
  max-width: 64px !important;
}

.max-w-20 {
  max-width: 80px !important;
}

.max-w-24 {
  max-width: 96px !important;
}

.max-w-32 {
  max-width: 128px !important;
}

.max-w-40 {
  max-width: 160px !important;
}

.max-w-48 {
  max-width: 192px !important;
}

.max-w-64 {
  max-width: 256px !important;
}

.max-w-85 {
  max-width: 340px !important;
}

.max-w-96 {
  max-width: 384px !important;
}

.max-w-128 {
  max-width: 512px !important;
}

.max-w-170 {
  max-width: 680px !important;
}

.max-w-192 {
  max-width: 768px !important;
}

.max-w-224 {
  max-width: 896px !important;
}

.max-w-256 {
  max-width: 1024px !important;
}

.max-w-none {
  max-width: none !important;
}

.max-w-full {
  max-width: 100% !important;
}

.max-w-1\/2 {
  max-width: 50% !important;
}

.max-w-2\/3 {
  max-width: 66.666667% !important;
}

.max-w-1\/4 {
  max-width: 25% !important;
}

.max-w-3\/4 {
  max-width: 75% !important;
}

.max-w-2\/5 {
  max-width: 40% !important;
}

.max-w-3\/5 {
  max-width: 60% !important;
}

.max-w-4\/5 {
  max-width: 80% !important;
}

.max-w-4\/6 {
  max-width: 66.666667% !important;
}

.max-w-5\/6 {
  max-width: 83.333333% !important;
}

.max-w-7\/12 {
  max-width: 58.333333% !important;
}

.max-w-9\/12 {
  max-width: 75% !important;
}

.max-w-10\/12 {
  max-width: 83.333333% !important;
}

.max-w-11\/12 {
  max-width: 91.666667% !important;
}

.max-w-min {
  max-width: min-content !important;
}

.max-w-max {
  max-width: max-content !important;
}

.max-w-fit {
  max-width: fit-content !important;
}
