@import '../../../style/variable';
@import '../../../style/mixin';
@import '../../../style/snippet';

%flex-align-center {
  display: flex;
  align-items: center;
}

%table-align-center {
  display: table-cell;
  vertical-align: middle;
}

%messageContent {
  margin-bottom: 0;
  border-radius: 8px;
  background-color: var(--conversation-person-message-bg);
  padding: 10px 16px;
  color: var(--conversation-person-message-text);
  display: inline-block;
  max-width: 80%;
  white-space: pre-line;
}

%messageComposerPopover {
  height: 260px;
  overflow-y: auto;
}
%meeting-style {
  max-width: 244px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.edit-visible {
  visibility: visible;
}

.edit-visible-hide {
  visibility: hidden;
}

.notes-mention {
  border-radius: 50px;
  background-color: var(--conversation-note-message-mention-bg);
  padding: 0.5px 7px;
  display: inline-block;
  margin: 2px 0px;
}

.ant-popover {
  .ant-popover-inner {
    min-width: 250px;
  }
  .ant-popover-title {
    border: none !important;
    padding: 15px 15px 5px 15px !important;
    font-size: 16px;
    .back-icon {
      margin-right: 6px;
      cursor: pointer;
    }
    .icon-settings {
      cursor: pointer;
      float: right;
      height: 30px;
      width: 30px;
      text-align: center;
      display: inline-block;
      &:hover {
        border-radius: 50%;
        background: var(--search-box-bg);
        text-align: center;
        float: right;
        display: inline-block;
      }
      i {
        display: inline-block;
        margin-top: 2px;
      }
    }
    .operations-picker {
      cursor: pointer;
      float: right;
      text-align: center;
      display: inline-block;
      button {
        padding: 2px 6px;
        margin-left: 10px;
        i {
          margin-left: 6px;
        }
      }
    }
    &:after {
      clear: right;
    }
  }
  .ant-popover-inner-content {
    padding: 12px 15px !important;
  }
  .ant-input-affix-wrapper {
    margin-bottom: 15px;
    input {
      border-radius: 25px;
      //background: var(--search-box-bg);
      border: none;
      padding-left: 15px;
      &:hover {
        border-color: var(--border-light) !important;
      }
    }
    .ant-input-suffix {
      i {
        font-size: 16px;
      }
    }
  }
}

.gif-container {
  @extend %messageComposerPopover;
  width: 315px;
  height: 310px;
  > div {
    display: flex;
    flex-wrap: wrap;
  }
  .no-gif {
    position: relative;
    top: 40%;
    text-align: center;
    font-weight: 500;
  }
  .gif-image-container {
    width: 50%;
    height: 120px;
    min-height: 120px;
    padding: 3px;
    .gif-image-body {
      width: 100%;
      height: 100%;
      position: relative;
      &:hover {
        cursor: pointer;
      }
      &:hover::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        @include background-with-opacity(
          $hex-color: var(--black-rgb),
          $opacity: 0.45
        );
        z-index: 2;
      }
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
      }
      &:hover svg {
        opacity: 1;
        z-index: 2;
      }
      .gif-image {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .ant-spin {
      position: absolute;
      display: inline;
      left: 52px;
      top: 35px;
      z-index: 0;
    }
  }
}

.meeting-scheduler-popover {
  .ant-input-affix-wrapper {
    margin-bottom: 10px !important;
  }
  .scheduler-container,
  .meetings-container {
    max-height: 275px;
    overflow-y: auto;
    max-width: 275px;
    .scheduler,
    .meetings {
      padding: 5px;
      margin-bottom: 5px;
      cursor: pointer;
      white-space: nowrap;
      vertical-align: middle;
      &:hover {
        background-color: $select-box-hover-bg;
        border-radius: 5px;
      }
      img {
        height: 20px;
        width: 20px;
      }
      span {
        font-size: $font-size-base;
        display: inline-block;
        width: calc(100% - 40px);
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
      .meeting-name {
        font-size: $font-size-base;
        font-weight: 500;
        color: var(--text-secondary);
        @extend %meeting-style;
      }
      .meeting-url {
        font-size: $font-size-base;
        color: var(--primary-color);
        @extend %meeting-style;
      }
    }
    .no-scheduler {
      text-align: center;
      padding: 60px 0px;
    }
  }
}

.snippet-popover-container {
  .popover-container {
    width: 100%;
  }
}

.manage-link-footer {
  padding-top: 18px;
  text-align: center;
  border-top: 1px solid var(--input-box-border);
  font-weight: 500;
  margin: 0px -21px;
  .manage-link {
    cursor: pointer;
    color: var(--primary-color);
  }
}

.chat-history {
  flex: 60%;
  background: transparent;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
  .drop-overlay-info {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    @include background-with-opacity(
      $hex-color: var(--white-rgb),
      $opacity: 0.9
    );
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
  }
  .no-messages {
    position: relative;
    top: 45%;
    text-align: center;
    width: 100%;
    font-size: 18px;
    [class^='fa-'] {
      font-size: 36px !important;
    }
  }

  .header {
    background: var(--bg-white);
    height: 60px;
    width: 100%;
    padding: 12px 20px;
    position: relative;
    border-bottom: 1px solid var(--border-light);
    z-index: 1;
    display: table;
    table-layout: fixed;

    .conversation-title-container {
      @extend %table-align-center;
      line-height: normal;
      .title {
        width: 100%;
        display: flex;
        padding-right: 5px;

        &:hover {
          .title-pencil {
            display: block;
            position: relative;
            top: 8px;
          }
        }

        h1 {
          font-size: 16px;
          font-weight: 500;
          padding: 0 10px 0 0;
          margin-bottom: 0;
        }

        .title-pencil {
          display: none;
          cursor: pointer;
          color: var(--text-light);
        }

        .inline-input {
          font-size: 16px;
          font-weight: 500;
          padding: 0 10px 0 0;
          margin-bottom: 0;
          width: 100%;
        }
      }
      .conversation-title-input {
        font-size: 16px;
        padding-bottom: 3px;
        margin-right: 30px;
        &.clicked {
          border-bottom: 1px dashed var(--border-dark);
        }
      }
      .conversation-title-h1 {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &.title-cursor-default {
          cursor: default;
        }
      }
      .h1-light {
        opacity: 0.7;
      }
    }
    .header-actions {
      @extend %table-align-center;
      width: 375px;

      &.pending {
        width: 375px + 50px;
        &.extended {
          width: 375px + 180px;
        }
        &.right-menu-closed {
          width: 375px + 80px;
        }
        &.extended.right-menu-closed {
          width: 375px + 210px;
        }
      }
      &.extended {
        width: 375px + 95px;
        &.right-menu-closed {
          width: 375px + 155px;
        }
      }
      &.right-menu-closed {
        width: 375px + 30px;
      }
      .action-icons {
        @extend %flex-align-center;
        justify-content: flex-end;
        gap: 10px;
        margin-left: 20px;

        > span {
          cursor: pointer;
          &.reopen,
          &.close {
            @extend %flex-align-center;
            i {
              margin-right: 5px;
            }
          }
        }

        .assign-container {
          cursor: pointer;

          img {
            height: 14px;
            width: 14px;
            border-radius: 50%;
          }

          .team-emoji {
            font-size: 14px;
          }
          .custom-btn {
            display: flex;
            align-items: center;
            .text {
              max-width: 150px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .conversation-blocked {
          color: $error-color;

          i {
            color: $error-color;
            padding-right: 5px;
          }
        }

        .user-property-icon {
          cursor: pointer;
          display: none;
          .anticon-user {
            font-size: 16px;
          }

          @media screen and (max-width: $screen-lg) {
            display: block;
          }
        }
      }
    }
  }
  .body-background-container {
    //background-image: url(../../../../src/image/inbox-bg/inbox-bg-1.png);
    //background: var(--bg-lightest-gray) !important;
    //opacity: .65;
    //left: 0;
    //right: 0;
    //top: 0;
    //bottom: 0;
    //position: absolute;
    //z-index: -1;
  }

  .body {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    width: 100%;
    max-width: 1660px;
    min-width: 468px;
    margin-left: auto;
    margin-right: auto;

    .message-banner {
      position: absolute;
      background: var(--conversation-note-message-bg);
      z-index: 1;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      box-shadow: $default-box-shadow;
      top: 60px;
      padding: 10px 30px 10px 30px;
      font-weight: 500;
      width: 100%;
      & + .spam-message-body-container {
        margin-top: 35px;
      }
    }
  }

  .footer {
    padding: 0 20px 20px;
    .anticon-caret-down {
      font-size: $font-size-base;
    }
    .blocked-conversation {
      height: 70px;
      background: var(--bg-white);
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      text-align: center;
    }
    .ant-tabs {
      padding: 0 15px 15px 15px;
      border-radius: 6px;
      box-shadow: 0 5px 10px 0 rgba(18, 66, 129, 0.03);
      border: solid 1px var(--border-light);
      background: var(--bg-white);
      @include border-with-opacity($hex-color: var(--black-rgb), $opacity: 0.2);
      overflow: visible;

      #replyEditor,
      #noteFroalaEditor {
        .fr-wrapper {
          overflow-x: hidden !important;
        }
      }

      .ai-icon {
        color: var(--gray-700);
        padding: 0px 2px;
        font-size: 16px;
        font-weight: 600;
        border: 1px solid var(--gray-600);
        border-radius: 4px;

        &:hover {
          border: 1px solid var(--primary-color);
        }
      }

      &.is-focused {
        border: 1px solid var(--primary-color);
        outline: 3px solid rgba($primary-color, 0.3);
      }

      .ant-tabs-top-content > .ant-tabs-tabpane {
        padding: 0;
      }
      .ant-tabs-bar {
        padding-top: 0;
        padding-left: 0;
        margin: 0;
      }
      &.note {
        background: var(--conversation-note-message-bg);
        color: var(--conversation-note-message-text);

        .ant-tabs-bar {
          border-bottom: 1px solid var(--black-opacity-bg);
        }
        #noteFroalaEditor {
          .fr-placeholder {
            color: var(--conversation-note-editor-placeholder-text);
          }
        }
      }
      &.editable-reply,
      &.editable-note {
        background: var(--conversation-composer-edit-mode-bg-color);
        .ant-tabs-bar {
          border-bottom: 1px solid rgba(var(--black-rgb), 0.1);
        }
      }

      &.editable-reply {
        .ant-tabs-nav .ant-tabs-tab:last-of-type {
          display: none;
        }
      }
      &.editable-note {
        .ant-tabs-nav .ant-tabs-tab:first-of-type {
          display: none;
        }
      }

      .send-buttons {
        display: flex;
        flex-wrap: nowrap;
        margin-left: 8px;

        span {
          position: relative;
        }

        & > .ant-btn {
          margin-left: 5px;
          padding: 5px 18px;
        }

        .send-snooze-button {
          display: flex;
          align-items: center;
          padding: 2px 10px;
          i {
            font-size: $font-size-base;
          }
          &:active,
          &:hover,
          &:focus {
            i {
              color: var(--primary-color);
            }
          }
        }
      }

      .ant-tabs-extra-content {
        > div {
          font-size: 16px;

          span {
            font-size: 14px;
          }
          i {
            font-size: 16px;
          }
          i,
          span {
            cursor: pointer;
            &:hover {
              color: var(--primary-color);
            }
          }

          > * + * {
            margin-left: 15px;
          }
        }
      }

      .ant-mention-wrapper {
        min-height: 40px;
        max-height: 180px;
        overflow-y: auto;
        .ant-mention-editor {
          border: 0 !important;
          outline: none !important;
          box-shadow: none !important;
          border-right-width: 0 !important;
          background: transparent;
          .public-DraftEditor-content {
            word-break: break-word;
          }
        }
      }
      textarea {
        border: none;
        resize: none;
        background: transparent;
        &:focus {
          box-shadow: none;
        }
      }
      .froalaEditor {
        padding: 12px 0 5px;

        .fr-wrapper {
          .fr-element {
            min-height: 63px !important;
            overflow-x: hidden;

            img {
              @extend %editor-image;
              margin: 6px 0 !important;
            }
          }

          .fr-placeholder {
            margin-top: 0 !important;
            line-height: 21px;
          }
        }
      }
    }
  }

  // Override skeleton
  .ant-skeleton {
    padding: 20px;
    flex: none;
    &:nth-child(even) {
      display: flex;
      flex-direction: row-reverse;
      .ant-skeleton-header {
        padding-right: 0;
        padding-left: 16px;
      }
    }
  }

  .messages-loading-container {
    position: absolute;
    top: 75px;
    left: 50%;
  }
  .fa-circle-check,
  .fa-inbox-full {
    margin-right: 5px;
  }
}
.chat-history.chat-history-with-both-menu {
  width: calc(100vw - 1220px);
}
.chat-history.chat-history-with-left-menu {
  width: calc(100vw - 740px);
}
.chat-history.chat-history-with-right-menu {
  width: calc(100vw - 1020px);
}
// Select dropdown override
.assign-dropdown {
  .ant-select-dropdown-menu {
    //max-width: 291px;
    max-height: 300px;
    .active-status-dot {
      border: 2px solid var(--border-white);
      height: 10px;
      width: 10px;
      border-radius: 6px;
      position: absolute;
      z-index: 1;
      left: 29px;
      top: 21px;
    }
    .ant-select-dropdown-menu-item-group-title {
      font-size: $font-size-base;
      margin: 5px 10px;
      font-weight: 600;
      color: var(--text-secondary);
    }
    .ant-select-dropdown-menu-item {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      > span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      img,
      .team-emoji {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        margin-right: 10px;
      }
      .team-emoji {
        font-size: 20px !important;
        line-height: 20px !important;
        overflow: visible;
      }
      .content-overflow {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.agent-message {
  .content {
    .bubble-container {
      flex-direction: row-reverse;

      .bubble {
        color: var(--conversation-agent-message-text);
        img {
          cursor: pointer;
        }
      }
      .bubble.bot-message {
        background-color: var(--conversation-bot-message-bg-color) !important;
        color: var(--conversation-bot-message-text) !important;
      }
    }
  }
}

.person-message {
  .content {
    .bubble-container {
      .bubble {
        align-items: flex-start;
      }
    }
  }
}

// Using common css for messages
.person-message,
.agent-message {
  display: flex;
  margin-bottom: 16px;
  word-break: break-all;
  .Linkify {
    width: 100%;
  }
  .content {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
    word-break: break-word;
    .bubble-container {
      display: flex;
      align-items: flex-start;
      .bubble-options {
        padding-top: 10px;
      }
      ul,
      ol {
        padding-left: 20px;
      }
    }
    .fa-pencil,
    .fa-tag {
      cursor: pointer;
      &:hover {
        color: var(--primary-color);
      }
    }
    .fa-tag {
      margin: 0 10px;
    }
    .edited-icon {
      margin-right: 10px;
    }
    .bubble {
      p:last-of-type {
        margin-bottom: 0;
      }
      .fr-video {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        display: block;
        min-width: 360px;
      }
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    &.is-image {
      > .image-container {
        display: inline-block;
        max-width: 52%;
        padding: 10px;
        border-radius: 5px;
        cursor: pointer;

        img {
          max-width: 100%;
          max-height: 100%;
          border-radius: 5px;
        }
      }
      .bubble {
        @extend %messageContent;
        & > a {
          color: var(--text-secondary);
        }
      }
      .note-image-attachement {
        background: var(--conversation-note-message-bg) !important;
      }
    }
    &.deleted-content {
      width: 100%;
    }
    .bubble {
      @extend %messageContent;
      & > a {
        .fa-paperclip {
          margin-right: 5px;
        }
      }
      .set-size {
        font-size: 28px;
      }
      .deleted-message {
        font-style: italic;
        color: var(--text-light);
      }
      img {
        @extend %editor-image;
        margin: 6px 0;
      }
    }
    > .emoji-bubble {
      padding: 5px 14px !important;
    }
    .status {
      margin-top: 7px;
      color: var(--text-light);
      font-size: 13px;
      display: flex;
      align-items: center;

      .separator {
        margin: 0 3px;
      }

      i {
        margin: 0 8px;
        font-size: 13px;
      }
    }
    .ant-dropdown-link {
      display: inline-block;
      margin-left: 10px;
      vertical-align: bottom;
      cursor: pointer;
    }
  }

  .ai-bot-container-inbox {
    border-radius: 10px;
    border: 1px solid var(--border-gray);
    width: 300px;
    max-width: 300px;
    text-align: start;

    &.ai-answer {
      background: var(--ai-answer-background);
      .separate-line {
        background-color: var(--border-gray);
      }
    }
  }
}

.bot-message {
  &.ai-answer {
    .footer {
      border-top: 1px solid var(--border-gray);
      padding: 12px 18px 2px;
      margin: 12px -15px 0px;

      .ai-icon {
        color: var(--text-white);
        background: var(--black);
        padding: 0 2px;
        font-size: 12px;
        font-weight: 600;
        border: 1px solid var(--black);
        border-radius: 4px;
      }
    }
  }
}

.agent-message {
  flex-direction: row-reverse;
  text-align: right;
  .profile-with-tooltip {
    height: 40px;
  }
  .content {
    &.is-image {
      > .image-container {
        background: var(--conversation-agent-message-bg);
      }
    }
    .bubble {
      text-align: left;
      background-color: var(--conversation-agent-message-bg);
      .tag-list {
        .tag {
          @include border-with-opacity(
            $hex-color: var(--black-rgb),
            $opacity: 0.2
          ); // Jiita TODO Need to check
          background: var(--bg-white);
          cursor: default;
          margin-top: 10px;
        }
      }
      h1 {
        font-size: 22px;
      }
      h2 {
        font-size: 18px;
      }
    }
    .status-contents {
      display: flex;
      justify-content: flex-end;
      .tenor-style {
        margin-right: 5px;
      }
    }
    .status {
      justify-content: flex-end;
    }
    &.note {
      .bubble {
        background: var(--conversation-note-message-bg) !important;
        color: var(--conversation-note-message-text);
      }
    }
    .ant-dropdown-link {
      i {
        font-size: 18px;
        margin-right: 10px;
        opacity: 1;
        cursor: pointer;
      }
    }
    .fa-tag {
      margin-right: 5px !important;
    }
  }
}

.person-message {
  .content {
    &.is-image {
      > .image-container {
        background: var(--conversation-person-message-bg);
      }
    }
    .bubble {
      .tag-list {
        .tag {
          border: 1px solid rgba(0, 0, 0, 0.2) !important;
          background: var(--bg-white);
          cursor: default;
          margin-top: 10px;
        }
      }
    }
    .status-contents {
      display: flex;
      justify-content: flex-end;
      flex-direction: row-reverse;
      .tenor-style {
        margin-right: 5px;
      }
    }
  }
  .satisfaction-rating-content {
    margin-left: 10px;
    img {
      width: 30px;
      height: 30px;
    }
    .ant-card {
      max-width: 460px;
      min-width: 200px;
      border: 1px solid var(--border-gray);
      border-radius: 5px;
      .ant-card-body {
        padding: 15px;
        div:nth-child(odd) {
          color: var(--text-light);
        }
        div:last-child {
          font-weight: 500;
          line-height: 1.29;
        }
        div:nth-child(3) {
          margin-top: 5px;
        }
      }
    }
  }
}

.divider {
  color: var(--text-light);
  font-size: 13px;
}
.meeting-scheduler {
  display: flex;
  > div {
    min-width: 300px;
    max-width: 500px;
    background: var(--bg-white);
    border-radius: 5px;
    border: solid 1px var(--border-gray);
    color: var(--text-secondary);
    float: right;
    > div:first-child {
      padding: 10px 15px;
      display: flex;
      align-items: center;
      p {
        margin-bottom: 0;
        margin-left: 10px;
      }
    }
    > div:nth-child(2) {
      padding: 10px 15px;
      border-top: solid 1px var(--border-gray);
      p {
        margin-bottom: 0;
        & + p {
          margin-top: 6px;
        }
      }
    }
  }
}

.conversation-status-message {
  text-align: right;
  color: var(--text-light);
  font-size: 13px;
  margin: 0 34px 8px;

  & + .agent-message,
  & + .person-message {
    margin-top: 16px;
  }
}

.ant-divider-horizontal.ant-divider-with-text-center {
  font-size: 15px;

  &:first-of-type {
    margin-top: 0;
  }
}

.schedule-content {
  margin-left: 10px;
  .status {
    color: var(--text-light);
    font-size: 13px;
    display: flex;
    align-items: center;
    margin-top: 5px;
  }
}

.emojis {
  .ant-popover-inner {
    .ant-popover-inner-content {
      padding: 0 !important;
      .emoji-mart {
        border: 0;
      }
    }
  }
}

// Typing bubble
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.typing-bubble {
  &.aiassist-typing-dot {
    padding: 0px !important;
    background: none !important;
  }
  @extend %messageContent;
  margin-left: 10px;
  margin-right: 10px;
  & > span {
    margin-right: 5px;
  }
  .dot {
    width: 7px;
    height: 7px;
    background-color: $conversation-typing-indicator-dot-color;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    @include prefixer(animation, fade 1s linear infinite);
    & + .dot {
      margin-left: 7px;
    }
    &:nth-child(1) {
      @include prefixer(animation-delay, 0s);
    }
    &:nth-child(2) {
      @include prefixer(animation-delay, 0.25s);
    }
    &:nth-child(3) {
      @include prefixer(animation-delay, 0.5s);
    }
  }
}

.meeting-scheduler-image {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  margin-right: 7px;
}
.mention-menu {
  max-width: 147px !important;
  max-height: 200px;
  overflow: auto;
  .ant-dropdown-menu-item {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .no-record {
    padding: 7px;
  }
}

.message-from {
  .ant-popover-inner-content {
    padding: 15px !important;
  }
}

.emoji-loading {
  width: 340px;
  height: 420px;
}

.snippet-text {
  font-size: $font-size-base;
}

.mentions-list {
  .ant-menu {
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    background-clip: padding-box;
    li.ant-menu-item {
      margin: 0;
    }
    li.ant-menu-item:hover {
      background-color: $select-box-hover-bg;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}

.note {
  .tag-list {
    .tag {
      @include border-with-opacity($hex-color: var(--black-rgb), $opacity: 0.2);
    }
  }
}
.tag-list {
  overflow: hidden;
  .tag {
    cursor: default;
    .tag-name {
      max-width: 250px;
      min-width: 20px;
      margin-right: 3px;
    }
    i {
      font-size: 12px;
      right: 0 !important;

      &.cancel {
        position: relative;
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }
  .more-tags {
    display: inline-block;
    padding: 3px 8px;
    color: var(--text-light);
    margin-top: 10px;
    margin-right: 10px;
    font-size: 12px;
    vertical-align: top;
    cursor: pointer;
  }
}

.snooze-date-picker {
  top: 120px !important;

  &.hide-snooze {
    display: none;
  }
}

.tribute-container {
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background-clip: padding-box;
  max-width: 147px;
  max-height: 200px;
  overflow: auto;
  background-color: var(--popover-bg);
  ul {
    padding: 0;
    margin: 0;
    li {
      padding: 0 16px;
      height: 40px;
      line-height: 30px;
      overflow: hidden;
      font-size: $font-size-base;
      text-overflow: ellipsis;
      cursor: pointer;
      &.highlight {
        background-color: var(--select-hover-color);
        color: var(--primary-color);
      }
      img {
        height: 20px;
        width: 20px;
        border-radius: 20px;
        margin-right: 10px;
      }
    }
  }
}

.attachments-preview {
  max-height: 50px;
  overflow-y: auto;
  .tag {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 12px;
    border-radius: 6px;
    border: solid 1px var(--border-gray);
    color: var(--text-light);
    margin-bottom: 10px;
    margin-right: 10px;
    font-size: 13px;
    cursor: default;
    background: var(--bg-white);
    max-width: 118px;
    &:hover {
      background: var(--gray-300);
      color: var(--text-secondary);
      .fa-xmark {
        display: unset;
      }
      .anticon-loading {
        display: none;
      }
    }
    .file-name {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
      white-space: nowrap;
    }
    i {
      font-size: 10px !important;
      position: relative;
      margin-left: 8px;
    }
    .fa-xmark {
      display: none;
      cursor: pointer;
    }
  }
}

.editor-footer {
  display: flex;
  justify-content: space-between;
}

span[disabled] {
  &,
  & * {
    cursor: not-allowed !important;
  }
  &:active {
    pointer-events: none;
  }
}

.snooze-overlay {
  position: fixed;
  inset: 0px;
  z-index: 400;

  &.hidden {
    display: none;
  }
}

.rc-menu-container {
  z-index: 500;
  .rc-menu.snooze-list {
    min-width: 220px;
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;

    .rc-menu__item {
      padding: 0.375rem 1rem;
      display: flex;
      justify-content: space-between;

      span {
        color: #727276;
      }
      &:hover {
        background: var(--table-header-hover-bg);
      }
    }
    background: var(--bg-white);
    color: var(--text);
  }
}

@media screen and (max-width: 1350px) {
  .chat-history .footer .ant-tabs-nav-container-scrolling {
    padding: 0 !important;
  }
  .chat-history .footer .ant-tabs-extra-content {
    float: none !important;
    line-height: 20px;
    padding-top: 10px;
  }
  .chat-history .footer .ant-tabs .ant-tabs-extra-content > div i {
    font-size: 13px;
  }
  .chat-history .footer .ant-tabs .ant-tabs-extra-content > div span {
    font-size: 12px;
  }
}

.custom-snooze-modal {
  .ant-modal {
    width: 460px !important;

    .ant-modal-body {
      padding: 0;
    }
  }
  z-index: 1001;
}

.react-datepicker {
  display: inline-block;
  position: relative;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  list-style: none;
  background-color: var(--bg-white);
  background-clip: padding-box;
  border: 1px solid var(--bg-white);
  border-radius: 4px;
  outline: none;
  margin-bottom: -5px;

  .react-datepicker__month-container {
    .react-datepicker__header {
      background: var(--bg-white);
      position: relative;
      text-align: center;
      border-bottom: 1px solid var(--border-dark);
      user-select: none;
      padding-bottom: 0;

      .react-datepicker__current-month,
      .react-datepicker-year-header {
        margin-top: 0;
        color: var(--text-black);
        font-weight: bold;
        font-size: 0.944rem;
        height: 30px;
        line-height: 30px;
      }
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      display: inline-block;
      width: 2.75rem;
      height: 2.75rem;
      text-align: center;
      background: transparent;
      border: 1px solid transparent;
      border-radius: 2px;
      -webkit-transition: background 0.3s ease;
      transition: background 0.3s ease;
      line-height: 42px;
      color: var(--text);
    }

    .react-datepicker__month {
      .react-datepicker__week {
        .react-datepicker__day,
        .react-datepicker__time-name {
          &:hover {
            cursor: pointer;
            background: var(--table-header-hover-bg);
          }
        }

        .react-datepicker__day {
          &.react-datepicker__day--keyboard-selected {
            color: var(--white);
            background: var(--primary-color);
          }
        }

        .react-datepicker__day--selected,
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--selected,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--selected,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--selected,
        .react-datepicker__year-text--in-selecting-range,
        .react-datepicker__year-text--in-range {
          color: var(--primary-color);
          font-weight: 700;
          border-color: var(--primary-color);
        }
      }
    }
    background: var(--bg-white);
  }

  .react-datepicker__time-container {
    border-left: 1px solid var(--border-dark);
    width: 98px;

    .react-datepicker__header--time {
      //border-left: 1px solid var(--border-dark);
      width: 100px;
    }

    .react-datepicker__time {
      background: var(--bg-white);
      .react-datepicker__time-box {
        width: 100px;

        .react-datepicker__time-list {
          height: 308px;

          .react-datepicker__time-list-item {
            height: auto;
            padding: 8px 10px;
            white-space: nowrap;
            margin: 5px 0px;
            color: var(--text);

            &:hover {
              cursor: pointer;
              background: var(--table-header-hover-bg);
            }
          }
          .react-datepicker__time-list-item--disabled {
            color: var(--border-darker) !important;
          }

          .react-datepicker__time-list-item--selected {
            color: var(--primary-color);
            font-weight: 700;
            border: 1px solid var(--primary-color);
            background-color: var(--bg-white);
          }
        }
      }
    }
    .react-datepicker__header {
      background: var(--bg-white);
      .react-datepicker-time__header {
        color: var(--text);
      }
    }
  }

  .react-datepicker__navigation {
    top: 14px;

    &.react-datepicker__navigation--next--with-time:not(
        .react-datepicker__navigation--next--with-today-button
      ) {
      right: 100px;
    }
  }
}

/** LIGHTBOX CSS **/
.img-lightbox-container {
  /* Overlay entire screen */
  position: fixed;
  z-index: 2147483003;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  /* A bit of padding around image */
  padding: 2em;

  /* Translucent background */
  background: rgba(0, 0, 0, 0.8);

  /* Set display to Flex */
  display: flex;

  img {
    background: transparent;
    border: 0;
    position: relative;
    display: block;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    outline: none;
    user-select: none;
    opacity: 1;
    margin: auto;
    box-shadow: none;
    z-index: 2;

    &.zoomed-in {
      cursor: zoom-out;
    }

    &.zoom-allowed {
      cursor: zoom-in;
    }
  }
}

.img-lightbox-controls {
  position: fixed;
  top: 5px;
  right: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  will-change: right;
  z-index: 2147483004;

  .lightbox-button {
    height: 40px;
    width: 40px;
    background-color: rgba(230, 230, 230, 0.8);
    border: 0;
    box-shadow: none;
    cursor: pointer;
    padding: 0;
    visibility: inherit;
    opacity: 1;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    position: relative;
    margin: 0 5px 0 0;
    border-radius: 3px;

    div {
      height: 36px;
      width: 36px;
      padding: 10px;
      display: flex;
      align-items: center;

      svg {
        display: block;
        height: 100%;
        width: 100%;
        overflow: visible;
        position: relative;
      }
    }
  }
}

.img-lightbox-overlay {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.insert-app-content {
  .center {
    display: block;
    margin: 0 auto;
  }
}

.call-bubble {
  border: 1px solid var(--border-gray);
  border-radius: 5px;
  background: var(--bg-white);
  box-shadow: 0 1px 4px 0 rgba(71, 110, 136, 0.12);
  .ant-card-body {
    .ant-card-meta {
      text-align: center;
      .ant-card-meta-avatar {
        float: none;
        padding: 0;
        margin-bottom: 10px;
        .profile-avatar {
          margin: auto;
        }
      }
      .ant-card-meta-title,
      .ant-card-meta-description {
        color: var(--text-primary);
      }
    }
  }
  .ant-card-actions {
    padding: 0 12px;
    background: var(--bg-white);
    border-color: var(--border-gray);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    li {
      span {
        cursor: default;
        .ant-typography {
          font-style: italic;
          color: var(--text-secondary);
        }
      }
    }
  }
}
.daily-co-popover {
  .ant-popover-content .ant-popover-inner {
    min-width: 150px;
  }
}

.view-original-message-modal {
  max-height: calc(100% - 150px);
  overflow: hidden;
  border-radius: 6px;
  width: calc(100% - 40px) !important;
  max-width: 1200px;

  .ant-modal-close {
    display: block;
  }

  .ant-modal-body {
    padding-top: 30px;
  }

  .message-details {
    border: 1px solid var(--border-light);
    border-radius: 5px;
    & > .ant-row {
      &:not(:last-child) {
        border-bottom: 1px solid var(--border-light);
      }
      .ant-col {
        padding: 10px;
      }
      .ant-col-20 {
        border-left: 1px solid var(--border-light);
      }
    }
  }

  .message-preview {
    margin-top: 10px;
    background-color: #fff; //In dark mode also message preview on white background
    border-radius: 4px;
    .ant-skeleton {
      padding: 0 20px;
      //In dark mode also message preview skeleton background don't changed.
      .ant-skeleton-title,
      .ant-skeleton-paragraph > li {
        background: linear-gradient(
          90deg,
          #f2f2f2 25%,
          #e6e6e6 37%,
          #f2f2f2 63%
        ) !important;
      }
    }
    height: calc(100vh - 440px);
    &.with-cc-content {
      height: calc(100vh - 480px);
    }
    .message-preview-content {
      overflow-y: auto;
      width: 100%;
      border: none;
      height: inherit;
    }
  }
}

.ai-assist-loader {
  animation: ai-assist-loader 2s infinite;
}

@keyframes ai-assist-loader {
  from {
    transform: scale(1, 1);
  }
  to {
    transform: scale(2, 2);
    opacity: 50%;
  }
}
