@import '~antd/dist/antd.css';

html,
body,
#root,
#root > div,
#root > div > section {
  height: 100%;
}
/*TODO Jitta - This needs to be test properly*/
#root .layout.main-layout {
  overflow-x: auto;
  min-width: 1024px;
}
/*@media screen and (min-width: 769px) {*/
/*  html, body, #root {*/
/*    overflow-x: auto;*/
/*    min-width: 1024px;*/
/*  }*/
/*}*/
#root > div > div > div > div.ant-layout.ant-layout-has-sider {
  height: calc(100% - 58);
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue',
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $font-size-base;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-btn {
  text-shadow: none;
}

.ant-layout-sider-children {
  overflow-x: hidden !important;
}

.ant-layout-sider
  .ant-menu
  .ant-menu-submenu
  .ant-menu-sub
  .ant-menu-item.ant-menu-item-selected {
  /*background: #e7e7e7 !important;*/
  background: none !important;
}

.ant-layout-sider
  .ant-menu
  .ant-menu-submenu.ant-menu-submenu-selected:not(.ant-menu-submenu-open) {
  /*background: #e7e7e7 !important;*/
  background: none !important;
}
/* Need to check and remove */
.report-sider
  .ant-menu
  .ant-menu-submenu
  .ant-menu-sub
  .ant-menu-item.ant-menu-item-selected {
  /*background: #f8fafb !important;*/
  background: none !important;
}
.report-sider
  .ant-menu
  .ant-menu-submenu.ant-menu-submenu-selected:not(.ant-menu-submenu-open) {
  /*background: #f8fafb !important;*/
  background: none !important;
}

.ant-popover-arrow {
  background-color: #fff;
}

.ant-skeleton-paragraph {
  padding: 0;
}

/*.ant-table-body .ant-table-fixed {*/
/*  border-top: none !important;*/
/*}*/

.ant-message {
  z-index: 1029;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: none;
}

/* Gist Messenger Bubble Custom Animation */
@keyframes showMessenger {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.gist-messenger-bubble-iframe iframe {
  animation: showMessenger 0.25s;
}
