@import 'variable';
@import 'mixin';

%title-style {
  color: var(--primary-color);
  font-weight: 500;
  margin-right: 5px;
}

.ant-tabs-tabpane {
  .outbound-table-container {
    padding: 0;
  }
}

.outbound-table-container {
  background: var(--bg-white);
  padding: 15px 20px;
  height: 100%;

  .table-header-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .left {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .right {
      display: flex;
      align-items: center;
      gap: 10px;

      button {
        i {
          margin-right: 5px;
        }
      }
    }
  }
}

.table-container {
  // DO NOT TOUCH THIS
  .ant-table-header {
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    margin: 0 !important;
    padding: 0 !important;
    border-radius: 5px 5px 0 0;
  }

  // DO NOT TOUCH THIS
  .ant-table-body {
    overflow-y: auto !important;
    overflow-x: auto !important;
    border-radius: 0 0 5px 5px;
  }

  .ant-table-header,
  .ant-table-body {
    table {
      background: var(--bg-white);
      border: 1px solid var(--border-gray);
      border-radius: 0;

      .ant-table-thead {
        background: var(--table-header-bg);

        th {
          padding: 10px 12px;
          white-space: nowrap;
          border-bottom: none !important;
          background: var(--table-header-bg);

          &:hover {
            background: var(--table-header-hover-bg);
          }

          //&:last-child {
          //  text-align: center;
          //}
        }
      }

      .ant-table-tbody {
        border-top: none !important;
        background: var(--bg-white);

        tr {
          > td {
            p {
              margin-bottom: 0;
            }

            // TODO Need to investivate why this was added
            //&:last-child {
            //  text-align: right;
            //}

            &.ant-table-column-sort {
              background: none;
            }
          }

          &.ant-table-row-selected td {
            @include background-with-opacity(
              $hex-color: var(--black-rgb),
              $opacity: 0.01
            );
          }

          &:hover:not(.ant-table-expanded-row) > td {
            @include background-with-opacity(
              $hex-color: var(--black-rgb),
              $opacity: 0.01
            );
          }
        }

        .ant-btn-default {
          background-color: var(--table-header-bg);
          border: 1px solid var(--border-dark);
          padding: 3px 15px;
          height: 32px;
          font-weight: 500;
          border-radius: 0;
          color: var(--text-secondary);

          &:first-child:not(:last-child) {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }

          &:last-child:not(:first-child) {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }

          &:hover {
            color: var(--text-secondary);
            background-color: var(--table-header-hover-bg);
          }
        }

        .multiple-actions.show-reports-icon {
          .ant-btn-default:first-of-type {
            border-left: none;
            border-radius: 0;
          }
        }

        .title {
          @extend %title-style;
          cursor: pointer;
          word-break: break-word;
        }

        .column-title {
          @extend %title-style;
        }

        // Drag and drop behavior
        .drop-over-downward {
          border: 2px dashed var(--primary-color);
        }
        .drop-over-upward {
          border: 2px dashed var(--primary-color);
        }
        .drag-drop-icon {
          i {
            font-size: 15px;
            color: var(--bg-gray);
            margin-right: 15px;
            vertical-align: middle;
          }
        }

        .tag-list {
          overflow: hidden;
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          margin-top: 8px;

          .tag {
            cursor: default;

            .tag-name {
              max-width: 200px;
              min-width: 20px;
              margin-right: 3px;
            }
          }

          .more-tags {
            display: inline-block;
            padding: 3px 8px;
            color: var(--text-light);
            margin-top: 10px;
            margin-right: 10px;
            font-size: 12px;
            vertical-align: top;
            cursor: pointer;
          }
        }

        .count {
          display: none;
        }

        .ant-table-row-cell-break-word:hover {
          .count {
            display: block;

            & + p {
              display: none;
            }
          }
        }
      }
    }
  }

  .ant-table-header {
    table {
      border-radius: 4px 4px 0 0;
    }
  }

  .ant-table-body {
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 5px;
      border-radius: 5px;

      @include background-with-opacity(
        $hex-color: var(--black-rgb),
        $opacity: 0.3
      );
    }
  }

  // No data styles
  .ant-table-placeholder {
    text-align: left;
    padding: 20px;
    border: 1px solid var(--border-light);
    border-top: none;
    font-size: 13px;
    color: var(--text-primary);
  }

  // TODO: Jitta table loader changes
  //&:not(.properties-table) {
  //  .ant-spin-nested-loading .ant-spin-blur {
  //    display: none!important;
  //  }
  //  .ant-spin-nested-loading {
  //    height: 70vh;
  //  }
  //}
}

.outbound-table-container {
  .priority-mode {
    .drag-drop-icon {
      display: inline-block;
    }

    .tag-list {
      margin-left: 30px;
    }
  }

  .drag-drop-icon {
    display: none;
  }
  .drag-drop-icon {
    &.drag-drop-icon-visible {
      display: inline-block !important;
    }
  }
}

.more-action-dropdown {
  min-width: 130px;

  .ant-dropdown-menu-item {
    &:hover {
      background-color: var(--select-hover-color);
    }
  }
}

.status-filter-dropdown {
  .ant-select-dropdown-menu-item {
    text-transform: capitalize;
  }
}
