.inbox-widget-canvas {
  .ant-collapse-content-box {
    padding: 0 !important;
  }

  .inbox-widget-card {
    &.gist-fade-in {
      animation: gist-fade-in 1s;
    }

    .disabled {
      pointer-events: none;
    }

    &.loading > * {
      cursor: default;
      pointer-events: none;

      button {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    &.error-card {
      .header {
        font-size: 13px;
        line-height: 18px;
        color: var(--text-secondary);
        font-weight: 600;
        text-align: center;
        margin-bottom: 10px;
      }

      .description {
        font-size: 13px;
        line-height: 18px;
        color: var(--text-secondary);
        text-align: center;
        margin-bottom: 10px;
      }

      .button-container {
        text-align: center;

        .btn {
          border-color: transparent;
          background-color: transparent;
          background-clip: padding-box;
          color: var(--text-primary);
          font-size: 13px;

          &:hover {
            color: var(--primary-color);
          }
        }
      }
    }

    .gist-card-component {
      padding-left: 12px;
      padding-right: 12px;
      margin-bottom: 8px;
      word-break: break-word;

      input[disabled],
      select[disabled],
      textarea[disabled] {
        pointer-events: none;
      }

      button[disabled],
      input[disabled] {
        cursor: default;
      }

      button,
      input[type='button'],
      input[type='reset'],
      input[type='submit'] {
        -webkit-appearance: button;
        cursor: pointer;
      }

      &.margin-bottom-none {
        margin-bottom: 0 !important;
      }

      // TEXT COMPONENT
      &.text-component {
        margin-bottom: 8px;

        p {
          margin: 0;

          &.header {
            font-size: 13px;
            line-height: 24px;
            font-weight: 600;
          }

          &.align-left {
            text-align: left;
          }

          &.align-center {
            text-align: center;
          }

          &.align-right {
            text-align: right;
          }

          &.muted {
            color: var(--text-light);

            .link-text {
              color: var(--text-light);
            }
          }

          &.error {
            color: var(--error-color);

            .link-text {
              color: var(--error-color);
            }
          }

          strong {
            font-weight: 600;
          }

          .link-text {
            text-decoration: underline;
          }
        }

        a {
          pointer-events: auto;
        }
      }

      // DATA TABLE COMPONENT
      &.data-table-component {
        .data-table {
          .list-item {
            display: flex;
            height: 20px;
            line-height: 20px;
            font-size: 13px;
            font-weight: 400;
            margin-bottom: 8px;

            .list-item-field {
              display: block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              &:first-of-type {
                color: var(--text-light);
                width: 40%;
              }

              &:last-of-type {
                width: 60%;
                padding-left: 8px;
                color: var(--text-primary);
              }
            }
          }
        }
      }

      // IMAGE COMPONENT
      &.image-component {
        position: relative;
        margin-top: 0;
        margin-bottom: 10px;

        .image-container {
          position: relative;
          max-width: 100%;

          &.align-left {
            text-align: left;
          }

          &.align-center {
            text-align: center;
            margin-left: auto;
            margin-right: auto;
          }

          &.align-right {
            text-align: right;
            margin-left: auto;
          }

          .final-image {
            max-width: 100%;
            height: auto;

            &.round {
              border-radius: 50%;
            }
          }

          [data-action-type='url'] {
            pointer-events: auto;
            cursor: pointer;
          }
        }
      }

      // SPACER COMPONENT
      &.spacer-component {
        margin: 0;

        .spacer-xs {
          width: 100%;
          height: 4px;
        }

        .spacer-s {
          width: 100%;
          height: 8px;
        }

        .spacer-m {
          width: 100%;
          height: 16px;
        }

        .spacer-l {
          width: 100%;
          height: 24px;
        }

        .spacer-xl {
          width: 100%;
          height: 32px;
        }
      }

      // DIVIDER COMPONENT
      &.divider-component {
        margin-bottom: 8px;
        padding: 0;

        .divider {
          border-top: 1px solid var(--border-gray);
          width: 100%;
        }
      }

      // BUTTON COMPONENT
      &.button-component {
        text-align: center;

        [data-action-type='url'] {
          &:after {
            content: ' ↗';
          }
        }

        button {
          display: inline-block;
          box-sizing: border-box;
          position: relative;
          width: 100%;
          height: 28px;
          padding: 0 12px 2px 12px;
          font-size: 13px;
          border-radius: 3px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
          transition: box-shadow linear 40ms;
          appearance: none;
          user-select: none;
          font-weight: 500;
          border: none;

          &:active {
            box-shadow: 0 0 0 3px rgba(51, 75, 250, 0.2);
          }

          &:focus {
            outline: 0;
            box-shadow: 0 0 0 3px rgba(51, 75, 250, 0.2);
          }

          &:focus:active {
            outline: 0;
          }

          &.primary {
            color: var(--text-white);
            background-color: var(--primary-color);

            &:hover:not(.disabled) {
              background-color: var(--primary-color-hover);
            }
          }

          &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
            box-shadow: unset;
          }

          &.secondary {
            color: var(--text-secondary);
            background-color: var(--bg-white);
            border: 1px solid var(--border-gray);

            &:hover:not(.disabled) {
              border: 1px solid var(--primary-color);
              color: var(--primary-color);
            }
          }

          &.link {
            border: none;
            background: none;
            color: var(--text-light);
            width: max-content;
            padding-left: 0;
            padding-right: 0;

            &:active {
              box-shadow: unset;
            }

            &:focus {
              border: none;
              box-shadow: unset;
            }

            &:hover:not(.disabled) {
              color: var(--primary-color);
              box-shadow: none;
            }
          }

          &.loading {
            color: transparent;

            .messenger-card-loader {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
              width: 18px;
              height: 18px;
              background-repeat: no-repeat;
              background-position: center;
              background-size: 18px;
              animation: gist-spin 1.1s infinite linear;
              background-image: url(../../../../../src/image/loader-blue.svg);
            }
          }
        }
      }

      // INPUT COMPONENT
      &.input-component {
        .label-input-container {
          .input-label {
            display: block;
            margin-bottom: 2px;
            line-height: 20px;
            font-size: 13px;
            font-weight: 400;
            color: var(--text-light);
          }

          .input-container {
            position: relative;
            display: flex;
            flex: 1 1 auto;

            input {
              flex: 1;
              box-sizing: border-box;
              height: 28px;
              min-width: 35px;
              padding: 0 35px 0 8px;
              font-size: 13px;
              line-height: 20px;
              border-radius: 4px;
              color: var(--text-primary);
              background: transparent;
              border: 1px solid var(--border-light);

              &:hover {
                border-color: #307fff;
              }

              &:focus {
                outline: 0;
                border-color: var(--primary-color);
                box-shadow: 0 0 0 3px rgba(51, 75, 250, 0.2);
              }
            }

            .input-button {
              box-shadow: none;
              position: relative;
              width: 24px;
              flex: 0 0 24px;
              margin-left: 8px;
              border-radius: 3px;
              background-position: center;
              background-image: url(../../../../../src/image/next-icon.png);
              background-size: 8px 13px;
              background-repeat: no-repeat;
              background-color: var(--primary-color);
              border: none;

              &.disabled {
                opacity: 0.5;
                pointer-events: none;
              }

              &.loading {
                opacity: 0.5;
                pointer-events: none;
                background-image: none;
              }
            }

            .input-success-icon {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              z-index: 1;
              width: 24px;
              background-position: center;
              background-size: 14px 12px;
              background-repeat: no-repeat;
              background-image: url(../../../../../src/image/green-check-icon.png);
            }
            textarea {
              width: 100%;
            }
          }

          &.disabled {
            .input-container {
              cursor: not-allowed;

              input {
                background-color: #f1f1f1;

                &:hover {
                  border-color: #de5e54;
                }
              }
            }
          }

          &.invalid {
            .input-label {
              color: #c7493d;
            }

            .input-container {
              input {
                color: #c7493d;
                border-color: #c7493d;
                background-color: #fef0f0;
              }
            }
          }
        }

        .messenger-card-loader {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          width: 18px;
          height: 18px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 18px;
          animation: gist-spin 1.1s infinite linear;
          background-image: url(../../../../../src/image/loader-white.svg);
        }
      }

      // TEXTAREA COMPONENT
      &.textarea-component {
        .textarea-container {
          display: block;

          .textarea-label {
            display: block;
            margin-bottom: 2px;
            line-height: 20px;
            font-size: 13px;
            font-weight: 400;
            color: var(--text-light);
          }

          textarea {
            width: 100%;
            min-height: 48px;
            padding: 4px 8px;
            font-size: 13px;
            line-height: 18px;
            box-sizing: border-box;
            border-radius: 4px;
            color: var(--text-primary);
            overflow-wrap: break-word;
            background: transparent;
            border: 1px solid var(--border-light);

            &:hover {
              border-color: #307fff;
            }

            &:focus {
              outline: 0;
              border-color: var(--primary-color);
              box-shadow: 0 0 0 3px rgba(51, 75, 250, 0.2);
            }
          }

          &.disabled {
            cursor: not-allowed;

            textarea {
              background-color: var(--gray-300);
            }
          }

          &.error {
            textarea {
              color: #c7493d;
              border-color: #c7493d;
              background-color: #fef0f0;
            }
          }
        }
      }

      // LIST COMPONENT
      &.list-component {
        margin-bottom: 8px;
        padding-left: 0;
        padding-right: 0;

        &.last-component {
          margin-bottom: 0 !important;
        }

        &:last-child {
          padding-bottom: 0;
        }

        .list {
          border-top: 1px solid var(--border-gray);
          border-bottom: 1px solid var(--border-gray);

          .list-item {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            background-color: transparent;
            border-bottom: 1px solid var(--border-gray);
            font-size: 13px;
            line-height: 19px;
            padding: 10px;

            &:first-child {
              border-top: none;
            }

            &:last-child {
              border-bottom: none;
            }

            &.loading {
              opacity: 0.5;
            }

            .text-container {
              flex: 1;

              .title {
                font-weight: 400;

                &.disabled {
                  color: var(--text-light);
                }
              }

              .subtitle {
                font-size: 13px;
                color: var(--text-light);

                .secondary-text,
                .tertiary-text {
                  display: inline-block;
                }

                .tertiary-text {
                  &:not(:first-child) {
                    &::before {
                      content: '\00a0\2022\00a0';
                    }
                  }
                }
              }
            }

            .image-container {
              position: relative;
              max-width: 75px;
              margin-right: 10px;

              .final-image {
                height: auto;
                max-width: 100%;

                &.round {
                  border-radius: 50%;
                }
              }
            }

            &.disabled {
              cursor: default;
              pointer-events: none;
            }

            .messenger-card-loader {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
              width: 18px;
              height: 18px;
              background-repeat: no-repeat;
              background-position: center;
              background-size: 18px;
              animation: gist-spin 1.1s infinite linear;
              background-image: url(../../../../../src/image/loader-blue.svg);
              position: relative;
              flex: 0 0 18px;
            }
          }

          [data-action-type='submit'],
          [data-action-type='url'] {
            cursor: pointer;

            &:hover {
              background-color: var(--conversation-hover-bg-color);
            }

            .title {
              color: var(--primary-color);
            }
          }
        }
      }

      // SINGLE SELECT COMPONENT
      &.single-select-component {
        .single-select-container {
          .label-single-select-container {
            display: block;
            margin-bottom: 2px;
            line-height: 20px;
            font-size: 13px;
            font-weight: 400;
            color: var(--text-light);
          }

          .single-select-options {
            display: flex;
            box-sizing: border-box;
            height: 28px;
            max-width: 100%;
            margin-bottom: 8px;

            .single-select-option-radio {
              display: none;

              &:checked + .single-select-option-label {
                color: #307fff;
                border-color: #307fff;
                border-right: 1px solid #307fff;
                background-color: #cddcfa;
              }
            }

            .single-select-option-label {
              position: relative;
              flex: 1 0 0;
              margin: 0;
              padding: 0 2px;
              overflow: hidden;
              font-weight: 400;
              font-size: 13px;
              line-height: 23px;
              white-space: nowrap;
              text-align: center;
              text-overflow: ellipsis;
              border-radius: 0;
              cursor: pointer;
              opacity: 1;
              border: 1px solid var(--border-light);
              color: var(--text-primary);
              border-right: none;

              &:first-child {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
              }

              &:last-child {
                border-right: 1px solid rgba(0, 0, 0, 0.2);
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
              }

              &.disabled {
                opacity: 0.5;
                cursor: not-allowed;
              }
            }
          }

          &.single-select-failed {
            .label-single-select-container {
              color: #c7493d;
            }

            .single-select-option-label {
              color: #c7493d;
              border-color: #c7493d;
              background-color: #fef0f0;
            }
          }
        }

        &.single-select-component-has-value {
          .single-select-failed {
            .single-select-option-label {
              border: 1px solid rgba(0, 0, 0, 0.2);
              color: var(--text-primary);
              background-color: unset;
            }

            .single-select-option-selected {
              color: #307fff;
              border-color: #307fff;
              background-color: #cddcfa;
            }
          }
        }
      }

      // CHECKBOX COMPONENT
      &.checkbox-component {
        .checkbox-container {
          .label-checkbox-container {
            display: block;
            margin-bottom: 2px;
            line-height: 20px;
            font-size: 13px;
            font-weight: 400;
            color: var(--text-light);
          }

          .checkbox-option-block {
            display: flex;
            flex-direction: row;

            .checkbox-option-box {
              margin: 3px 6px 3px 0;
            }
          }

          &.checkbox-failed {
            .label-checkbox-container {
              color: #ea2727;
            }
          }
        }
      }

      // DROPDOWN COMPONENT
      &.dropdown-component {
        .dropdown-container {
          width: 100%;

          .label-dropdown-container {
            display: block;
            margin-bottom: 2px;
            line-height: 20px;
            font-size: 13px;
            font-weight: 400;
            color: var(--text-light);
          }

          .dropdown-options {
            padding: 5px 8px;
            font-size: 13px;
            text-decoration: none;
            border-radius: 4px;
            cursor: pointer;
            background-color: var(--bg-white);
            border: 1px solid var(--border-light);
            box-shadow: none;
            width: 100%;
            overflow: auto;
            list-style: none;
            outline: none;
          }

          &.disabled {
            cursor: not-allowed;

            .dropdown-options {
              color: var(--text-light);
            }

            select {
              cursor: auto;
              pointer-events: none;
            }
          }

          &.dropdown-failed {
            .label-dropdown-container {
              color: #c7493d;
            }
          }
        }
      }
    }
  }
}

@keyframes gist-spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes gist-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
