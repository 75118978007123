@import '../../../style/variable';
@import '../../../style/mixin';

.chat-modal {
  .mail-builder {
    display: flex;
    align-items: center;
    justify-content: center;
    //margin-bottom: 20px;
    & + div {
      margin-top: 20px;
      margin-bottom: 0;
    }
  }

  .ant-tabs-bar {
    border-bottom: none;
    margin-bottom: 0;

    .ant-tabs-tab {
      margin: 0;
      padding: 10px;

      &:hover {
        color: var(--text-primary);
      }

      &.ant-tabs-tab-active {
        color: var(--text-primary);
      }

      &.ant-tabs-tab-disabled {
        color: var(--text-light);
      }
    }
  }

  .ant-tabs-content {
    .all-template-tab {
      padding: 30px 0 20px;

      .no-data {
        padding: 100px;
        text-align: center;
        font-size: 18px;
        color: var(--text-secondary);
      }

      .template {
        width: 200px;
        height: 200px;
        border-radius: 5px;
        cursor: pointer;

        .overlay {
          top: 0;
          left: 0;
          width: 90%;
          height: 100%;
          position: absolute;
        }

        & + p {
          margin: 8px 0 25px 0;
          font-size: 15px;
          font-weight: 600;
          color: var(--text-secondary);
          text-transform: capitalize;
        }

        & iframe {
          border-radius: 4px;
          border: 4px solid var(--border-light);
          @include prefixer(transform-origin, 0 0);
          @include prefixer(transform, scale(0.5));
          width: 315px;
          height: 400px;
        }

        .active-image {
          border-color: var(--primary-color);
          box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
        }

        //& img {
        //  border: none;
        //  height: inherit;
        //  width: inherit;
        //}
      }
    }
  }

  .bee-templates {
    .ant-col {
      margin-bottom: 10px;
    }

    .overlay {
      top: 0;
      left: 0;
      width: 90%;
      height: 100%;
      position: absolute;
      cursor: pointer;

      &:hover {
        cursor: pointer;
      }
    }

    img {
      border-radius: 4px;
      width: 160px;
      margin-bottom: 5px;
      border: 1px solid var(--border-light);

      &.active-image {
        border: 2px solid var(--primary-color);
      }
    }
  }

  &.scroll .ant-modal-body {
    max-height: calc(100vh - 140px);
    overflow: hidden;

    .ant-form {
      &.ant-form-horizontal {
        .back {
          cursor: pointer;
          margin-bottom: 10px;
        }
      }

      .ant-form-item-label {
        margin-bottom: 15px;
      }

      .ant-tabs-content,
      .bee-templates {
        overflow-x: hidden;
        overflow-y: auto;
        height: calc(50vh - 200px);
      }

      .bee-templates {
        margin-top: 30px;
      }

      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
}
