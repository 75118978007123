@import "../../style/variable";
@import "../../style/mixin";

%drawer-wrapper {
  .ant-drawer-content-wrapper[style] {
    background: var(--bg-white);
    //margin-top: 58px;
    box-shadow: $default-box-shadow;
  }
}

%heading {
  .drawer-heading {
    margin-bottom: 20px;
    h2 {
      font-size: 18px;
      color: var(--text-secondary);
      margin-bottom: 5px;
    }
    p {
      font-size: $font-size-base;
      margin-bottom: 0;
    }
  }
}

.fa-caret-down {
  font-size: 12px !important;
}


.workspace-drawer {
  @extend %drawer-wrapper;
  .ant-drawer-body {
    padding: 30px;
    @extend %heading;
    .drawer-content {
      .ant-row {
        h3 {
          font-size: $font-size-base;
          font-weight: 500;
          margin-bottom: 5px;
          color: var(--text-primary);
        }
      }
      .submit{
        margin-right: 10px;
      }
    }
  }
}
