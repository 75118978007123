@import '../../../style/variable';

//.ant-layout-sider
// should remove the class after generalising the drawer
.events-sider {
  flex: 0 0 260px !important;
  max-width: 260px !important;
  min-width: 230px !important;
  width: 260px !important;
  border-right: 1px solid var(--border-gray);
  .ant-menu-inline {
    border-right: 0;
  }
  .fa-magnifying-glass {
    font-size: 16px;
  }
  .ant-menu {
    background: var(--sidebar-bg-light);
    .ant-menu-submenu-arrow {
      left: $gist-space;
    }
    .ant-menu-submenu-title {
      color: var(--text-primary);
      font-size: 13px;
      .submenu-title {
        display: flex;
        justify-content: space-between;
        div {
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .anticon-loading {
          font-size: 16px !important;
        }
        .menu-count {
          &:hover {
            display: none;
          }
        }
      }
      .action-icons {
        i {
          display: none;
          cursor: pointer;
          font-size: 13px;

          &:not(.fa-trash) {
            &:hover {
              color: var(--primary-color);
            }
          }
          &:hover {
            &.fa-trash {
              color: $error-color;
            }
          }
          &:first-child {
            margin-right: 10px;
          }
        }
      }
      &:hover {
        .action-icons {
          i {
            display: inline-block;
          }
        }
        .menu-count {
          display: none;
        }
        .visible {
          display: inline !important;
        }
      }
    }
    .ant-menu-submenu {
      .ant-menu-submenu-title {
        height: auto;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 13px;
        padding-left: 36px !important;
        line-height: 1.2;
      }
    }
    .ant-menu-item {
      &:hover,
      &:active {
        background: transparent !important;
      }
      &:after {
        border: none;
        transition: none;
      }
    }
    .ant-menu-submenu-title,
    .ant-menu-item {
      margin: 0 !important;
      padding-left: $gist-space !important;
      padding-right: $gist-space !important;
      > a {
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline;
        &:hover {
          color: var(--primary-color);
        }
      }
      &:hover {
        color: var(--text-secondary);
        background: transparent;
      }
      &.ant-menu-item-selected {
        color: var(--text-secondary);
        background: var(--bg-gray);
        font-weight: 500;
        &:hover {
          background: var(--bg-gray);
        }
      }
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    .header {
      display: flex;
      padding: 20px 20px 0px;
      flex-direction: column;
      .title-bar {
        display: flex;
        align-items: center;
        .ant-dropdown-trigger {
          cursor: pointer;
        }
        button {
          i {
            color: var(--primary-color);
          }
        }
        h3 {
          overflow: hidden;
          text-overflow: ellipsis;
          & + i {
            margin-right: 10px;
            cursor: pointer;
          }
        }
      }
      .search {
        min-width: auto;
      }
      h3 {
        flex: 1;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 0;
      }
    }
    .body {
      flex-grow: 1;
      overflow-y: auto;
      overflow-x: hidden;
      margin-bottom: 20px;
      .middle {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          padding: 0 20px;
          font-weight: 500;
          font-size: 12px;
          margin-bottom: 0;
          text-align: center;
        }
      }
    }
    .footer {
      padding: $gist-space;
    }
  }
}

// FOR EDIT MODAL OPENING FROM SIDEBAR
.edit-modal {
  width: 475px !important;
  .ant-modal-header {
    color: var(--text-primary);
    border-bottom: none;
    .ant-modal-title {
      font-size: 18px;
      font-weight: bold;
    }
  }
  .ant-modal-body {
    padding: 0 24px 0;
  }
  .ant-modal-footer {
    border: none;
    margin-bottom: 20px;
  }
}

.report-sider {
  background-color: var(--sidebar-bg-light) !important;
  .ant-menu {
    .ant-menu-submenu {
      .ant-menu-sub {
        .ant-menu-item {
          &:hover,
          &:active {
            background: none !important;
          }
          a {
            &:hover {
              color: var(--primary-color);
            }
          }
          &.ant-menu-item-selected {
            background: none !important;
            a {
              font-weight: 500;
              color: var(--primary-color);
            }
          }
        }
      }
    }
  }
}
