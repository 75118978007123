@import '../../style/variable';

.deal-layout {
  background: var(--bg-white);

  .header {
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--deals-column-border);

    .left {
      display: flex;
      align-items: center;

      .heading {
        font-size: 20px;
        color: var(--text-secondary);
        cursor: text;
        font-weight: bold;
        line-height: 1.4;
        letter-spacing: normal;
        margin-right: 15px;
      }

      .overall-stats {
        font-size: 16px;
      }
    }

    .right {
      .form-item {
        margin-right: 15px;

        .ant-select-selection {
          min-width: 160px;
          max-width: 300px;

          .ant-select-selection-selected-value {
            img {
              width: 25px;
              height: 25px;
              border-radius: 25px;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

.reset {
  height: 40px;
}

.crm-container {
  height: 100%;
  padding: 20px;

  .drag-container {
    display: flex;
    align-items: stretch !important;
    justify-content: flex-start !important;
    overflow-x: auto !important;
    flex-grow: 1;
    width: 100%;
    position: relative;
    height: calc((100vh - 58px) - 115px);

    .column-container {
      display: flex;
      flex-wrap: nowrap;
      flex-shrink: 0;

      .column {
        display: flex;
        width: 300px;
        flex-shrink: 0;
        overflow: hidden;
        background: var(--bg-lighter);
        flex-direction: column;
        border: 1px solid var(--deals-column-border);
        border-radius: 6px;
        margin-right: 10px;

        &:last-of-type {
          border-right: 1px solid var(--border-gray);
        }

        &.skeleton-view {
          .ant-skeleton {
            padding: 10px;
          }
          .ant-skeleton-with-avatar {
            display: flex;
            flex-direction: row-reverse;
          }
          .ant-skeleton-content {
            padding-right: 16px;
          }
          .ant-skeleton-header {
            padding-right: 0;
          }
        }

        .inner-column {
          display: flex;
          -webkit-box-flex: 1;
          flex-grow: 1;
          flex-shrink: 0;
          position: relative;
          flex-direction: column;
          overflow: hidden;

          .column-header {
            background: var(--bg-white);
            border-bottom: 1px solid var(--deals-column-border);
            padding: 12px 15px;
            display: flex;
            justify-content: space-between;

            h3 {
              font-size: 15px;
              font-weight: 600;
              margin-bottom: 0;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .count {
              background-color: var(--bg-gray);
              border-color: var(--border-gray);
              color: var(--text-light);
              padding: 1px 8px;
              border-radius: 12px;
              font-size: 13px;
              font-weight: 600;
            }
          }

          .column-footer {
            text-align: center !important;
            padding: 8px;
            background: var(--bg-white);
            border-top: 1px solid var(--border-gray);
            .amount {
              font-size: $font-size-base;
              font-weight: 500;
            }
          }

          .item-container {
            flex-grow: 1;
            min-height: 100px;
            padding-left: 8px;
            padding-right: 8px;
            overflow-y: auto;
            height: 0px;
            margin: 0px;

            .item {
              display: flex;
              position: relative;
              background: var(--bg-white);
              border-radius: 3px;
              border: 1px solid var(--border-gray);
              cursor: pointer;
              padding: 10px 12px;
              line-height: 20px;
              margin-top: 6px;
              margin-bottom: 6px;
              min-height: 60px;

              &:hover {
                box-shadow: var(--black-opacity-bg) 0px 1px 20px 0px;
              }

              &.deal-expired {
                background-color: var(--deal-expired-bg);
              }

              .status {
                flex-shrink: 0;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                margin: 0 auto;
                margin-top: 10px;
                position: absolute;
                bottom: 15px;
                right: 15px;
                background: var(--bg-gray);

                .critical {
                  background: $error-color;
                }

                .attention {
                  background: $warning-color;
                }

                .good {
                  background: $bg-success;
                }
              }

              .inner-item {
                width: calc(100% - 24px);
                padding-right: 5px;

                a {
                  font-weight: 600;

                  &:hover {
                    text-decoration: underline;
                  }
                }

                ul {
                  list-style: none;
                  padding: 0;
                  padding-top: 5px;
                  margin-bottom: 0;

                  li {
                    margin-top: 3px;

                    span {
                      &:first-of-type {
                        font-weight: 600;
                        padding-right: 4px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.deal-drawer {
  .ant-drawer-body {
    .heading {
      font-size: 16px;
      font-weight: 500;
      color: var(--text-secondary);
      display: block;
    }
    .select-label {
      display: block;
      margin-bottom: 5px;
      font-weight: 500;
    }
    .content {
      .ant-form {
        .ant-select-selection--multiple {
          .ant-select-selection__rendered,
          .ant-select-selection__placeholder {
            margin-left: 0px;
          }
        }
        .deal-owner-select {
          .ant-select-selection--single {
            .ant-select-selection__rendered {
              .ant-select-selection-selected-value {
                img {
                  height: 26px;
                  width: 26px;
                  border-radius: 50%;
                  margin-right: 10px;
                }
              }
            }
          }
          .ant-select-selection--multiple {
            .ant-select-selection__rendered {
              .ant-select-selection__choice__content {
                img {
                  height: 18px;
                  width: 18px;
                  border-radius: 50%;
                  margin-right: 10px;
                  margin-top: -2px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.crm-teammate-name {
  margin-left: 10px;
}

.status-filter-dropdown {
  min-width: 110px;
  max-width: 400px;
  &.deals-pipeline-dropdown .ant-select-dropdown-menu-item {
    display: block;
  }
  &.profile-dropdown .ant-select-dropdown-menu-item {
    .content-overflow {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .link {
    display: block;
    padding: 5px 15px 10px;

    &:hover {
      background-color: var(--select-hover-color) !important;
    }
  }
}

.column-drop-alert {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: 1px dashed $primary-color-secondary;
  font-size: $font-size-base;
}
.item-container-dragging-over{
  background-color: #fafafa60;
}
.drag-disabled{
  background-color: lightgrey !important;
}
.dragging-container{
  background-color: lightgreen !important;
}

.crm-profile-container {
  position: relative;
  height: 24px;
  width: 24px;

  .active-status-dot {
    border: 2px solid var(--border-white);
    height: 10px;
    width: 10px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    left: 16px !important;
    top: 17px !important;
  }
}
