@import "variable";

%contact-modal {
  .ant-modal-close {
    display: none;
  }

  .ant-modal-body {
    .ant-modal-close {
      display: block;
    }

    .ant-row {
      margin-bottom: 15px;

      .ant-select {
        width: 100%;

        .ant-select-selection {
          .ant-select-selection__rendered {
            .ant-select-selection--multiple > ul > li, .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
              border-radius: 4px;
            }
          }

          &.ant-select-selection--multiple {
            .ant-select-selection__rendered {
              & > ul > li {
                border-radius: 4px;
              }
            }
          }
        }
      }
    }
  }
}